import React from 'react'
import axios from 'axios'
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Ranktop extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            list:[],
            loading : true
        }
    }
    
    componentDidMount(){
        axios.get(`https://sv.mvpkick.com/league/rank_top.php`).then(res =>{
            //console.log(res.data)
            this.setState({list : res.data,loading : false})
        })
        .catch(e =>{
            this.setState({list : [],loading : false})
        })
    }

    render(){
        if(this.state.list.length>0){
            var dataSet = this.state.list.map((item,index) =>{
                let {team} = item;

                if(team.length<=0){
                    return(
                        <div key={index} className="cardRankTop">
                            <div>
                                <div className="col-12 leagueRankTop py-2 my-2">{item.tourName} ({item.seasonName})</div>
                                <div className="col-12 py-2">Wait Confirm</div>
                            </div>
                        </div>
                    )
                }else
                {
                team.sort((a,b)=> a.rank-b.rank)
                let standingArrData = [];
                team.map((team,i)=>{
                    //console.log(i)
                    let {standing_data} = team
                    let objSet = {
                        points : 0,
                        goalsfor : 0,
                        goalsagainst : 0,
                        played : 0,
                        draws : 0,
                        wins : 0,
                        defeits : 0
                    }
                    for(const data of standing_data){
                        switch (data.code) {
                            case 'points':objSet.points=data.value;break;
                            case 'goalsfor':objSet.goalsfor=data.value;break;
                            case 'goalsagainst':objSet.goalsagainst=data.value;break;
                            case 'played':objSet.played=data.value;break;
                            case 'draws':objSet.draws=data.value;break;
                            case 'wins':objSet.wins=data.value;break;
                            case 'defeits':objSet.defeits=data.value;break;
                            default:break;
                        }
                    }
                    standingArrData.push(objSet)
                })

                //console.log(standingArrData)
                //console.log(points)
                return(
                    <div key={index} className="cardRankTop">
                        <div>
                            <div className="col-12 leagueRankTop py-2 my-2">{item.tourName} ({item.seasonName})</div>
                            <div className="col-12 py-2"><LazyLoadImage src={team[0].participant.logo} alt={team[0].participant.name} className="logoRecCard boxRT" /><br/>{standingArrData[0].points} Points<br/>{team[0].participant.name}</div>
                            <div className="col-6"><LazyLoadImage src={team[1].participant.logo} alt={team[1].participant.name} className="logoTeamLs boxRT" /><br/>{standingArrData[1].points} Points</div>
                            <div className="col-6"><LazyLoadImage src={team[2].participant.logo} alt={team[2].participant.name} className="logoTeamLs boxRT" /><br/>{standingArrData[2].points} Points</div>
                        </div>
                        <div className="overlay-bottom-to-top">
                            <div>
                                <div className="col-12"><LazyLoadImage src={team[0].participant.logo} alt={team[0].participant.name} className="logoRecCard boxRT" /><br/>{team[0].participant.name}</div>
                                <div className="col-5">Point : {standingArrData[0].points}</div>
                                <div className="col-5">Played : {standingArrData[0].played}</div>
                                <div className="col-5">Win : {standingArrData[0].wins}</div>
                                <div className="col-5">Draw : {standingArrData[0].draws}</div>
                                <div className="col-5">Lose : {standingArrData[0].defeits}</div>
                                <div className="col-5">Goals : {standingArrData[0].goalsfor}</div>
                                <div className="col-5">Lose a goal : {standingArrData[0].goalsagainst}</div>
                            </div>
                        </div>
                    </div>
                )
                }
            })
        }else{
           
        }
        return(
            <div className="text-center">
                <h3 className="mx-3 text-center heading-hl">Top 3 Team</h3>
                {this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" /></div>:dataSet}
            </div>
        )
    }
}


export default Ranktop;