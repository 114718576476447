import axios from 'axios'
import React from 'react'
import Chartleague from './Chartsleague'

class Standingtour extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            list : null,
            id : this.props.id,
            connMobile : this.props.connMobile,
            from : this.props.from,
            idTeam : this.props.teamFocus,
            loading : true,
            selectTour : this.props.selectTour
        }
    }

    componentDidMount(){
       // console.log(this.state.list)
        axios.get(`https://api.mvpkick.com/tournament?id=${this.state.id}&league_table=yes`).then(res => {
            let listforSet = null;
            if(res.data.msg===undefined&&res.data.standing_participants!==undefined){ /* เคส standing_participants!==undefined ยังไม่เชค error */
                let {standing_participants} = res.data
                for(const key in standing_participants){
                    let {participantFK} = standing_participants[key]
                    if(participantFK===this.state.idTeam){
                        let dataTour = {
                            points:'',
                            goalsfor:'',
                            goalsagainst:'',
                            draws:'',
                            played:'',
                            wins:'',
                            defeits:''
                        }
                        let standing_data = standing_participants[key].standing_data
                        if(standing_data.length>0){
                            for(let i=0;i<standing_data.length;i++){
                                switch(standing_data[i].code){
                                    case "points":dataTour.points=standing_data[i].value;break;
                                    case "goalsfor":dataTour.goalsfor=standing_data[i].value;break;
                                    case "goalsagainst":dataTour.goalsagainst=standing_data[i].value;break;
                                    case "draws":dataTour.draws=standing_data[i].value;break;
                                    case "played":dataTour.played=standing_data[i].value;break;
                                    case "wins":dataTour.wins=standing_data[i].value;break;
                                    case "defeits":dataTour.defeits=standing_data[i].value;break;
                                    default: break;
                                }
                            }
                            listforSet = dataTour
                        }
                    }
                }
            }
            
            this.setState({list : listforSet, loading : false})
            
        })
    }

    setSelectChart = () =>{
        //console.log('q1')
        this.props.setSelectIdTour(this.state.id)
    }

    setSelectChartNull = () =>{
        //console.log('q1_1')
        this.props.setSelectIdTour(null)
    }

    render(){
        return(
            this.state.list!==null?
            <div>
                {
                this.props.selectTour===this.state.id?
                <div className="tourteamTable">
                    <div onClick={this.setSelectChartNull} className="btnSwitchChartActive">Hide Chart</div>
                    <Chartleague id={this.state.id} list={this.state.list}/>
                </div>
                :
                <div className="tourteamTable">
                <div onClick={this.setSelectChart} className="btnSwitchChart">See Chart</div>
                </div>
                }
            <div className="tourteamTable">
                <div className="colTourteam-4">wins : {this.state.list.wins}</div>
                <div className="colTourteam-4">draws : {this.state.list.draws}</div>
                <div className="colTourteam-4">defeits : {this.state.list.defeits}</div>
                <div className="colTourteam-4">played : {this.state.list.played}</div>
                <div className="colTourteam-4">goalsfor : {this.state.list.goalsfor}</div>
                <div className="colTourteam-4">goalsagainst : {this.state.list.goalsagainst}</div>
                <div className="colTourteam-12">points : {this.state.list.points}</div>
            </div>
            </div>:
            <div className="tourteamTable"><div className="colTourteam-12">No Standing Data</div></div>
        )
    }
}

export default Standingtour;