import React from 'react'
import axios from 'axios'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Hva from '../../components/h2h/Hva'
import Odds from '../../components/h2h/Odds'
import Votetded from './Votetded'

class Contenttded extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            id : this.props.id,
            homeInfo : '',
            awayInfo : '',
            resultHome : '',
            resultAway : '',
            infoMatch: '',
            loading : true,
            connMobile : false,
            pointCalH2H : [],
            pointCalH2HHva : {data : null},
            pointCalH2HHome : {data : null},
            pointCalH2HAway : {data : null},
            cupTour : undefined
            
        }
        this.idFocusLeague = ['38','40','42','45','46','47','48','50','51','53','54','55','61','64','69','77','78','86','87','110','113','123','132','134','138','140','141','143','146','149','224','268','8984'];
    }

    handleCallback = (keywords, calTdedObj) =>{
        //console.log(keywords, calTdedObj)
        if(keywords==='hva'){this.state.pointCalH2HHva.data===null?this.setState({ pointCalH2HHva : {data:calTdedObj} }):console.log()}
        else if(keywords==='home'){this.state.pointCalH2HHome.data===null?this.setState({ pointCalH2HHome : {data:calTdedObj} }):console.log()}
        else if(keywords==='away'){this.state.pointCalH2HAway.data===null?this.setState({ pointCalH2HAway : {data:calTdedObj} }):console.log()}
        //console.log(this.state.pointCalH2HHva)
    }

    handleWindowsize = () =>{
        window.innerWidth>1000?this.state.connMobile===false?console.log():this.setState({connMobile : false}):this.state.connMobile===true?console.log():this.setState({connMobile : true}); // เนื้องจาก .container ใน hva มีการสลับแสดงผลที่ 1000
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.handleWindowsize);
    }

    componentDidMount(){
        window.addEventListener("resize", this.handleWindowsize);

        //axios.get(`http://localhost/event?id=${this.state.id}`).then(res =>{
        axios.get(`https://api.mvpkick.com/event?id=${this.state.id}`).then(res =>{
        //axios.get(`https://sv.mvpkick.com/h2h/index.php?id=${this.state.id}`).then(res =>{
            //console.log(res.data)
        

        
            let homeInfo = {}
            let awayInfo = {}
            let infoMatch = {
                id: '',
                tournament_stageFK: '',
                startdate: '',
                status_type: '',
                status_descFK: '',
                tournamentFK: '',
                tournament_templateFK: '',
                tournament_stage_name: '',
                tournament_name: '',
                tournament_template_name: '',
                VenueName: ''
            }
            let resultHome = {
                ordinarytime:null,
                halftime:null,
                finalresult:null
            };
            let resultAway = {
                ordinarytime:null,
                halftime:null,
                finalresult:null
            };
            let cupTour = this.idFocusLeague.find(ele => ele === infoMatch.tournament_templateFK);
            if(res.data.msg!==undefined){
                this.setState({homeInfo : homeInfo, awayInfo : awayInfo, resultHome : resultHome, resultAway : resultAway, infoMatch : infoMatch, loading : false, cupTour : this.idFocusLeague.find(ele => ele === infoMatch.tournament_templateFK)});
            }else{
            let event = res.data;
            for (const keyEvent in event) {
                infoMatch = {
                    id: event.id,
                    tournament_stageFK: event.tournament_stageFK,
                    startdate: event.startdate,
                    status_type: event.status_type,
                    status_descFK: event.status_descFK,
                    tournamentFK: event.tournamentFK,
                    tournament_templateFK: event.tournament_templateFK,
                    tournament_stage_name: event.tournament_stage_name,
                    tournament_name: event.tournament_name,
                    tournament_template_name: event.tournament_template_name,
                }
                let {event_participants, property} = event;
                for (const listEventParticipants in event_participants) {
                    let {number,participant,result} = event_participants[listEventParticipants];
                    switch(number){
                        case '1':homeInfo = participant;
                        for(const resultKey in result){
                            switch (result[resultKey].result_code) {
                                case "ordinarytime":resultHome.ordinarytime=result[resultKey].value; break;
                                case "halftime":resultHome.halftime=result[resultKey].value; break;
                                case "finalresult":resultHome.finalresult=result[resultKey].value; break;
                                default:break;
                            }
                        }
                        break;
                        case '2':awayInfo = participant;
                        for(const resultKey in result){
                            switch (result[resultKey].result_code) {
                                case "ordinarytime":resultAway.ordinarytime=result[resultKey].value; break;
                                case "halftime":resultAway.halftime=result[resultKey].value; break;
                                case "finalresult":resultAway.finalresult=result[resultKey].value; break;
                                default:break;
                            }
                        }
                        break;
                        default:break;
                    }
                }
                for (const listEventProperty in property) {
                    let {name, value} = property[listEventProperty];
                    switch(name){
                        case 'VenueName':infoMatch.VenueName=value;break;
                        default:break;
                    }
                }
            }
            this.setState({homeInfo : homeInfo, awayInfo : awayInfo, resultHome : resultHome, resultAway : resultAway, infoMatch : infoMatch, loading : false, cupTour : this.idFocusLeague.find(ele => ele === infoMatch.tournament_templateFK)});
        }
        })
        
    }

    componentDidUpdate(){
        //console.log(this.state.connMobile)
    }

    calPerCentTded = (difGoalPerGameHome,difGoalPerGameAway,resultWin) => {
        let result = 0;
        let dif = 0;
        let notice = '';
        if(resultWin===this.state.homeInfo.name){dif = difGoalPerGameHome-difGoalPerGameAway}
        else if(resultWin===this.state.awayInfo.name){dif = difGoalPerGameAway-difGoalPerGameHome}
        else if(resultWin!==this.state.homeInfo.name&&resultWin!==this.state.awayInfo.name&&resultWin!=='เสมอกัน'){return false;}

        //console.log(difGoalPerGameHome+ " " + difGoalPerGameAway + " " + resultWin)

        if(dif>=1.6){result=90; notice = 'จำนวนประตูได้เสียเฉลี่ยต่อแมตซ์ ของ '+resultWin+' ดีกว่าคู่แข่งอย่างมาก'}
        else if(dif>=1.4){result=85; notice = 'จำนวนประตูได้เสียเฉลี่ยต่อแมตซ์ ของ '+resultWin+' ดีกว่าคู่แข่งพอสมควร'}
        else if(dif>=1.2){result=80; notice = 'จำนวนประตูได้เสียเฉลี่ยต่อแมตซ์ ของ '+resultWin+' ดีกว่าคู่แข่งพอสมควร'}
        else if(dif>=1){result=75; notice = 'จำนวนประตูได้เสียเฉลี่ยต่อแมตซ์ ของ '+resultWin+' ดีกว่าคู่แข่งปานกลาง'}
        else if(dif>=0.8){result=70; notice = 'จำนวนประตูได้เสียเฉลี่ยต่อแมตซ์ ของ '+resultWin+' ดีกว่าคู่แข่งปานกลาง'}
        else if(dif>=0.6){result=65; notice = 'จำนวนประตูได้เสียเฉลี่ยต่อแมตซ์ ของ '+resultWin+' ดีกว่าคู่แข่งเล็กน้อย'}
        else if(dif>=0.4){result=60; notice = 'จำนวนประตูได้เสียเฉลี่ยต่อแมตซ์ ของ '+resultWin+' ดีกว่าคู่แข่งเล็กน้อย'}
        else if(dif>=0.2){result=55; notice = 'จำนวนประตูได้เสียเฉลี่ยต่อแมตซ์ ของ '+resultWin+' ไม่ต่างจากคู่แข่งมากนัก'}
        else if(dif>=0){result=50; notice = 'จำนวนประตูได้เสียเฉลี่ยต่อแมตซ์ ของ '+resultWin+' ไม่ต่างจากคู่แข่งมากนัก';}
        else{result='น้อยกว่า 50'; notice = 'จำนวนประตูได้เสียเฉลี่ยต่อแมตซ์ ของ '+resultWin+' อยู่ในเกณฑ์ต่ำกว่าคู่แข่ง แม้ภาพรวมจะดูดีกว่าก็ตาม';}

        //return `${result}% : ${notice}`;
        return(
            <div className="col-12">
                <div className="col-12 percentSureTded my-5"><span className="funtongScoreTded" style={result>70?{border:'2px solid #09956c'}:result>60?{border:'2px solid rgb(224 132 5)'}:{border:'2px solid #f92a38'}}>ความมั่นใจ : {result}%</span></div>
                <div className="funtongCommentTded col-8"><span>ความคิดเห็น : {notice}</span></div>
            </div>
        )
    }

    render(){
        var calScoreTded = {
            homePoint : 0,
            awayPoint : 0,
            resultWin : '',
            score : '',
            percent : '',
        }
        if(this.state.pointCalH2HHva.data!==null&&this.state.pointCalH2HHome.data!==null&&this.state.pointCalH2HAway.data!==null){
            console.log(this.state.pointCalH2HHva)
            console.log(this.state.pointCalH2HHome)
            console.log(this.state.pointCalH2HAway)
            this.state.pointCalH2HHva.data.resultCalSelectTeam===this.state.homeInfo.name?calScoreTded.homePoint++:this.state.pointCalH2HHva.data.resultCalSelectTeam===this.state.awayInfo.name?calScoreTded.awayPoint++:this.state.pointCalH2HHva.data.resultCalSelectTeam===false? console.log(): this.state.pointCalH2HHva.data.resultCalSelectTeam==='draw'? this.state.pointCalH2HHva.data.difGoalPerGame>this.state.pointCalH2HHva.data.difGoalPerGameNoSelect? calScoreTded.homePoint++: this.state.pointCalH2HHva.data.difGoalPerGame===this.state.pointCalH2HHva.data.difGoalPerGameNoSelect? console.log():calScoreTded.awayPoint++: console.log()
            this.state.pointCalH2HHome.data.resultCalSelectTeam===this.state.homeInfo.name?calScoreTded.homePoint++:this.state.pointCalH2HHome.data.resultCalSelectTeam===this.state.awayInfo.name?calScoreTded.awayPoint++:this.state.pointCalH2HHome.data.resultCalSelectTeam===false? console.log(): this.state.pointCalH2HHome.data.resultCalSelectTeam==='draw'? this.state.pointCalH2HHome.data.difGoalPerGame>this.state.pointCalH2HHome.data.difGoalPerGameNoSelect? calScoreTded.homePoint++: this.state.pointCalH2HHome.data.difGoalPerGame===this.state.pointCalH2HHome.data.difGoalPerGameNoSelect? console.log(): calScoreTded.awayPoint++: console.log()
            this.state.pointCalH2HAway.data.resultCalSelectTeam===this.state.homeInfo.name?calScoreTded.homePoint++:this.state.pointCalH2HAway.data.resultCalSelectTeam===this.state.awayInfo.name?calScoreTded.awayPoint++:this.state.pointCalH2HAway.data.resultCalSelectTeam===false? console.log(): this.state.pointCalH2HAway.data.resultCalSelectTeam==='draw'? this.state.pointCalH2HAway.data.difGoalPerGame>this.state.pointCalH2HAway.data.difGoalPerGameNoSelect? calScoreTded.awayPoint++: this.state.pointCalH2HAway.data.difGoalPerGame===this.state.pointCalH2HAway.data.difGoalPerGameNoSelect? console.log(): calScoreTded.homePoint++: console.log()
            
            //console.log(this.state.pointCalH2HHva.data.difGoalPerGame+ " " +this.state.pointCalH2HHva.data.difGoalPerGameNoSelect)
            console.log(calScoreTded)

            let scHome = 0;
            let scAway = 0;

            if((calScoreTded.homePoint+calScoreTded.awayPoint)<2){ // สถิติ 3 ส่วนมีแค่ส่วนเดียว
                calScoreTded.resultWin="รายการนี้ สถิติมีน้อยเกินไป ไม่สามารถนำมาคำนวนได้"
                calScoreTded.score = "-"
                calScoreTded.percent = this.calPerCentTded(this.state.pointCalH2HHome.data.difGoalPerGame,this.state.pointCalH2HAway.data.difGoalPerGame,calScoreTded.resultWin)
            }else{
                
                if(calScoreTded.homePoint===calScoreTded.awayPoint){
                    if(this.state.pointCalH2HHome.data.resultCalSelectTeam!==false&&this.state.pointCalH2HAway.data.resultCalSelectTeam!==false){
                        this.state.pointCalH2HHome.data.difGoalPerGame===this.state.pointCalH2HAway.data.difGoalPerGame?calScoreTded.resultWin="เสมอกัน":this.state.pointCalH2HHome.data.difGoalPerGame>this.state.pointCalH2HAway.data.difGoalPerGame?calScoreTded.resultWin=this.state.homeInfo.name:calScoreTded.resultWin=this.state.awayInfo.name
                        calScoreTded.percent = this.calPerCentTded(this.state.pointCalH2HHome.data.difGoalPerGame,this.state.pointCalH2HAway.data.difGoalPerGame,calScoreTded.resultWin)
                        
                    }else{
                        calScoreTded.resultWin="รายการนี้ สถิติมีน้อยเกินไป ไม่สามารถนำมาคำนวนได้"
                        calScoreTded.score = "-"
                    }
                }else{
                    calScoreTded.homePoint>calScoreTded.awayPoint?calScoreTded.resultWin=this.state.homeInfo.name:calScoreTded.resultWin=this.state.awayInfo.name
                }

                //console.log(this.state.pointCalH2HHva);
                //console.log(this.state.pointCalH2HHome);
                //console.log(this.state.pointCalH2HAway);
    
                //console.log(this.state.pointCalH2HHome.data.difGoalPerGame+" "+this.state.pointCalH2HAway.data.difGoalPerGame)
    
                calScoreTded.percent = this.calPerCentTded(this.state.pointCalH2HHome.data.difGoalPerGame,this.state.pointCalH2HAway.data.difGoalPerGame,calScoreTded.resultWin)
                
                //console.log(this.state.pointCalH2HHome.data.goalPerGame+" "+this.state.pointCalH2HAway.data.lostGoalPerGame)
                //console.log(this.state.pointCalH2HAway.data.goalPerGame+" "+this.state.pointCalH2HHome.data.lostGoalPerGame)

                let scHomeCheckPoint = ((this.state.pointCalH2HHome.data.goalPerGame+this.state.pointCalH2HAway.data.lostGoalPerGame)/2)
                let scAwayCheckPoint = ((this.state.pointCalH2HAway.data.goalPerGame+this.state.pointCalH2HHome.data.lostGoalPerGame)/2)

                if(scHomeCheckPoint>0.75){scHome = Math.round(scHomeCheckPoint)}else{scHome = Math.floor(scHomeCheckPoint)} // 0.75+ ปัดขึ้น ต่ำกว่า ปัดลง
                if(scAwayCheckPoint>0.75){scAway = Math.round(scAwayCheckPoint)}else{scAway = Math.floor(scAwayCheckPoint)} // 0.75+ ปัดขึ้น ต่ำกว่า ปัดลง
    
                //scHome = Math.floor(((this.state.pointCalH2HHome.data.goalPerGame+this.state.pointCalH2HAway.data.lostGoalPerGame)/2))
                //scAway = Math.floor(((this.state.pointCalH2HAway.data.goalPerGame+this.state.pointCalH2HHome.data.lostGoalPerGame)/2))
                //console.log(scHome+'-'+scAway+" "+calScoreTded.resultWin)
                if(scHome<0){scHome=0}
                if(scAway<0){scAway=0}
                if(calScoreTded.resultWin===this.state.homeInfo.name&&scHome>scAway){}
                else if(calScoreTded.resultWin===this.state.homeInfo.name&&scHome<=scAway){scHome = scAway+1}
                else if(calScoreTded.resultWin===this.state.awayInfo.name&&scAway>scHome){}
                else if(calScoreTded.resultWin===this.state.awayInfo.name&&scAway<=scHome){scAway = scHome+1}
                else if(calScoreTded.resultWin!==this.state.homeInfo.name&&calScoreTded.resultWin!==this.state.awayInfo.name){scHome>scAway?scAway=scHome:scHome=scAway}
                calScoreTded.score = scHome +' - '+ scAway
            }
        }
        

        return(
            this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" alt="Loading" /></div>:
            <HelmetProvider>
                <Helmet>
                <title>วิเคราะห์บอล {this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name} VS {this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name} {this.state.infoMatch.startdate.slice(0,10)}</title>
                <meta name="description" content={`วิเคราะห์บอลวันนี้ ฟันธงบอล รายการ ${this.state.infoMatch.tournament_stage_name} ${this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name} VS ${this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name} วันที่ ${this.state.infoMatch.startdate.slice(0,10)} โดย MVPKICK.COM เว็บไซต์ฟุตบอลอันดับ 1 ของไทย`} />
                <link rel="canonical" href={`https://mvpkick.com/tded/${this.state.id}/`} />
                <meta property="og:url" content={`https://mvpkick.com/tded/${this.state.id}/`} />
                <meta property="og:title" content={`วิเคราะห์บอล ${this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name} VS ${this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name} ${this.state.infoMatch.startdate.slice(0,10)}`} />
                <meta property="og:description" content={`วิเคราะห์บอลวันนี้ ฟันธงบอล รายการ ${this.state.infoMatch.tournament_stage_name} ${this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name} VS ${this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name} วันที่ ${this.state.infoMatch.startdate.slice(0,10)} โดย MVPKICK.COM เว็บไซต์ฟุตบอลอันดับ 1 ของไทย`} />
                <meta property="og:image" content="https://mvpkick.com/img/asset/og-img-main.jpg" />
                </Helmet>
                <div>
                    {/* s ส่วน Poster */}
                    <div>
                        <div className="thumbTdedImg">
                            <div className="tourNameTded col-12 py-2">{this.state.infoMatch.tournament_template_name} ({this.state.infoMatch.tournament_name})<br/>{this.state.infoMatch.tournament_stage_name}<br/>{this.state.infoMatch.startdate.slice(0,10)} {this.state.infoMatch.status_descFK==='6'?'(จบ)':''}</div>
                            <div className="topLogoH2h">
                                <div className="col-6"><LazyLoadImage className="logoTeamPage H2hTopLogo" alt={this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name} src={this.state.homeInfo.logo} /><br/>{this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name}</div>
                                <div className="col-6"><LazyLoadImage className="logoTeamPage H2hTopLogo " alt={this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name} src={this.state.awayInfo.logo} /><br/>{this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name}</div>
                            </div>
                            <div className="tourStartTded col-12"><span style={this.state.infoMatch.status_descFK!=='1'?{background:'#e3104a'}:{}}>{this.state.infoMatch.status_descFK!=='1'?this.state.infoMatch.status_descFK!=='6'?this.state.infoMatch.status_type:`${this.state.resultHome.finalresult} - ${this.state.resultAway.finalresult}`:this.state.infoMatch.startdate.slice(11,16)}</span></div>
                            <div className="col-12 py-2">{this.state.infoMatch.VenueName!==''?this.state.infoMatch.VenueName:''}</div>
                        </div>
                    </div>
                    {/* e ส่วน Poster */}
                    { /*console.log(this.state.pointCalH2HHva)*/}
                    { /*console.log(this.state.pointCalH2HHome)*/}
                    { /*console.log(this.state.pointCalH2HAway)*/}
                    {/* s ส่วน hva */}
                    <h3 className="text-center" style={{backgroundColor:'rgb(26 108 181)',padding:'5px'}}>การพบกัน</h3>
                    {this.state.homeInfo.id?<Hva idHome={this.state.homeInfo.id} nameHome={this.state.homeInfo.name} logoHome={this.state.homeInfo.logo} idAway={this.state.awayInfo.id} nameAway={this.state.awayInfo.name} logoAway={this.state.awayInfo.logo} connMobile={this.state.connMobile} keywords='hva' from="ContentTded" tourNameFocus={this.state.infoMatch.tournament_template_name }  parentCallback = {this.handleCallback} cupTour={this.state.cupTour} />:console.log()}
                    <h3 className="text-center" style={{backgroundColor:'rgb(26 108 181)',padding:'5px'}}>สถิติในบ้าน {this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name}</h3>
                    {this.state.homeInfo.id?<Hva idHome={this.state.homeInfo.id} nameHome={this.state.homeInfo.name} logoHome={this.state.homeInfo.logo} idAway={this.state.awayInfo.id} nameAway={this.state.awayInfo.name} logoAway={this.state.awayInfo.logo} connMobile={this.state.connMobile} keywords='home' from="ContentTded" tourNameFocus={this.state.infoMatch.tournament_template_name} parentCallback = {this.handleCallback} cupTour={this.state.cupTour} />:console.log()}
                    <h3 className="text-center" style={{backgroundColor:'rgb(26 108 181)',padding:'5px'}}>สถิตินอกบ้าน {this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name}</h3>
                    {this.state.homeInfo.id?<Hva idHome={this.state.homeInfo.id} nameHome={this.state.homeInfo.name} logoHome={this.state.homeInfo.logo} idAway={this.state.awayInfo.id} nameAway={this.state.awayInfo.name} logoAway={this.state.awayInfo.logo} connMobile={this.state.connMobile} keywords='away' from="ContentTded" tourNameFocus={this.state.infoMatch.tournament_template_name} parentCallback = {this.handleCallback} cupTour={this.state.cupTour} />:console.log()}
                    
                    {/* e ส่วน hva */}
                    
                    <div className="listMatch text-center boxFunTong">
                    {calScoreTded.percent===false?
                    <div>
                        <h3>สรุปผล ฟันธงบอลคู่นี้</h3>
                        <div className="my-5">
                        
                        <span className="px-3 my-5" style={{color:'orange',backgroundColor:'rgba(0, 0, 0, 0.25)'}}>{calScoreTded.resultWin}</span>
                        </div>
                    </div>:
                    this.state.infoMatch.status_descFK=='1'?
                    <div>
                        <h3>สรุปผล ฟันธงบอลคู่นี้</h3>
                        <div className="my-5">
                            {calScoreTded.resultWin==='เสมอกัน'?<div className="my-5"><LazyLoadImage className="logoTeamPage" alt={this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name} src={this.state.homeInfo.logo} /><LazyLoadImage className="logoTeamPage" alt={this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name} src={this.state.awayInfo.logo} /></div>:''}
                            {calScoreTded.resultWin===this.state.homeInfo.name?<div><LazyLoadImage className="logoTeamPage" alt={this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name} src={this.state.homeInfo.logo} /><br/>{calScoreTded.resultWin}</div>:calScoreTded.resultWin===this.state.awayInfo.name?<div><LazyLoadImage className="logoTeamPage" alt={this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name} src={this.state.awayInfo.logo} /><br/>{calScoreTded.resultWin}</div>:calScoreTded.resultWin}
                        </div>
                        <div className="mt-3"><span className="funtongScoreTded">ผลที่คาด : {calScoreTded.score}</span></div>
                        <div className="my-3">{calScoreTded.percent}</div>
                        <div className="my-3 px-3 col-8" style={{color:'#3ae09e',backgroundColor:'rgba(0, 0, 0, 0.25)',padding:'10px'}}>MVPKICK.COM ระบบจะวิเคราะห์บอล โดยอิงข้อมูลเฉพาะรายการแข่งที่เกี่ยวข้องเป็นหลัก รวมถึงคำนวนผลงานเฉพาะในบ้านของทีมเหย้า และผลงานนอกบ้านของทีมเยือน เพื่อให้ผลการวิเคราะห์มีความน่าจะเป็นมากที่สุด<br/><br/>ยกเว้นกลุ่มรายการบอลถ้วย อาทิ UCL WorldCup UEL EuroCup และอื่นๆที่มีสถิติรายการเดียวกันน้อยเกินไป ระบบจะคำนวนอิงจากทุกรายการแข่ง เฉพาะในบ้าน(ทีมเหย้า) และเฉพาะนอกบ้าน(ทีมเยือน) เป็นหลัก เนื่องจากมีสถิติแข่งในรายการเดียวกันจะมีค่อนข้างน้อย<br/><br/>วิเคราะห์บอลด้วยระบบที่แม่นยำ ต้อง "MVPKICK"</div>
                    </div>:
                    <div>
                        <h3>สรุปผล ฟันธงบอลคู่นี้</h3>
                        <div className="my-5">
                            <div className='sorryNoCalTded'>ขออภัย ! ระบบวิเคราะห์ได้เฉพาะโปรแกรมบอลที่ยังไม่เริ่มแข่งเท่านั้น</div>
                        </div>
                    </div>
                    }
                    </div>
                    
                    {/* s vote zone */}
                    {this.state.homeInfo.id&&this.state.awayInfo.id?<Votetded id={this.props.id} homeInfo={this.state.homeInfo} awayInfo={this.state.awayInfo} />:""}
                    {/* e vote zone */}

                    {this.state.homeInfo.id?
                    <div className="my-3">
                        <h3 className="text-center" style={{backgroundColor:'rgb(26 108 181)',padding:'5px'}}>อัตราต่อรอง</h3>
                        <Odds id={this.state.id} infoTeam={{home:this.state.homeInfo,away:this.state.awayInfo}}/>
                    </div>:
                    console.log()}
                </div>
            </HelmetProvider>
        )
    }

}

export default Contenttded;