import axios from 'axios'
import React from 'react'
import {Link} from "react-router-dom";
import Hva from '../../components/h2h/Hva'
import Odds from '../../components/h2h/Odds'
import Chartsh2h from './Chartsh2h';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet, HelmetProvider } from 'react-helmet-async';

class H2h extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            homeInfo : '',
            awayInfo : '',
            infoMatch: '',
            cdTime: {d:'-',h:'-',m:'-',s:'-'},
            connMobile:window.innerWidth>800?false:true,
            id:null,
            loading : true,
            havetded : false,
            wdlHome:[],
            wdlAway:[],
            idHome:null,
            idAway:null
        }
    }

    handleWindowsize = () =>{
        window.innerWidth>800?this.state.connMobile===false?console.log('handle size PC H2h'):this.setState({connMobile : false}):this.state.connMobile===true?console.log('handle size Mobile H2h'):this.setState({connMobile : true});
    }

    reciveHvaForCharts = (h2hData,keyword) =>{
        let arrSetWDL = []
        for(let i=0;i<h2hData.length;i++){
            if(i==0){
                if(h2hData[i].w==1){arrSetWDL.push(1)}
                else if(h2hData[i].d==1){arrSetWDL.push(0)}
                else if(h2hData[i].l==1){arrSetWDL.push(-1)}
            }else{
                if(h2hData[i].w==1){arrSetWDL.push(arrSetWDL[i-1]+1)}
                else if(h2hData[i].d==1){arrSetWDL.push(arrSetWDL[i-1]+0)}
                else if(h2hData[i].l==1){arrSetWDL.push(arrSetWDL[i-1]-1)}
            }
        }
        let allMatch = arrSetWDL.length
        //console.log(arrSetWDL)
        //console.log(h2hData)
        //console.log(keyword)
        keyword=='away'?this.setState({wdlAway:{wdl:arrSetWDL,matchcount:allMatch}}):this.setState({wdlHome:{wdl:arrSetWDL,matchcount:allMatch}})
    }

    componentDidMount() {
        
        window.addEventListener("resize", this.handleWindowsize);
        let homeInfo = {}
        let awayInfo = {}
        let infoMatch = {
            id: '',
            tournament_stageFK: '',
            startdate: '',
            status_type: '',
            status_descFK: '',
            tournamentFK: '',
            tournament_templateFK: '',
            tournament_stage_name: '',
            tournament_name: '',
            tournament_template_name: '',
            VenueName: ''
        }
        const {match} = this.props
        let {id} = match.params
        //axios.get(`https://sv.mvpkick.com/h2h/index.php?id=${id}`).then(res => {
        //axios.get(`http://localhost/event?id=${id}`).then(res => {
        axios.get(`https://api.mvpkick.com/event?id=${id}`).then(res => {
            if(res.data.msg!==undefined){
                this.setState({homeInfo : homeInfo, awayInfo : awayInfo, infoMatch : infoMatch, id : id, loading : false, havetded : havetded});
                setInterval(this.countdownTime, 1000);
            }else{
            let event = res.data;
            //console.log(event)
            //or (const keyEvent in event) {
                infoMatch = {
                    id: event.id,
                    tournament_stageFK: event.tournament_stageFK,
                    startdate: event.startdate,
                    status_type: event.status_type,
                    status_descFK: event.status_descFK,
                    tournamentFK: event.tournamentFK,
                    tournament_templateFK: event.tournament_templateFK,
                    tournament_stage_name: event.tournament_stage_name,
                    tournament_name: event.tournament_name,
                    tournament_template_name: event.tournament_template_name,
                }
                var havetded =  event.havetded
                let {event_participants, property} = event;
                for (const listEventParticipants in event_participants) {
                    let {number,participant} = event_participants[listEventParticipants];
                    switch(number){
                        case '1':homeInfo = participant;break;
                        case '2':awayInfo = participant;break;
                        default:break;
                    }
                }
                for (const listEventProperty in property) {
                    let {name, value} = property[listEventProperty];
                    switch(name){
                        case 'VenueName':infoMatch.VenueName=value;break;
                        default:break;
                    }
                }
                this.setState({homeInfo : homeInfo, awayInfo : awayInfo, infoMatch : infoMatch, id : id, loading : false, havetded : havetded,idHome : homeInfo.id, idAway:awayInfo.id});
                setInterval(this.countdownTime, 1000);
            }
        });
    }

    componentWillUnmount(){
        clearInterval(this.countdownTime);
        window.removeEventListener("resize", this.handleWindowsize);
    }

    countdownTime = () =>{
        let now = new Date().getTime();
        let distance = new Date(this.state.infoMatch.startdate).getTime() - now;  
        if (distance > 0) {
           // Time calculations for days, hours, minutes and seconds
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            
            this.setState({cdTime : {d : days, h : hours, m : minutes, s : seconds}})
        }
    }

    render(){
        //console.log(this.state.connMobile+" out")
        //console.log(this.state)
        return(
            <HelmetProvider>
                <Helmet>
                <title>{this.state.homeInfo.id&&this.state.awayInfo.id?`H2H ${this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name} VS ${this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name} การพบกัน สถิติล่าสุด ${this.state.infoMatch.startdate.slice(0,10)}`:`No Data H2H ID ${this.state.id}`}</title>
                <meta name="description" content={this.state.homeInfo.id&&this.state.awayInfo.id?`ข้อมูล H2H ล่าสุด ระหว่าง ${this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name} VS ${this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name} รอบการแข่งวันที่ ${this.state.infoMatch.startdate.slice(0,10)} โดยเว็บไซต์ MVPKICK.COM`:`No Data H2H Des ID ${this.state.id}`} />
                <link rel="canonical" href={`https://mvpkick.com/h2h/${this.state.id}/`} />
                <meta property="og:url" content={`https://mvpkick.com/h2h/${this.state.id}/`} />
                <meta property="og:title" content={this.state.homeInfo.id&&this.state.awayInfo.id?`H2H ${this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name} VS ${this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name} การพบกัน สถิติล่าสุด ${this.state.infoMatch.startdate.slice(0,10)}`:`No Data H2H ID ${this.state.id}`} />
                <meta property="og:description" content={this.state.homeInfo.id&&this.state.awayInfo.id?`ข้อมูล H2H ล่าสุด ระหว่าง ${this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name} VS ${this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name} รอบการแข่งวันที่ ${this.state.infoMatch.startdate.slice(0,10)} โดยเว็บไซต์ MVPKICK.COM`:`No Data H2H Des ID ${this.state.id}`} />
                <meta property="og:image" content="https://mvpkick.com/img/asset/og-img-main.jpg" />
                </Helmet>
                {this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" alt="Loading" /></div>:
                <div className="container">
                    <div className="text-center">
                        <div className="boxVSh2h my-3 col-12">
                            <div className="col-12" style={{fontSize:16}}><p>{this.state.infoMatch.tournament_template_name} {this.state.infoMatch.tournament_name} {this.state.infoMatch.tournament_stage_name}</p><p>{this.state.infoMatch.startdate.slice(0,10)} {this.state.infoMatch.startdate.slice(11,16)}</p></div>
                            <div className="topLogoH2h">
                                <div className="col-6"><LazyLoadImage className="logoTeamPage H2hTopLogo" alt={this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name} src={this.state.homeInfo.logo} /><br/>{this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name}</div>
                                <div className="col-6"><LazyLoadImage className="logoTeamPage H2hTopLogo" alt={this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name} src={this.state.awayInfo.logo} /><br/>{this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name}</div>
                            </div>
                            <div>
                                <div className="col-2 countdownBoxNum py-3">{this.state.cdTime.d} D</div>
                                <div className="col-2 countdownBoxNum py-3">{this.state.cdTime.h} H</div>
                                <div className="col-2 countdownBoxNum py-3">{this.state.cdTime.m} M</div>
                                <div className="col-2 countdownBoxNum py-3">{this.state.cdTime.s} S</div>
                            </div>
                            <p>VenueName : {this.state.infoMatch.VenueName}</p>
                        </div>
                    </div>
                    {this.state.havetded?<Link to={`/tded/${this.state.id}/`}><div className="wicroeballTitle text-center py-3" style={{margin:'15px auto',backgroundColor:'#1ca5d4',width:'70%'}}>อ่านบทวิเคราะห์คู่นี้ คลิ๊ก !!</div></Link>:console.log()}
                    {this.state.wdlHome.length!==0&&this.state.wdlAway.length!==0?<Chartsh2h wdlHome={this.state.wdlHome} wdlAway={this.state.wdlAway} idHome={this.state.idHome} idAway={this.state.idAway} homeInfo={this.state.homeInfo} awayInfo={this.state.awayInfo} />:''}
                    {this.state.homeInfo.id?<Odds id={this.state.id} infoTeam={{home:this.state.homeInfo,away:this.state.awayInfo}}/>:console.log()}
                    {this.state.homeInfo.id?<Hva idHome={this.state.homeInfo.id} nameHome={this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name} idAway={this.state.awayInfo.id} nameAway={this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name} connMobile={this.state.connMobile} keywords='hva' from="h2h" tourFKFocus={this.state.infoMatch.tournament_templateFK} tourNameFocus={this.state.infoMatch.tournament_template_name} logoHome={this.state.homeInfo.logo} logoAway={this.state.awayInfo.logo}/>:console.log()}
                    {this.state.homeInfo.id?<Hva h2hData={this.reciveHvaForCharts} idHome={this.state.homeInfo.id} nameHome={this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name} idAway={this.state.awayInfo.id} nameAway={this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name} connMobile={this.state.connMobile} keywords='home' from="h2h" tourFKFocus={this.state.infoMatch.tournament_templateFK} tourNameFocus={this.state.infoMatch.tournament_template_name} logoHome={this.state.homeInfo.logo} logoAway={this.state.awayInfo.logo}/>:console.log()}
                    {this.state.homeInfo.id?<Hva h2hData={this.reciveHvaForCharts} idHome={this.state.homeInfo.id} nameHome={this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name} idAway={this.state.awayInfo.id} nameAway={this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name} connMobile={this.state.connMobile} keywords='away' from="h2h" tourFKFocus={this.state.infoMatch.tournament_templateFK} tourNameFocus={this.state.infoMatch.tournament_template_name} logoHome={this.state.homeInfo.logo} logoAway={this.state.awayInfo.logo}/>:console.log()}
                </div>
                }
            </HelmetProvider>
        )
    }
}

export default H2h;