import React from 'react'
import ContentNews from '../components/news/ContentNews'
import Mainnews from '../components/news/Mainnews'

class News extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            focus : null,
            id : null
        }
    }
    componentDidMount(){
        let {match} = this.props;
        if(match===undefined){
            this.setState({focus : 'page'})
        }else{
            let {id} = match.params
            this.setState({focus : 'post', id : id})
        }
    }

    componentDidUpdate(){
        let {match} = this.props;
        if(match===undefined){
            this.state.focus==='page'?console.log():this.setState({focus : 'page',id : null})
        }else{
            let {id} = match.params
            this.state.focus==='post'&&this.state.id===id?console.log():this.setState({focus : 'post',id : id})
        }
    }

    render(){

        
        return(
            this.state.focus!==null?
            this.state.focus==='page'?
            <Mainnews />:
            <ContentNews id={this.state.id}/>:
            <div className="listMatch text-center">No Data</div>
        )
    }
}

export default News; 