import React from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'
import Commentaries from '../../components/viewmatch/Commentaries'
import Formation from '../../components/viewmatch/Formation'
import Standing from '../../components/viewmatch/Standing'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet, HelmetProvider } from 'react-helmet-async';

class Viewmatch extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            id:'',
            list:[],
            select:'viewmatch',
            idHome:'',
            nameHome:'',
            logoHome:'',
            idAway:'',
            nameAway:'',
            logoAway:'',
            loading:true
        }
    }

    componentDidMount(){
        const {match} = this.props
        let {id} = match.params
        //axios.get(`https://sv.mvpkick.com/viewmatch/index.php?id=${id}`).then(res=>{
            axios.get(`https://api.mvpkick.com/event?id=${id}`).then(res=>{
        //axios.get(`https://service.mvpkick.com/viewmatch/?id=${id}`).then(res=>{
            let listforSet = [];
            let idHome = '';
            let idAway = '';
            let nameHome = '';
            let nameAway = '';
            let logoHome = '';
            let logoAway = '';
            console.log(res.data)
            //var {event} = res.data;
            //for (const eventKey in event) {
                if(res.data.msg===undefined){
                    listforSet.push(res.data)
                    let {event_participants} = res.data;
                    for (const event_participantsKey in event_participants) {
                        let {number,participantFK,participant} = event_participants[event_participantsKey]
                        if(number==='1'){idHome = participantFK; nameHome = participant.name; logoHome = participant.logo }else if(number==='2'){idAway = participantFK; nameAway = participant.name; logoAway = participant.logo}
                    }
                    this.setState({list : listforSet, id : id, idAway : idAway, idHome : idHome, nameAway : nameAway, nameHome : nameHome, logoHome : logoHome, logoAway : logoAway,loading : false})
                }else{
                    this.setState({list : [], id : id, idAway : idAway, idHome : idHome, nameAway : nameAway, nameHome : nameHome, logoHome : logoHome, logoAway : logoAway,loading : false})
                }
        })
    }

    selectShow = (select) =>{
        this.state.select===select?console.log():this.setState({select : select})
    }

    render(){
        if(this.state.list.length>0){
            var dataViewmatch = this.state.list.map((item,index) =>{
                var homeInfo = {
                    participant:{
                        id:'',
                        name:'',
                        gender:'',
                        countryFK:'',
                        country_name:'',
                        logo:'',
                        shirt:'',
                        thainame:null
                    },
                    result:{
                        ordinarytime:'',
                        halftime:'',
                        finalresult:'',
                        runningscore:''
                    },
                    incident:[],
                    lineup:[],
                    lineupFmt:[],
                    fmt:''
                }
                var awayInfo = {
                    participant:{
                        id:'',
                        name:'',
                        gender:'',
                        countryFK:'',
                        country_name:'',
                        logo:'',
                        shirt:'',
                        thainame:null
                    },
                    result:{
                        ordinarytime:'',
                        halftime:'',
                        finalresult:'',
                        runningscore:''
                    },
                    incident:[],
                    lineup:[],
                    lineupFmt:[],
                    fmt:''
                }
                let {event_participants} = item
                for(const event_participantsKey in event_participants){
                    let {number,participant,result,incident,lineup,property} = event_participants[event_participantsKey];
                    if(number==='1'){
                        homeInfo.participant = participant;
                        for (const propertyKey in property) {
                            switch(property[propertyKey].name){
                                case'formation':homeInfo.fmt=property[propertyKey].value;break;
                                default:break;
                            }
                        }
                        for (const results in result) {
                            let {result_code,value} = result[results]
                            switch(result_code){
                                case 'runningscore':homeInfo.result.runningscore=value;break;
                                case 'ordinarytime':homeInfo.result.ordinarytime=value;break;
                                case 'halftime':homeInfo.result.halftime=value;break;
                                case 'finalresult':homeInfo.result.finalresult=value;break;
                                default:break;
                            }
                        }
                        for (const incidentKey in incident) {
                            let inc = {
                                select:'home',
                                incident_typeFK:null,
                                incident_code:null,
                                elapsed:null,
                                elapsed_plus:null,
                                id:null,
                                name:null,
                                incImg:null,
                                thainame:null
                            }
                            let {incident_typeFK,incident_code,elapsed,elapsed_plus,participant} = incident[incidentKey]
                            let incImg = null;
                            switch(incident_typeFK){
                                case '7': incImg = "/img/viewmatch/goal.png";break;
                                case '8': incImg = "/img/viewmatch/penalty.png";break;
                                case '9': incImg = "/img/viewmatch/penalty_miss.png";break;
                                case '10': incImg = "/img/viewmatch/own_goal.png";break;
                                case '11': incImg = "/img/viewmatch/penalty_miss.png";break;
                                case '12': incImg = "/img/viewmatch/penalty.png";break;
                                case '14': incImg = "/img/viewmatch/yellow_card.png";break;
                                case '15': incImg = "/img/viewmatch/yellow_card_2.png";break;
                                case '16': incImg = "/img/viewmatch/red_card.png";break;
                                case '18': incImg = "/img/viewmatch/goal.png";break;
                                case '20': incImg = "/img/viewmatch/subout.png";break;
                                case '32': incImg = "/img/viewmatch/subin.png";break;
                                case '34': incImg = "/img/viewmatch/assists.png";break;
                                case '35': incImg = "/img/viewmatch/assists_2.png";break;
                                default:break;
                            }
                            inc = {
                                incident_typeFK:incident_typeFK,
                                incident_code:incident_code,
                                elapsed:elapsed,
                                elapsed_plus:elapsed_plus,
                                id:participant.id,
                                name:participant.name,
                                select:'home',
                                incImg:incImg,
                                thainame:participant.thainame
                            }
                            homeInfo.incident.push(inc)
                        }
                        for (const lineupKey in lineup) {
                            let dataPlayer = {
                                shirt_number:null,
                                lineup_typeFK:null,
                                id:null,
                                name:null,
                                countryFK:null,
                                country_name:null,
                                type:null,
                                enet_pos:null,
                                pos:null,
                                flag:null,
                                thainame:null
                            }
                            let {shirt_number,participant,lineup_typeFK,pos,enet_pos} = lineup[lineupKey]
                            dataPlayer.shirt_number=shirt_number;
                            dataPlayer.lineup_typeFK=lineup_typeFK;
                            dataPlayer.id=participant.id;
                            dataPlayer.name=participant.name;
                            dataPlayer.countryFK=participant.countryFK;
                            dataPlayer.country_name=participant.country_name;
                            dataPlayer.type=participant.type;
                            dataPlayer.enet_pos=enet_pos;
                            dataPlayer.pos=pos;
                            dataPlayer.flag=participant.flag;
                            dataPlayer.thainame=participant.thainame;
                            homeInfo.lineup.push(dataPlayer)
                            homeInfo.lineupFmt[pos] = dataPlayer
                        }
                        //console.log(homeInfo.lineupFmt)
                        homeInfo.lineup.sort((a,b)=>a.lineup_typeFK-b.lineup_typeFK)
                        homeInfo.lineupFmt.sort((a,b)=>a.pos-b.pos)
                        //console.log(homeInfo.lineup)
                    }else if(number==='2'){
                        awayInfo.participant = participant;
                        for (const propertyKey in property) {
                            switch(property[propertyKey].name){
                                case'formation':awayInfo.fmt=property[propertyKey].value;break;
                                default:break;
                            }
                        }
                        for (const results in result) {
                            let {result_code,value} = result[results]
                            switch(result_code){
                                case 'runningscore':awayInfo.result.runningscore=value;break;
                                case 'ordinarytime':awayInfo.result.ordinarytime=value;break;
                                case 'halftime':awayInfo.result.halftime=value;break;
                                case 'finalresult':awayInfo.result.finalresult=value;break;
                                default:break;
                            }
                        }
                        for (const incidentKey in incident) {
                            let inc = {
                                select:'away',
                                incident_typeFK:null,
                                incident_code:null,
                                elapsed:null,
                                elapsed_plus:null,
                                id:null,
                                name:null,
                                incImg:null,
                                thainame:null
                            }
                            let {incident_typeFK,incident_code,elapsed,elapsed_plus,participant} = incident[incidentKey]
                            let incImg = null;
                            switch(incident_typeFK){
                                case '7': incImg = "/img/viewmatch/goal.png";break;
                                case '8': incImg = "/img/viewmatch/penalty.png";break;
                                case '9': incImg = "/img/viewmatch/penalty_miss.png";break;
                                case '10': incImg = "/img/viewmatch/own_goal.png";break;
                                case '11': incImg = "/img/viewmatch/penalty_miss.png";break;
                                case '12': incImg = "/img/viewmatch/penalty.png";break;
                                case '14': incImg = "/img/viewmatch/yellow_card.png";break;
                                case '15': incImg = "/img/viewmatch/yellow_card_2.png";break;
                                case '16': incImg = "/img/viewmatch/red_card.png";break;
                                case '18': incImg = "/img/viewmatch/goal.png";break;
                                case '20': incImg = "/img/viewmatch/subout.png";break;
                                case '32': incImg = "/img/viewmatch/subin.png";break;
                                case '34': incImg = "/img/viewmatch/assists.png";break;
                                case '35': incImg = "/img/viewmatch/assists_2.png";break;
                                default:break;
                            }
                            inc = {
                                incident_typeFK:incident_typeFK,
                                incident_code:incident_code,
                                elapsed:elapsed,
                                elapsed_plus:elapsed_plus,
                                id:participant.id,
                                name:participant.name,
                                select:'away',
                                incImg:incImg,
                                thainame:participant.thainame
                            }
                            awayInfo.incident.push(inc)
                        }
                        for (const lineupKey in lineup) {
                            let dataPlayer = {
                                shirt_number:null,
                                lineup_typeFK:null,
                                id:null,
                                name:null,
                                countryFK:null,
                                country_name:null,
                                type:null,
                                enet_pos:null,
                                pos:null,
                                flag:null,
                                thainame:null
                            }
                            let {shirt_number,participant,lineup_typeFK,pos,enet_pos} = lineup[lineupKey]
                            dataPlayer.shirt_number=shirt_number;
                            dataPlayer.lineup_typeFK=lineup_typeFK;
                            dataPlayer.id=participant.id;
                            dataPlayer.name=participant.name;
                            dataPlayer.countryFK=participant.countryFK;
                            dataPlayer.country_name=participant.country_name;
                            dataPlayer.type=participant.type;
                            dataPlayer.enet_pos=enet_pos;
                            dataPlayer.pos=pos;
                            dataPlayer.flag=participant.flag;
                            dataPlayer.thainame=participant.thainame;
                            awayInfo.lineup.push(dataPlayer)
                            awayInfo.lineupFmt[pos] = dataPlayer
                        }
                        awayInfo.lineup.sort((a,b)=>a.lineup_typeFK-b.lineup_typeFK)
                        awayInfo.lineupFmt.sort((a,b)=>b.pos-a.pos)
                        //console.log(awayInfo.lineup)
                        //console.log(awayInfo.lineupFmt)
                        
                    } // end if else number
                } // end for event_participants
                var newInc = [...homeInfo.incident,...awayInfo.incident]
                newInc.sort((a,b)=>a.elapsed - b.elapsed)
                console.log(this.state.list[0])

                return(
                    <div className="inc" key={index}>
                        {/* inc*/}
                        {this.state.select==='viewmatch'?
                        <div>
                            <div className="tournament text-center">Incident</div>
                            {newInc.length>0?
                            <div className="incBox">
                                {newInc.map((item,i) =>{
                                   // console.log(item)
                                    if((i>0&&newInc[i-1].elapsed<=45&&item.elapsed>45)||(newInc.length===1&&item.elapsed>45)||(i===0&&newInc[i+1].elapsed>45)){
                                        return item.select==='home'?
                                        <div key={i}><div className="incListHTFT listMatch">HT : {homeInfo.result.halftime} - {awayInfo.result.halftime}</div><div className="incListHome listMatch">{item.elapsed}' {item.incImg!==null?<LazyLoadImage className="logoTeamLs" alt={item.incident_code} src={item.incImg}/>:''} <Link to={`/player/${item.id}/`} target="_blank">{item.thainame!==null?item.thainame:item.name}</Link></div></div>:
                                        <div key={i}><div className="incListHTFT listMatch">HT : {homeInfo.result.halftime} - {awayInfo.result.halftime}</div><div className="incListAway listMatch"><Link to={`/player/${item.id}/`} target="_blank">{item.thainame!==null?item.thainame:item.name}</Link> {item.incImg!==null?<LazyLoadImage className="logoTeamLs" alt={item.incident_code} src={item.incImg}/>:''} {item.elapsed}'</div></div>
                                    }else if(newInc.length===1&&item.elapsed<=45){
                                        return item.select==='home'?
                                        <div key={i}><div className="incListHome listMatch">{item.elapsed}' {item.incImg!==null?<LazyLoadImage className="logoTeamLs" alt={item.incident_code} src={item.incImg}/>:''} <Link to={`/player/${item.id}/`} target="_blank">{item.thainame!==null?item.thainame:item.name}</Link></div><div className="incListHTFT listMatch">HT : {homeInfo.result.halftime} - {awayInfo.result.halftime}</div></div>:
                                        <div key={i}><div className="incListAway listMatch"><Link to={`/player/${item.id}/`} target="_blank">{item.thainame!==null?item.thainame:item.name}</Link> {item.incImg!==null?<LazyLoadImage className="logoTeamLs" alt={item.incident_code} src={item.incImg}/>:''} {item.elapsed}'</div><div className="incListHTFT listMatch">HT : {homeInfo.result.halftime} - {awayInfo.result.halftime}</div></div>
                                    }else if(i>0&&newInc[i-1].elapsed<=90&&i+1===newInc.length){
                                        return item.select==='home'?
                                        <div key={i}><div className="incListHome listMatch">{item.elapsed}' {item.incImg!==null?<LazyLoadImage className="logoTeamLs" alt={item.incident_code} src={item.incImg}/>:''} <Link to={`/player/${item.id}/`} target="_blank">{item.thainame!==null?item.thainame:item.name}</Link></div><div className="incListHTFT listMatch">FT : {homeInfo.result.finalresult} - {awayInfo.result.finalresult}</div></div>:
                                        <div key={i}><div className="incListAway listMatch"><Link to={`/player/${item.id}/`} target="_blank">{item.thainame!==null?item.thainame:item.name}</Link> {item.incImg!==null?<LazyLoadImage className="logoTeamLs" alt={item.incident_code} src={item.incImg}/>:''} {item.elapsed}'</div><div className="incListHTFT listMatch">FT : {homeInfo.result.finalresult} - {awayInfo.result.finalresult}</div></div>
                                    }else{
                                        return item.select==='home'?<div className="incListHome listMatch" key={i}>{item.elapsed}' {item.incImg!==null?<LazyLoadImage className="logoTeamLs" alt={item.incident_code} src={item.incImg}/>:''} <Link to={`/player/${item.id}/`} target="_blank">{item.thainame!==null?item.thainame:item.name}</Link></div>:<div className="incListAway listMatch" key={i}><Link to={`/player/${item.id}/`} target="_blank">{item.thainame!==null?item.thainame:item.name}</Link> {item.incImg!==null?<LazyLoadImage className="logoTeamLs" alt={item.incident_code} src={item.incImg}/>:''} {item.elapsed}'</div>
                                    }
                                })}
                            </div>:<div className="listMatch text-center">No Data Incident This Match</div>}
                        </div>:
                        this.state.select==='lineup'?

                        /*lineup*/ 
                        <div>
                            <div className="col-6 lineupHome"><h3 className="text-center"><LazyLoadImage className="logoTeamPage mb-3" alt={this.state.nameHome} src={this.state.logoHome} /><br/>Lineup : {homeInfo.participant.name}</h3>
                            <div className="tournament">
                                <div className="col-4 lineup">No.</div>
                                <div className="col-8 lineup">Name</div>
                            </div>
                            {homeInfo.lineup.length>0?
                            homeInfo.lineup.map((item,index) =>{
                                let lineup_typeFKThai = '';
                                switch (item.lineup_typeFK) {
                                    case '1':lineup_typeFKThai='GoalKeeper';break;
                                    case '2':lineup_typeFKThai='Defence';break;
                                    case '3':lineup_typeFKThai='Midfield';break;
                                    case '4':lineup_typeFKThai='Forward';break;
                                    case '5':lineup_typeFKThai='Substitute';break;
                                    case '7':lineup_typeFKThai='Injured';break;
                                    case '10':lineup_typeFKThai='Coach';break;
                                    default:lineup_typeFKThai=item.lineup_typeFK;break;
                                }
                                return(
                                    (index===0)||(index>0&&homeInfo.lineup[index-1].lineup_typeFK!==item.lineup_typeFK)?
                                    <div key={index}>
                                        <div className="col-12 rowTypePlayer"><span>{lineup_typeFKThai}</span></div>
                                            <div className="listMatch">
                                            <div className="col-4 lineup">{item.shirt_number}</div>
                                            <div className="col-8 lineup"><LazyLoadImage className="flagLs" alt={item.country_name} src={item.flag} /><Link to={`/player/${item.id}/`} target="_blank">{item.thainame!==null?item.thainame:item.name}</Link></div>
                                        </div>
                                    </div>
                                    :
                                    <div className="listMatch" key={index}>
                                        <div className="col-4 lineup">{item.shirt_number}</div>
                                        <div className="col-8 lineup"><LazyLoadImage className="flagLs" alt={item.country_name} src={item.flag} /><Link to={`/player/${item.id}/`} target="_blank">{item.thainame!==null?item.thainame:item.name}</Link></div>
                                    </div>
                                )
                            })
                            :<div className="listMatch text-center">No Lineup HomeTeam</div>}
                            </div>
                            <div className="col-6 lineupAway"><h3 className="text-center"><LazyLoadImage className="logoTeamPage mb-3" alt={this.state.nameAway} src={this.state.logoAway} /><br/>Lineup : {awayInfo.participant.name}</h3>
                            <div className="tournament">
                                <div className="col-4 lineup">No.</div>
                                <div className="col-8 lineup">Name</div>
                            </div>
                            {awayInfo.lineup.length>0?
                            awayInfo.lineup.map((item,index) =>{
                                let lineup_typeFKThai = '';
                                switch (item.lineup_typeFK) {
                                    case '1':lineup_typeFKThai='GoalKeeper';break;
                                    case '2':lineup_typeFKThai='Defence';break;
                                    case '3':lineup_typeFKThai='Midfield';break;
                                    case '4':lineup_typeFKThai='Forward';break;
                                    case '5':lineup_typeFKThai='Substitute';break;
                                    case '7':lineup_typeFKThai='Injured';break;
                                    case '10':lineup_typeFKThai='Coach';break;
                                    default:lineup_typeFKThai=item.lineup_typeFK;break;
                                }
                                return(
                                    (index===0)||(index>0&&awayInfo.lineup[index-1].lineup_typeFK!==item.lineup_typeFK)?
                                    <div key={index}>
                                        <div className="col-12 rowTypePlayer"><span>{lineup_typeFKThai}</span></div>
                                        <div className="listMatch">
                                            <div className="col-4 lineup">{item.shirt_number}</div>
                                            <div className="col-8 lineup"><LazyLoadImage className="flagLs" alt={item.country_name} src={item.flag} /><Link to={`/player/${item.id}/`} target="_blank">{item.thainame!==null?item.thainame:item.name}</Link></div>
                                        </div>
                                    </div>
                                    :
                                    <div className="listMatch" key={index}>
                                        <div className="col-4 lineup">{item.shirt_number}</div>
                                        <div className="col-8 lineup"><LazyLoadImage className="flagLs" alt={item.country_name} src={item.flag} /><Link to={`/player/${item.id}/`} target="_blank">{item.thainame!==null?item.thainame:item.name}</Link></div>
                                    </div>
                                )
                            })
                            :<div className="listMatch text-center">No Lineup AwayTeam</div>}
                            </div>
                            
                            <div className="clearboth"></div>
                        </div>
                        :this.state.select==='formation'?
                        
                        /* Formation */
                        <div className="mainFmtBox">
                        <div className="clearboth"></div>
                        {homeInfo.lineup.length>0&&homeInfo.fmt!==''?<Formation fmt={homeInfo.fmt} HorA='home' lineupFmt={homeInfo.lineupFmt} logo={homeInfo.participant.logo} shirt={homeInfo.participant.shirt} inc={newInc} />:<div className="listMatch text-center">No Data Formation Home Team</div>}
                        {awayInfo.lineup.length>0&&awayInfo.fmt!==''?<Formation fmt={awayInfo.fmt} HorA='away' lineupFmt={awayInfo.lineupFmt} logo={awayInfo.participant.logo} shirt={awayInfo.participant.shirt} inc={newInc} />:<div className="listMatch text-center">No Data Formation Away Team</div>}
                        </div>:''}
                    </div>
                )
                
            })
        }
        return(
            <HelmetProvider>
                <Helmet>
                <title>{this.state.list.length>0?`${this.state.nameHome} VS ${this.state.nameAway} รายละเอียดการแข่งขัน วันที่ ${this.state.list[0].startdate.slice(0,10)}`:`No Data ViewMatch ID ${this.state.id}`}</title>
                <meta name="description" content={this.state.list.length>0?`รายละเอียดโปรแกรมบอล ${this.state.nameHome} VS ${this.state.nameAway} รอบวันที่ ${this.state.list[0].startdate.slice(0,10)} ผลการแข่ง เหตุการณ์ระหว่างเกม รายชื่อผู้เล่น สถิติสำคัญ โดยเว็บไซต์ MVPKICK.COM`:`No Data ViewMatch Des ID ${this.state.id}`} />
                <link rel="canonical" href={`https://mvpkick.com/viewmatch/${this.state.id}/`} />
                <meta property="og:url" content={`https://mvpkick.com/viewmatch/${this.state.id}/`} />
                <meta property="og:title" content={this.state.list.length>0?`${this.state.nameHome} VS ${this.state.nameAway} รายละเอียดการแข่งขัน วันที่ ${this.state.list[0].startdate.slice(0,10)}`:`No Data ViewMatch ID ${this.state.id}`} />
                <meta property="og:description" content={this.state.list.length>0?`รายละเอียดโปรแกรมบอล ${this.state.nameHome} VS ${this.state.nameAway} รอบวันที่ ${this.state.list[0].startdate.slice(0,10)} ผลการแข่ง เหตุการณ์ระหว่างเกม รายชื่อผู้เล่น สถิติสำคัญ โดยเว็บไซต์ MVPKICK.COM`:`No Data ViewMatch Des ID ${this.state.id}`}  />
                <meta property="og:image" content="https://mvpkick.com/img/asset/og-img-main.jpg" />
                </Helmet>
                {this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" alt="Loading" /></div>:
                <div className="container">
                    <div className="text-center mb-3">
                        <div className={this.state.select==='viewmatch'?'active btn btn-h2h col-2':'btn btn-h2h col-2'} onClick={(e)=>this.selectShow('viewmatch')}>Info</div>
                        <div className={this.state.select==='commentaries'?'active btn btn-h2h col-2':'btn btn-h2h col-2'} onClick={(e)=>this.selectShow('commentaries')}>Timeline</div>
                        <div className={this.state.select==='standing'?'active btn btn-h2h col-2':'btn btn-h2h col-2'} onClick={(e)=>this.selectShow('standing')}>Stats</div>
                        <div className={this.state.select==='lineup'?'active btn btn-h2h col-2':'btn btn-h2h col-2'} onClick={(e)=>this.selectShow('lineup')}>Lineup</div>
                        <div className={this.state.select==='formation'?'active btn btn-h2h col-2':'btn btn-h2h col-2'} onClick={(e)=>this.selectShow('formation')}>Fmt</div>
                    </div>
                    {this.state.select==='commentaries'?<Commentaries id={this.state.id}/>:this.state.select==='standing'?<Standing id={this.state.id} idHome={this.state.idHome} idAway={this.state.idAway}/>:dataViewmatch?dataViewmatch.length>0?dataViewmatch:<div className="listMatch text-center">No Data Incident</div>:<div className="listMatch text-center">No Data Incident</div>}
                </div>
                }
            </HelmetProvider>
        )
    }
}

export default Viewmatch;