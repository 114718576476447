import React from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'
import Lastnextmatch from '../../components/league/Lastnextmatch'
import Statistics from '../../components/league/Statistics'
import Topplayer from '../../components/league/Topplayer'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Callchartsleague from '../../components/league/Callchartsleague'

class League extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            listSeason : [],
            listTour : [],
            id : null,
            nameLeague : null,
            selectSeason : null,
            listDataTour : [],
            selectMenu : 'info',
            connMobile:window.innerWidth>800?false:true,
            loading : true,
            selectChartId : null
        }
    }
    selectMenu = (select) =>{
        this.setState({selectMenu : select})
    }
    handleWindowsize = () =>{
        window.innerWidth>800?this.state.connMobile===false?console.log('handle size PC H2h'):this.setState({connMobile : false}):this.state.connMobile===true?console.log('handle size Mobile H2h'):this.setState({connMobile : true});
    }
    setSeason = (season,listSeason = this.state.listSeason,id = this.state.id, nameLeague = this.state.nameLeague) =>{
        //console.log(season)
        this.setState({loading:true})
        let listTour = [];
        let listDataTour = [];
        //axios.get(`https://sv.mvpkick.com/league/season.php?id=${season}`).then(res =>{
        axios.get(`https://api.mvpkick.com/tournament?id=${season}&season=yes`).then(res =>{
        //axios.get(`http://localhost/tournament?id=${season}&season=yes`).then(res =>{
            //let {tournament_stages} = res.data;
            //console.log(res.data)
            if(res.data.msg===undefined){
                for(const index in res.data){
                    listTour.push(res.data[index]);
                }
            }
            //console.log(listTour)
            /*
            for(const tournament_stagesKey in tournament_stages){
                listTour.push(tournament_stages[tournament_stagesKey])
            }
            */
           
            for(let i=0;i<listTour.length;i++){
                //console.log(listTour[i].id)
                //axios.get(`https://sv.mvpkick.com/league/tournament.php?id=${listTour[i].id}`).then(res=>{
                axios.get(`https://api.mvpkick.com/tournament?id=${listTour[i].id}&league_table=yes`).then(res=>{
                //axios.get(`http://localhost/tournament?id=${listTour[i].id}&league_table=yes`).then(res=>{
                    let arrKeep = [];
                    let standings
                    if(res.data.msg===undefined){
                        standings = res.data;
                        arrKeep.push(standings)
                    }
                    //arrKeep.push(standings)
                    //console.log(arrKeep)
                    //console.log(standings)
                    if(arrKeep.length>0){
                        //console.log(standings)
                        if(standings.msg!==undefined){
                            ///console.log('aaa')
                            //this.setState({id:id,loading:false})
                            
                            listDataTour.push({nameTour : listTour[i].name,standings :[]})
                            this.setState({ // setState ถี่เพื่อกัน loop for เสร็จก่อน
                                id:id,
                                selectSeason:season,
                                nameLeague:nameLeague,
                                listSeason:listSeason.slice(0,3),
                                listTour:listTour,
                                listDataTour:listDataTour,
                                loading:false
                            })
                        }else{
                                listDataTour.push({nameTour : listTour[i].name,standings :standings})
                                this.setState({ // setState ถี่เพื่อกัน loop for เสร็จก่อน
                                    id:id,
                                    selectSeason:season,
                                    nameLeague:nameLeague,
                                    listSeason:listSeason.slice(0,3),
                                    listTour:listTour,
                                    listDataTour:listDataTour,
                                    loading:false
                                })
                        }
                    }else{
                        this.setState({id:id,loading:false})
                    }
                })
            }
        })
    }

    componentDidMount(){
        window.addEventListener("resize", this.handleWindowsize);
        const {id} = this.props.match.params
        let selectSeason;
        let listSeason = [];
        /* call season */
        //axios.get(`https://sv.mvpkick.com/league/index.php?id=${id}`).then(res =>{ // season url
        axios.get(`https://api.mvpkick.com/tournament?id=${id}`).then(res =>{ // season url
        //axios.get(`http://localhost/tournament?id=${id}`).then(res =>{ // season url
        //let {tournaments} = res.data;
            //console.log(res.data)
            if(res.data.msg===undefined){
                for(const index in res.data){
                    listSeason.push(res.data[index]);
                }
            }
            //console.log(listSeason)


            //console.log(listSeason)
            //listSeason.sort((a,b)=>b.id - a.id)
            listSeason.sort((a,b)=>b.name.slice(0,4) - a.name.slice(0,4)) // slice สำหรับบางอันมีเป็น string เช่น 2019/2020 จะลบกันไม่ได้
            //console.log(listSeason)

            
            /* ใช้ชั่วคราว ลบ array ตัวปี 2022 (ตีวแรก) เพราะ Debug API เพี้ยน https://mvpkick.com/league/77/ */
            /*if(id=='77'){
                listSeason.shift()
            }*/
            /* ใช้ชั่วคราว ลบ array ตัวปี 2022 เพราะ Debug API เพี้ยน https://mvpkick.com/league/77/ */

            if(listSeason.length>0){
                
                //console.log(listSeason)
                selectSeason = listSeason[0].id;
                let nameLeague = listSeason[0].nameLeague;
                this.setSeason(selectSeason,listSeason,id,nameLeague)
            }else{
                this.setState({id:id,loading:false})
            }
        })
    }
    componentWillUnmount(){
        window.removeEventListener("resize", this.handleWindowsize);
    }

    selectIdTour = (id) =>{
        this.setState({selectChartId:id})
    }

    render(){
        
        let maxplayed = 0
        if(this.state.listDataTour.length>0){
           
            var dataLeague = this.state.listDataTour.map((item,index)=>{
                let {standings,nameTour} = item // ไม่ for in เพราะถอด key แล้วจาก componentDidMount
                let {standing_participants} = standings
                let listOneTour = [];
                for(const standing_participantsKey in standing_participants){
                    let iteamInfo = {
                        id:null,
                        name:null,
                        points:null,
                        goalsfor:null,
                        goalsagainst:null,
                        draws:null,
                        played:null,
                        wins:null,
                        defeits:null,
                        rank:null,
                        logo:null
                    }
                    let {participant,standing_data,rank} = standing_participants[standing_participantsKey]
                    for(let i=0;i<standing_data.length;i++){
                        switch(standing_data[i].code){
                            case'points':iteamInfo.points=standing_data[i].value;break;
                            case'goalsfor':iteamInfo.goalsfor=standing_data[i].value;break;
                            case'goalsagainst':iteamInfo.goalsagainst=standing_data[i].value;break;
                            case'draws':iteamInfo.draws=standing_data[i].value;break;
                            case'played':iteamInfo.played=standing_data[i].value;break;
                            case'wins':iteamInfo.wins=standing_data[i].value;break;
                            case'defeits':iteamInfo.defeits=standing_data[i].value;break;
                            default:break;
                        }
                    }
                    iteamInfo.id=participant.id;
                    iteamInfo.name=participant.name;
                    iteamInfo.thainame=participant.thainame;
                    iteamInfo.rank=rank;
                    iteamInfo.logo=participant.logo;
                    listOneTour.push(iteamInfo)
                }
                listOneTour.sort((a,b)=>a.rank-b.rank)
               // console.log(listOneTour)
                return(
                    <div key={index}>
                        <div className="tournament row">
                            <div className="col-12 text-center">{nameTour}</div>
                        </div>
                        <div className="listMatch text-center">
                            <div className="col-1">Rank</div>
                            <div className={this.state.connMobile?'col-4 text-center':'col-4 text-left'}>Team</div>
                            <div className="col-1">PL</div>
                            <div className="col-1">W</div>
                            <div className="col-1">D</div>
                            <div className="col-1">L</div>
                            <div className="col-1">G</div>
                            <div className="col-1">GL</div>
                            <div className="col-1">P</div>
                        </div>
                        {listOneTour.length>0?
                        listOneTour.map((item,i)=>{
                            //console.log(item.played)
                            if(item.played>maxplayed){
                                maxplayed = item.played
                            }
                            return(
                                <div className="listMatch text-center" key={i}>
                                    <div className="col-1">{item.rank}</div>
                                    <div className={this.state.connMobile?'col-4 text-center':'col-4 text-left'}><LazyLoadImage className="logoTeamLs" alt={item.thainame!==null?item.thainame:item.name} src={item.logo} /><br style={this.state.connMobile?{}:{display:'none'}}/><Link to={`/team/${item.id}/`} target="_blank" >{item.thainame!==null?item.thainame:item.name}</Link></div>
                                    <div className="col-1">{item.played}</div>
                                    <div className="col-1">{item.wins}</div>
                                    <div className="col-1">{item.draws}</div>
                                    <div className="col-1">{item.defeits}</div>
                                    <div className="col-1">{item.goalsfor}</div>
                                    <div className="col-1">{item.goalsagainst}</div>
                                    <div className="col-1">{item.points}</div>
                                </div>
                            )
                        })
                        :<div className="listMatch text-center">No Data</div>}
                        {this.state.connMobile==false?this.state.selectChartId==standings.objectFK?<div><div className="tourteamTable"><div className='btnSwitchChartActive' onClick={(e) =>this.selectIdTour(null)}>Hide Chart</div></div><Callchartsleague maxplayed={maxplayed} listTour={this.state.listTour} idTour={standings.objectFK} connMobile={this.state.connMobile} LorN='lastmatch'/></div>:<div className="tourteamTable"><div className="btnSwitchChart" onClick={(e) =>this.selectIdTour(standings.objectFK)}>See Chart</div></div>:''}
                    </div>
                )
            })
            //console.log(this.state.listDataTour)
        }else{
            if(this.state.loading===false){
                return <div className="listMatch text-center">No Data League</div>;
            }else{
                return <div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" alt="Loading"/></div>;
            }
        }
        return(
            <HelmetProvider>
                <Helmet>
                <title>{this.state.listSeason.length>0?`${this.state.listSeason[0].nameLeague} ข้อมูลลีก รายชื่อทีม โปรแกรมการแข่งขัน`:`No Data League ID ${this.state.id}`}</title>
                <meta name="description" content={this.state.listSeason.length>0?`ข้อมูลทัวร์นาเม้น ${this.state.listSeason[0].nameLeague} รายละเอียดการแข่ง รายชื่อสโมสร ผลบอล โปรแกรมบอล โดยเว็บไซต์ MVPKICK.COM`:`No Data League Des ID ${this.state.id}`} />
                <link rel="canonical" href={`https://mvpkick.com/league/${this.state.id}/`} />
                <meta property="og:url" content={`https://mvpkick.com/league/${this.state.id}/`} />
                <meta property="og:title" content={this.state.listSeason.length>0?`${this.state.listSeason[0].nameLeague} ข้อมูลลีก รายชื่อทีม โปรแกรมการแข่งขัน`:`No Data League ID ${this.state.id}`} />
                <meta property="og:description" content={this.state.listSeason.length>0?`ข้อมูลทัวร์นาเม้น ${this.state.listSeason[0].nameLeague} รายละเอียดการแข่ง รายชื่อสโมสร ผลบอล โปรแกรมบอล โดยเว็บไซต์ MVPKICK.COM`:`No Data League Des ID ${this.state.id}`} />
                <meta property="og:image" content="https://mvpkick.com/img/asset/og-img-main.jpg" />
                </Helmet>
                
            <div className="container">
                 <div className="text-center menuSeason">
                    <div className={this.state.selectMenu==='info'?'active btn btn-h2h col-2 league mb-2':'btn btn-h2h col-2 league mb-2'} onClick={(e)=>this.selectMenu('info')}>Info League</div>
                    <div className={this.state.selectMenu==='lastmatch'?'active btn btn-h2h col-2 league mb-2':'btn btn-h2h col-2 league mb-2'} onClick={(e)=>this.selectMenu('lastmatch')}>Last Match</div>
                    <div className={this.state.selectMenu==='nextmatch'?'active btn btn-h2h col-2 league mb-2':'btn btn-h2h col-2 league mb-2'} onClick={(e)=>this.selectMenu('nextmatch')}>Next Match</div>
                    <div className={this.state.selectMenu==='statistics'?'active btn btn-h2h col-2 league mb-2':'btn btn-h2h col-2 league mb-2'} onClick={(e)=>this.selectMenu('statistics')}>Statistics</div>
                    <div className={this.state.selectMenu==='topplayer'?'active btn btn-h2h col-2 league mb-2':'btn btn-h2h col-2 league mb-2'} onClick={(e)=>this.selectMenu('topplayer')}>Top Player</div>
                 </div>
                 <div className="text-center menuSeason my-3">
                     {this.state.listSeason.length>0?
                     this.state.listSeason.map((item,index)=>{
                        return <div className={this.state.selectSeason===item.id?'active btn btn-h2h col-3':'btn btn-h2h col-3'} onClick={(e)=>this.setSeason(item.id)} key={item.id}>{item.name}</div>
                     })
                     :''
                     }
                </div>
                {this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" alt="Loading" /></div>:
                this.state.selectMenu==='info'?dataLeague?dataLeague.length>0?<div>{dataLeague}</div>:<div className="listMatch text-center">No Data League</div>:<div className="listMatch text-center">No Data League</div>:
                this.state.selectMenu==='lastmatch'?<Lastnextmatch listTour={this.state.listTour} connMobile={this.state.connMobile} LorN='lastmatch'/>:
                this.state.selectMenu==='nextmatch'?<Lastnextmatch listTour={this.state.listTour} connMobile={this.state.connMobile} LorN='nextmatch'/>:
                this.state.selectMenu==='statistics'?<Statistics listTour={this.state.listTour} connMobile={this.state.connMobile}/>:
                this.state.selectMenu==='topplayer'?<Topplayer listTour={this.state.listTour} connMobile={this.state.connMobile}/>:console.log('bbb')
                }
            </div>
            </HelmetProvider>
        )
    }
}

export default League;