import axios from 'axios'
import React from 'react'
import Nextmatch from '../../components/team/Nextmatch'
import Lastmatch from '../../components/team/Lastmatch'
import Standingtour from '../../components/team/Standingtour'
import {Link} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Team extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            list : [],
            id:'',
            select:'player',
            connMobile:window.innerWidth>800?false:true,
            loading : true,
            selectTour : null
        }
    }

    componentDidMount(){
        window.addEventListener("resize", this.handleWindowsize);
        const {match} = this.props
        let {id} = match.params

        //axios.get(`https://sv.mvpkick.com/team/index.php?id=${id}`).then(res => {
        //axios.get(`https://service.mvpkick.com/team/?id=${id}`).then(res => {
        axios.get(`https://api.mvpkick.com/team/?id=${id}`).then(res => {
            //axios.get(`http://localhost/team/?id=${id}`).then(res => {
            //var {team} = res.data;
            //console.log(res.data)
            let listforSet = [];
            if(res.data.msg===undefined){
                listforSet.push(res.data)
            }
            //console.log(listforSet)
            this.setState({list : listforSet, id : id , loading : false})
        })
    }
    componentWillUnmount(){
        window.removeEventListener("resize", this.handleWindowsize);
    }

    selectShow = (select) =>{
        this.state.select===select?console.log():this.setState({select : select})
    }

    handleWindowsize = () =>{
        window.innerWidth>800?this.state.connMobile===false?console.log('handle size PC Team'):this.setState({connMobile : false}):this.state.connMobile===true?console.log('handle size Mobile Team'):this.setState({connMobile : true});
    }

    setMainSelectTour = (idSelect) =>{
        console.log('q2')
        this.setState({selectTour:idSelect})
    }

    render(){

        if(this.state.list.length>0){
             var dataTeam = this.state.list.map((item,index) =>{

                //var urlTeam = '';
                //var name = item.name;
                let players = [];
                let tours = [];
                let {property, participants, tournament_stages} = item;

                for(const propertyKey in property){
                    switch(property[propertyKey].name){
                        //case'HomePage':urlTeam=property[propertyKey].value;break;
                        default:break;
                    }
                }
                for(const participantsKey in participants){
                    let dataPlayer = {
                        date_from:null,
                        date_to:null,
                        ShirtNumber:null,
                        name:null,
                        id:null,
                        type:null,
                        gender:null,
                        countryFK:null,
                        country_name:null,
                        flagLogo:null,
                        thainame:null

                    }
                    
                    let {properties,participant} = participants[participantsKey];
                    participants[participantsKey].date_from==='0000-00-00'?dataPlayer.date_from='-':dataPlayer.date_from = participants[participantsKey].date_from
                    participants[participantsKey].date_to==='0000-00-00'?dataPlayer.date_to = '-':dataPlayer.date_to = participants[participantsKey].date_to;
                    for(const propertiesKey in properties){
                        switch(properties[propertiesKey].name){
                            case'ShirtNumber':dataPlayer.ShirtNumber=properties[propertiesKey].value;break;
                            default:break;
                        }
                    }
                    if(participant){
                        
                    }
                    
                    for(const participantKey in participant){
                        let {id,name,gender,type,countryFK,country_name,flagLogo,imgplayer,thainame} = participant[participantKey];
                        dataPlayer.name=name;
                        dataPlayer.id=id;
                        dataPlayer.type=type;
                        dataPlayer.gender=gender;
                        dataPlayer.countryFK=countryFK;
                        dataPlayer.country_name=country_name;
                        dataPlayer.flagLogo=flagLogo;
                        dataPlayer.imgplayer=imgplayer;
                        dataPlayer.thainame=thainame;
                    }
                    players.push(dataPlayer)
                }
                players.sort((a, b) =>{
                    return a.type.length - b.type.length;
                })
                for(const tournament_stagesKey in tournament_stages){
                    let dataTour = {
                        name:null,
                        id:null,
                        tournamentFK:null,
                        startdate:null,
                        enddate:null,
                        countryFK:null,
                        country_name:null

                    }
                    let{tournament_stage} = tournament_stages[tournament_stagesKey]
                    for(const tournament_stageKey in tournament_stage){
                        dataTour = tournament_stage[tournament_stageKey]
                    }
                    tours.push(dataTour)
                }

                return(
                    <div key={index} className="text-center">

                        {/* player */}
                        <div className="tournament row">
                            <div className="col-1">No.</div><div className="col-6 text-left">Name</div><div className="col-3">in-out</div><div className="col-2">Type</div>
                        </div>
                        {players.length>0?
                        players.map((item,i)=>{
                            return(
                                <div className="listMatch" key={i}>
                                    <div className="col-1">{item.ShirtNumber}</div>
                                    <div className="col-6 text-left"><LazyLoadImage className="flagLs" src={item.flagLogo} alt={item.country_name} /><LazyLoadImage className="playerImg" src={item.imgplayer} alt={item.thainame===null?item.name:item.thainame} /><Link to={`/player/${item.id}/`} target="_blank">{item.thainame===null?item.name:item.thainame}</Link></div>
                                    <div className="col-3">{item.date_from}<br/>{item.date_to}</div>
                                    <div className="col-2">{item.type}</div>
                                </div>
                                )
                        }):
                        <div className="listMatch">
                            <div className="col-12 text-center">No Data Player</div>
                        </div>}
                        
                        {/* tournament */}
                        
                        {/*
                        // Start ตรงนี้เป็น Chart Tournament Comment ชั่วคราวเพื่อความโหลดไว้ใน Lighthouse ขึ้น

                        <div className="tournament row">
                            <div className="col-12 text-left"><span style={{paddingLeft:'10px'}}>Tournaments ( Start to End )</span></div>
                        </div>
                        {tours.length>0?
                        tours.map((item,i)=>{
                            //console.log(item)
                            if(item.name!==null){
                                //console.log(this.state.selectTour+'q3')
                            
                                return(
                                    <div className="listMatch" key={i}>
                                       <div className="col-12 text-left headTourTeam"><span style={{paddingLeft:'10px'}}><LazyLoadImage className="flagLs" alt={item.country_name} src={item.flag} />{item.name}</span></div>
                                        <Standingtour id={item.id} from="pageteam" connMobile={this.state.connMobile} teamFocus={this.state.id} setSelectIdTour={this.setMainSelectTour} selectTour={this.state.selectTour} />
                                        <div className="tourteamTable pb-2">( {item.startdate.slice(0,10)} to {item.enddate.slice(0,10)} )</div>
                                    </div>
                                   <></>
                                    )
                            }
                            
                        }):
                        <div className="listMatch">
                            <div className="col-12 text-center">No Data Tournament</div>
                        </div>}
                        
                        // Start ตรงนี้เป็น Chart Tournament Comment ชั่วคราวเพื่อความโหลดไว้ใน Lighthouse ขึ้น
                        */}

                    </div>
                )
             })
            
        }
        return(
            <HelmetProvider>
                <Helmet>
                <title>{this.state.list.length>0?`${this.state.list[0].name} ข้อมูลทีม รายชื่อนักเตะ โปรแกรมบอล`:`No Data Team ID ${this.state.id}`}</title>
                <meta name="description" content={this.state.list.length>0?`ข้อมูลทีมฟุตบอล ${this.state.list[0].name} รายละเอียดนักฟุตบอล รายชื่อผู้เล่น โปรแกรมบอลล่วงหน้า ผลบอลย้อนหลัง โดยเว็บไซต์ MVPKICK.COM`:`No Data Team Des ID ${this.state.id}`} />
                <link rel="canonical" href={`https://mvpkick.com/team/${this.state.id}/`} />
                <meta property="og:url" content={`https://mvpkick.com/team/${this.state.id}/`} />
                <meta property="og:title" content={this.state.list.length>0?`${this.state.list[0].name} ข้อมูลทีม รายชื่อนักเตะ โปรแกรมบอล`:`No Data Team ID ${this.state.id}`} />
                <meta property="og:description" content={this.state.list.length>0?`ข้อมูลทีมฟุตบอล ${this.state.list[0].name} รายละเอียดนักฟุตบอล รายชื่อผู้เล่น โปรแกรมบอลล่วงหน้า ผลบอลย้อนหลัง โดยเว็บไซต์ MVPKICK.COM`:`No Data Team Des ID ${this.state.id}`} />
                <meta property="og:image" content="https://mvpkick.com/img/asset/og-img-main.jpg" />
                </Helmet>
            <div className="container">
                
                <div className="text-center">
                    {this.state.list.length>0?
                    <div className="mb-3">
                        <img className="logoTeamPage" alt={this.state.list[0].thainame===null?this.state.list[0].name:this.state.list[0].thainame} src={this.state.list[0].logo} /><p>{this.state.list[0].thainame===null?this.state.list[0].name:`${this.state.list[0].thainame}`}</p><br/>
                        {this.state.list[0].shirt.shirthome!=='https://service.mvpkick.com/images/shirt/noshirt.png'?<img alt='shirthome' src={this.state.list[0].shirt.shirthome} />:''}
                        {this.state.list[0].shirt.shirthome!=='https://service.mvpkick.com/images/shirt/noshirt.png'?<img alt='shirtaway' src={this.state.list[0].shirt.shirtaway} />:''}
                        {this.state.list[0].shirt.shirthome!=='https://service.mvpkick.com/images/shirt/noshirt.png'?<img alt='cup1' src={this.state.list[0].shirt.cup1} />:''}
                        {this.state.list[0].shirt.shirthome!=='https://service.mvpkick.com/images/shirt/noshirt.png'?<img alt='cup2' src={this.state.list[0].shirt.cup2} />:''}
                        {this.state.list[0].shirt.shirthome!=='https://service.mvpkick.com/images/shirt/noshirt.png'?<img alt='cup3' src={this.state.list[0].shirt.cup3} />:''}
                        {this.state.list[0].shirt.shirthome!=='https://service.mvpkick.com/images/shirt/noshirt.png'?<img alt='cup4' src={this.state.list[0].shirt.cup4} />:''}
                    </div>:
                    console.log()}
                </div>
                <div className="text-center my-3">
                    <div className={this.state.select==='player'?'active btn btn-h2h col-3':'btn btn-h2h col-3'} onClick={(e)=>this.selectShow('player')}>Player List</div>
                    <div className={this.state.select==='nextmatch'?'active btn btn-h2h col-3':'btn btn-h2h col-3'} onClick={(e)=>this.selectShow('nextmatch')}>Next Match</div>
                    <div className={this.state.select==='lastmatch'?'active btn btn-h2h col-3':'btn btn-h2h col-3'} onClick={(e)=>this.selectShow('lastmatch')}>Last Match</div>
                </div>
                {this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" alt="Loading" /></div>:
                this.state.select==='nextmatch'?<Nextmatch id={this.state.id} connMobile={this.state.connMobile} />:this.state.select==='lastmatch'?<Lastmatch id={this.state.id} connMobile={this.state.connMobile} from="TeamPage" />:dataTeam?dataTeam.length>0?dataTeam:<div className="text-center listMatch">No Data</div>:<div className="text-center listMatch">No Data</div>
                }
            </div>
            </HelmetProvider>
        )
    }
}

export default Team;