import React from 'react'
import axios from 'axios'

class Commentaries extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            list : [],
            id : this.props.id,
            loading:true
        }
    }

    componentDidMount(){
        axios.get(`https://api.mvpkick.com/event?commentaries=yes&id=${this.state.id}`).then(res =>{
        //axios.get(`https://service.mvpkick.com/viewmatch/commentaries/?id=${this.state.id}`).then(res =>{
            if(res.data.msg===undefined){
                var {event_incident} = res.data;
                let listforSet = [];
                for (const commentaries of event_incident) {
                    listforSet.push(commentaries)
                }
                console.log(listforSet)
                this.setState({list : listforSet, loading : false})
            }else{
                this.setState({list : [], loading : false})
            }
            
        })
    }

    render(){
        if(this.state.list.length>0){
            var cmtaries = this.state.list.map((item,index) =>{
                return(
                    <div className="listMatch" key={index}>
                        <div className="col-3 text-center">{item.elapsed_plus>0?`${item.elapsed}+${item.elapsed_plus}`:item.elapsed}</div>
                        <div className="col-9">{item.event_incident_text.map((cmItem)=>{ return cmItem})}</div>
                    </div>
                )
            })
        }
        return(
            this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" alt="Loading"/></div>:
                this.state.list.length>0?
                <div>
                    <div className="tournament">
                    <div className="col-3 text-center">Elapsed</div>
                    <div className="col-9">Des</div>
                    </div>
                {cmtaries}
                </div>
                :<div className="listMatch text-center">No Commentaries</div>
            
        )
    }
}

export default Commentaries;