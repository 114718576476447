import React from 'react'
import axios from 'axios'
import {Link} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Hva extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            loading : true,
            home:{
                id:this.props.idHome,
                name:this.props.nameHome,
                logo:this.props.logoHome
            },
            away:{
                id:this.props.idAway,
                name:this.props.nameAway,
                logo:this.props.logoAway
            },
            cupTour:this.props.cupTour,
            list:[],
            connMobile:this.props.connMobile,
            HorA : `${this.props.from==='ContentTded'?this.props.keywords==='away'?'away':'home':'all'}`,
            tour : `${this.props.from==='ContentTded'&&this.props.cupTour===undefined?this.props.tourNameFocus:'All'}`, // พิมใหญ่
            countShow : 'all',
            keywords:this.props.keywords,
            from : this.props.from,
            tourFKFocus : this.props.tourFK,
            tourNameFocus : this.props.tourNameFocus,
            hvaListSelectCount : [],
            sum : {w:0,d:0,l:0},
            sumAway : {w:0,d:0,l:0},
            resultCalSelectTeam : null,
            hvaList : null,
            resultWDL :null,
            listTour : ['All'],
            calTdedObj : null,
            sumGoals : null
        }
    }

    callData = (list) =>{

        //console.log(this.props.cupTour)
        
        if(list.length>0){
            var wdl = []
            var goals = []
            var listTour = ['All']
            var hvaList = list.map((item,index)=>{
                //console.log(item,index)
                let {event_participants} = item;
                let homeInfo = {
                    participant:{
                        id:'',
                        name:'',
                        gender:'',
                        countryFK:'',
                        country_name:'',
                        logo:''
                    },
                    result:{
                        ordinarytime:0,
                        halftime:0,
                        finalresult:0,
                        runningscore:0
                    }
                }
                let awayInfo = {
                    participant:{
                        id:'',
                        name:'',
                        gender:'',
                        countryFK:'',
                        country_name:'',
                        logo:''
                    },
                    result:{
                        ordinarytime:0,
                        halftime:0,
                        finalresult:0,
                        runningscore:0
                    }
                }

                const found = listTour.find(tourName => tourName === item.tournament_template_name);
                if(found===item.tournament_template_name){
                }else{
                    listTour.push(item.tournament_template_name)
                }
                
                for (const listEventParticipants in event_participants) {
                    let {number,participant,result} = event_participants[listEventParticipants];
                    if(number==='1'){
                        homeInfo.participant = participant;
                        for (const results in result) {
                            let {result_code,value} = result[results]
                            switch(result_code){
                                case 'runningscore':homeInfo.result.runningscore=value;break;
                                case 'ordinarytime':homeInfo.result.ordinarytime=value;break;
                                case 'halftime':homeInfo.result.halftime=value;break;
                                case 'finalresult':homeInfo.result.finalresult=value;break;
                                default:break;
                            }
                        }
                    }else if(number==='2'){
                        awayInfo.participant = participant;
                        for (const results in result) {
                            let {result_code,value} = result[results]
                            switch(result_code){
                                case 'runningscore':awayInfo.result.runningscore=value;break;
                                case 'ordinarytime':awayInfo.result.ordinarytime=value;break;
                                case 'halftime':awayInfo.result.halftime=value;break;
                                case 'finalresult':awayInfo.result.finalresult=value;break;
                                default:break;
                            }
                        }
                    }
                }
                // cal WDL 
                var selectTeamId;
                this.state.keywords==='home'||this.state.keywords==='hva'?selectTeamId=this.state.home.id:selectTeamId=this.state.away.id
                //console.log(awayInfo.result)
                if(homeInfo.participant.id===selectTeamId){
                    goals.push({
                        gfHome:parseInt(homeInfo.result.finalresult),
                        gfAway:0,
                        glHome:parseInt(awayInfo.result.finalresult),
                        glAway:0
                    })
                    if(homeInfo.result.finalresult-awayInfo.result.finalresult>0){
                        wdl.push({w:1,d:0,l:0});
                    }else if(homeInfo.result.finalresult-awayInfo.result.finalresult===0){
                        wdl.push({w:0,d:1,l:0})
                    }else if(homeInfo.result.finalresult-awayInfo.result.finalresult<0){
                        wdl.push({w:0,d:0,l:1})
                    }
                }else if(awayInfo.participant.id===selectTeamId){
                    
                    goals.push({
                        gfHome:0,
                        gfAway:parseInt(awayInfo.result.finalresult),
                        glHome:0,
                        glAway:parseInt(homeInfo.result.finalresult)
                    })
                    if(awayInfo.result.finalresult-homeInfo.result.finalresult>0){
                        wdl.push({w:1,d:0,l:0});
                    }else if(awayInfo.result.finalresult-homeInfo.result.finalresult===0){
                        wdl.push({w:0,d:1,l:0})
                    }else if(awayInfo.result.finalresult-homeInfo.result.finalresult<0){
                        wdl.push({w:0,d:0,l:1})
                    }
                }

                if(this.state.HorA==='home'&&homeInfo.participant.id!==selectTeamId){
                    return undefined;
                }else if(this.state.HorA==='away'&&awayInfo.participant.id!==selectTeamId){
                    return undefined;
                }

                if(this.state.tour!=='All'&&item.tournament_template_name!==this.state.tour){
                    return undefined;
                }

                return(
                    this.state.from==='h2h'?
                    // H2H Zone
                    <div key={item.id}>
                        {this.props.connMobile===false?
                        // PC 
                        <div className="listMatch">
                            <div className="col-2 text-center">{item.startdate.substr(0,10)}<br /><Link to={`/league/${item.tournament_templateFK}/`}>{item.tournament_template_name}</Link></div>
                            <div className="col-3 text-right"><Link to={`/team/${homeInfo.participant.id}/`} target="_blank">{homeInfo.participant.name}</Link><LazyLoadImage className="logoTeamLs" alt={homeInfo.participant.name} src={homeInfo.participant.logo} /></div>
                            <div className="col-2 text-center ftScore"><Link to={`/viewmatch/${item.id}/`} target="_blank"><span>{homeInfo.result.finalresult+' - '+awayInfo.result.finalresult}</span></Link></div>
                            <div className="col-3"><LazyLoadImage className="logoTeamLs" alt={awayInfo.participant.name} src={awayInfo.participant.logo} /><Link to={`/team/${awayInfo.participant.id}/`} target="_blank">{awayInfo.participant.name}</Link></div>
                            <div className="col-2 text-center text-white">{wdl[index].w===1?<span className="h2hw">W</span>:wdl[index].d===1?<span className="h2hd">D</span>:<span className="h2hl">L</span>}</div>
                        </div>:
                        // Mobile 
                        <div className="listMatch">
                            <div className="col-2 text-center">{item.startdate.substr(0,10)}<br /><Link to={`/league/${item.tournament_templateFK}/`}>{item.tournament_template_name}</Link></div>
                            <div className="col-6"><LazyLoadImage className="logoTeamLs" alt={homeInfo.participant.name} src={homeInfo.participant.logo} /><Link to={`/team/${homeInfo.participant.id}/`} target="_blank">{homeInfo.participant.name}</Link><br /><LazyLoadImage className="logoTeamLs" alt={awayInfo.participant.name} src={awayInfo.participant.logo} /><Link to={`/team/${awayInfo.participant.id}/`} target="_blank">{awayInfo.participant.name}</Link></div>
                            <div className="col-1 text-center"><Link to={`/viewmatch/${item.id}/`} target="_blank">{homeInfo.result.finalresult}<br />{awayInfo.result.finalresult}</Link></div>
                            <div className="col-1 text-center">{homeInfo.result.halftime}<br />{awayInfo.result.halftime}</div>
                            <div className="col-2 text-center text-white">{wdl[index].w===1?<span className="h2hw">W</span>:wdl[index].d===1?<span className="h2hd">D</span>:<span className="h2hl">L</span>}</div>
                        </div>
                        }
                        
                    </div>:
                    // Content Tded Zone
                        
                    <div key={item.id}>
                        {this.props.connMobile===false?
                        //pc
                        <div className="listMatch">
                            <div className="col-2 text-center">{item.startdate.substr(0,10)}</div>
                            <div className="col-3 text-right">{homeInfo.participant.name}<LazyLoadImage className="logoTeamLs" alt={homeInfo.participant.name} src={homeInfo.participant.logo} /></div>
                            <div className="col-2 text-center ftScore"><span>{homeInfo.result.finalresult+' - '+awayInfo.result.finalresult}</span></div>
                            <div className="col-3"><LazyLoadImage className="logoTeamLs" alt={awayInfo.participant.name} src={awayInfo.participant.logo} />{awayInfo.participant.name}</div>
                            <div className="col-2 text-center">{item.tournament_template_name}</div>
                        </div>:
                        <div className="listMatch">
                            <div className="col-2 text-center">{item.startdate.substr(0,10)}<br />{item.tournament_template_name}</div>
                            <div className="col-6"><LazyLoadImage className="logoTeamLs" alt={homeInfo.participant.name} src={homeInfo.participant.logo} /><Link to={`/team/${homeInfo.participant.id}/`} target="_blank">{homeInfo.participant.name}</Link><br /><LazyLoadImage className="logoTeamLs" alt={awayInfo.participant.name} src={awayInfo.participant.logo} /><Link to={`/team/${awayInfo.participant.id}/`} target="_blank">{awayInfo.participant.name}</Link></div>
                            <div className="col-1 text-center"><Link to={`/viewmatch/${item.id}/`} target="_blank">{homeInfo.result.finalresult}<br />{awayInfo.result.finalresult}</Link></div>
                            <div className="col-1 text-center">{homeInfo.result.halftime}<br />{awayInfo.result.halftime}</div>
                            <div className="col-2 text-center text-white">{wdl[index].w===1?<span className="h2hw">W</span>:wdl[index].d===1?<span className="h2hd">D</span>:<span className="h2hl">L</span>}</div>
                        </div>
                         }       
                    </div>
                )
            })
            //console.log(listTour)

            for(let z=0;z<hvaList.length;z++){
                if(hvaList[z]===undefined){
                    hvaList.splice(z,1)
                    wdl.splice(z,1)
                    goals.splice(z,1)
                    z--;
                }
            }
            var hvaListSelectCount = hvaList;

            if(this.state.countShow!=='all'){
                hvaListSelectCount = hvaList.slice(0,this.state.countShow);
                wdl = wdl.slice(0,this.state.countShow);
            }
            /* s sent wdl to parent h2h.js */
            //console.log(wdl)
            if(this.props.h2hData){
                this.props.h2hData(wdl,this.state.keywords)
            }
            /* e sent wdl to parent h2h.js */
            if(hvaListSelectCount.length===0){hvaListSelectCount=<div className="listMatch"><div className="col-12 text-center">No Match</div></div>}
           
            var sum = {w:0,d:0,l:0}
            var sumAway = {w:0,d:0,l:0}
            var sumGoals = {gfHome:0,glHome:0,gfAway:0,glAway:0}
            for(let i=0;i<wdl.length;i++){
                switch(1){
                    case wdl[i].w:sum.w++;sumAway.l++;break;
                    case wdl[i].d:sum.d++;sumAway.d++;break;
                    case wdl[i].l:sum.l++;sumAway.w++;break;
                    default:break;
                }
                sumGoals.gfHome += goals[i].gfHome;
                sumGoals.gfAway += goals[i].gfAway;
                sumGoals.glHome += goals[i].glHome;
                sumGoals.glAway += goals[i].glAway;
            }

            //console.log(sumGoals)

            var resultWDL = (
            <div className="tournament" key='hvaResult'>
                <div className="col-12 text-center">รวม {hvaListSelectCount.length} นัด : ชนะ {sum.w} เสมอ {sum.d} แพ้ {sum.l}</div>
            </div>
            )

            //console.log(hvaList)
            // s cal สูตร TDed 

            var resultCalSelectTeam = '';
            var difGoalPerGame = '';
            var difGoalPerGameNoSelect = '';
            var goalPerGame = '';
            var goalPerGameNoSelect = '';
            var lostGoalPerGame = '';
            var lostGoalPerGameNoSelect = '';

            if(this.state.from==='ContentTded'&&hvaList.length>=1){
                
                if(sum.w>sum.l){
                    if(this.state.keywords==='away'){
                        //console.log(goals)
                        resultCalSelectTeam = this.state.away.name;
                        difGoalPerGame = (sumGoals.gfAway-sumGoals.glAway)/hvaList.length
                        difGoalPerGameNoSelect = (sumGoals.glAway-sumGoals.gfAway)/hvaList.length
                        goalPerGame = sumGoals.gfAway/hvaList.length
                        goalPerGameNoSelect = sumGoals.glAway/hvaList.length
                        lostGoalPerGame = sumGoals.glAway/hvaList.length
                        lostGoalPerGameNoSelect = sumGoals.gfAway/hvaList.length

                    }else if(this.state.keywords==='home'||this.state.keywords==='hva'){
                        resultCalSelectTeam = this.state.home.name;
                        difGoalPerGame = (sumGoals.gfHome-sumGoals.glHome)/hvaList.length
                        difGoalPerGameNoSelect = (sumGoals.glHome-sumGoals.gfHome)/hvaList.length
                        goalPerGame = sumGoals.gfHome/hvaList.length
                        goalPerGameNoSelect = sumGoals.glHome/hvaList.length
                        lostGoalPerGame = sumGoals.glHome/hvaList.length
                        lostGoalPerGameNoSelect = sumGoals.gfHome/hvaList.length
                    }

                }else if(sum.w===sum.l){
                    resultCalSelectTeam = "draw"
                    if(this.state.keywords==='away'){
                        difGoalPerGame = (sumGoals.gfAway-sumGoals.glAway)/hvaList.length
                        difGoalPerGameNoSelect = (sumGoals.glAway-sumGoals.gfAway)/hvaList.length
                        goalPerGame = sumGoals.gfAway/hvaList.length
                        goalPerGameNoSelect = sumGoals.glAway/hvaList.length
                        lostGoalPerGame = sumGoals.glAway/hvaList.length
                        lostGoalPerGameNoSelect = sumGoals.gfAway/hvaList.length
                    }else if(this.state.keywords==='home'||this.state.keywords==='hva'){
                        difGoalPerGame = (sumGoals.gfHome-sumGoals.glHome)/hvaList.length
                        difGoalPerGameNoSelect = (sumGoals.glHome-sumGoals.gfHome)/hvaList.length
                        goalPerGame = sumGoals.gfHome/hvaList.length
                        goalPerGameNoSelect = sumGoals.glHome/hvaList.length
                        lostGoalPerGame = sumGoals.glHome/hvaList.length
                        lostGoalPerGameNoSelect = sumGoals.gfHome/hvaList.length
                    }

                }else if(sum.w<sum.l){
                    if(this.state.keywords==='away'){
                        resultCalSelectTeam = this.state.home.name;
                        difGoalPerGame = (sumGoals.gfAway-sumGoals.glAway)/hvaList.length
                        difGoalPerGameNoSelect = (sumGoals.glAway-sumGoals.gfAway)/hvaList.length
                        goalPerGame = sumGoals.gfAway/hvaList.length
                        goalPerGameNoSelect = sumGoals.glAway/hvaList.length
                        lostGoalPerGame = sumGoals.glAway/hvaList.length
                        lostGoalPerGameNoSelect = sumGoals.gfAway/hvaList.length
                        
                    }else if(this.state.keywords==='home'||this.state.keywords==='hva'){
                        resultCalSelectTeam = this.state.away.name;
                        difGoalPerGame = (sumGoals.gfHome-sumGoals.glHome)/hvaList.length
                        difGoalPerGameNoSelect = (sumGoals.glHome-sumGoals.gfHome)/hvaList.length
                        goalPerGame = sumGoals.gfHome/hvaList.length
                        goalPerGameNoSelect = sumGoals.glHome/hvaList.length
                        lostGoalPerGame = sumGoals.glHome/hvaList.length
                        lostGoalPerGameNoSelect = sumGoals.gfHome/hvaList.length
                    }
                }
            }else{
                resultCalSelectTeam = false
                difGoalPerGame = false
                difGoalPerGameNoSelect = false
                goalPerGame = false
                goalPerGameNoSelect = false
                lostGoalPerGame = false
                lostGoalPerGameNoSelect = false
            }

            let calTdedObj = {
                resultCalSelectTeam : resultCalSelectTeam,
                difGoalPerGame : difGoalPerGame,
                difGoalPerGameNoSelect : difGoalPerGameNoSelect,
                goalPerGame : goalPerGame,
                goalPerGameNoSelect : goalPerGameNoSelect,
                lostGoalPerGame : lostGoalPerGame,
                lostGoalPerGameNoSelect : lostGoalPerGameNoSelect
            }
            // e cal สูตร TDed 

            if(this.state.from==='ContentTded'){this.props.parentCallback(this.state.keywords,calTdedObj)}

            this.setState({
                hvaListSelectCount : hvaListSelectCount,
                sum : sum,
                sumAway : sumAway,
                resultCalSelectTeam : resultCalSelectTeam,
                hvaList : hvaList,
                resultWDL : resultWDL,
                loading : false,
                listTour : listTour,
                list : list,
                calTdedObj : calTdedObj,
                sumGoals : sumGoals,
                connMobile : this.props.connMobile
            })

        }else{
            if(this.state.loading!==false){this.setState({loading : false})}
        }

    }

    componentDidMount(){

        
        var urlApi;
        if(this.state.keywords==='home'){
            urlApi =  `https://api.mvpkick.com/team?id=${this.state.home.id}&lastmatch=yes`;
            //urlApi =  `http://localhost/team?id=${this.state.home.id}&lastmatch=yes`;
            //urlApi =  `https://service.mvpkick.com/h2h/?id=${this.state.home.id}`;
        }else if(this.state.keywords==='away'){
            urlApi =  `https://api.mvpkick.com/team?id=${this.state.away.id}&lastmatch=yes`;
            //urlApi =  `http://localhost/team?id=${this.state.away.id}&lastmatch=yes`;
            //urlApi =  `https://service.mvpkick.com/h2h/?id=${this.state.away.id}`;
        }else if(this.state.keywords==='hva'){
            //urlApi =  `http://localhost/h2h/?idhome=${this.state.home.id}&idaway=${this.state.away.id}`;
            urlApi = `https://api.mvpkick.com/h2h/?idhome=${this.state.home.id}&idaway=${this.state.away.id}`;
            //urlApi =  `https://service.mvpkick.com/h2h/?idhome=${this.state.home.id}&idaway=${this.state.away.id}`;
        }

        axios.get(urlApi).then(res => {
            //console.log(res.data)
            if(res.data.msg){
                this.setState({list : [], loading : false})
            }else{
                var {events} = res.data;
                let listforSet = [];
                for (const listEvent of events) {
                    listforSet.push(listEvent)
                }
                listforSet.sort((a, b) => new Date(b.startdate) - new Date(a.startdate));
                if(listforSet.length>0){
                    if(this.props.keywords==='away'){/*console.log(listforSet)*/}
                    this.callData(listforSet);
                }else{
                    let calTdedObj = {
                        resultCalSelectTeam : false,
                        difGoalPerGame : false,
                        difGoalPerGameNoSelect : false,
                        goalPerGame : false,
                        goalPerGameNoSelect : false,
                        lostGoalPerGame : false,
                        lostGoalPerGameNoSelect : false
                    }
                    if(this.state.from==='ContentTded'){this.props.parentCallback(this.state.keywords,calTdedObj)}
                    this.setState({list : [], loading : false})
                }
            }
        
            
            //this.setState({list : listforSet, loading : false})
        })


    }
    componentDidUpdate(){
        
        //console.log(this.props.connMobile + " " + this.state.connMobile)
        if(this.state.connMobile!==this.props.connMobile){/*this.setState({connMobile : this.props.connMobile}); */this.callData(this.state.list); }
        if(this.state.loading===true){this.callData(this.state.list);}
        
        //console.log('update')

        
        
    }

    setHva = (reciveHorA = 'all') =>{
        this.setState({HorA : reciveHorA,loading : true})
        
    }

    setTour = (event) =>{
        this.setState({tour : event.target.value,loading : true}) // from select option
    }

    setTourFocusCT = (nameTour) =>{
        
        this.setState({tour : nameTour,loading : true})
    }

    setCountShow = (event) =>{
        this.setState({countShow : event.target.value,loading : true})
    }

    render(){
        return(
            this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" alt="Loading" className="loadingImg" /></div>:
            this.state.from==='h2h'?
            // h2h Zone
            <div>
                {this.state.hvaListSelectCount?this.state.hvaListSelectCount.length>0?
                <div>
                    {/* s chart set */}
                    <div style={{maxWidth:'800px',margin: 'auto'}}>
                    <div className="chartH2h col-6">
                        <div className="chartH2hInner fading" style={{
                            background:`conic-gradient(
                                #15a46a ${((this.state.sum.w/this.state.hvaListSelectCount.length)*100)/2}%, #21c382 0 ${(this.state.sum.w/this.state.hvaListSelectCount.length)*100}%,
                                orange 0 ${(((this.state.sum.w/this.state.hvaListSelectCount.length)*100)+((this.state.sum.d/this.state.hvaListSelectCount.length)*100)/2)}%, #ffc300 0 ${((this.state.sum.w/this.state.hvaListSelectCount.length)*100)+((this.state.sum.d/this.state.hvaListSelectCount.length)*100)}%, 
                                #f92a38 0 ${((this.state.sum.w/this.state.hvaListSelectCount.length)*100)+((this.state.sum.d/this.state.hvaListSelectCount.length)*100)+(((this.state.sum.l/this.state.hvaListSelectCount.length)*100)/2)}%, #ff3946 0 ${((this.state.sum.w/this.state.hvaListSelectCount.length)*100)+((this.state.sum.d/this.state.hvaListSelectCount.length)*100)+((this.state.sum.l/this.state.hvaListSelectCount.length)*100)}%,
                                #000 0
                                )`,
                            borderRadius:'50%',
                            height:'0',
                            margin: '10px'
                        }}> 
                        </div>
                    </div>
                    <div className="chartH2h col-6" style={{textAlign: '-webkit-left'}}>
                        <div className="percentResult">
                        <div style={{textAlign:'center'}}>
                            {this.state.keywords==='away'?
                            <LazyLoadImage className="logoTeamPage H2hTopLogoTded" alt={this.state.away.name} src={this.state.away.logo} />
                            :this.state.keywords==='home'?
                            <LazyLoadImage className="logoTeamPage H2hTopLogoTded" alt={this.state.home.name} src={this.state.home.logo} />
                            :<div><LazyLoadImage className="logoTeamPage H2hTopLogoTded" alt={this.state.home.name} src={this.state.home.logo} /><LazyLoadImage className="logoTeamPage H2hTopLogoTded" alt={this.state.away.name} src={this.state.away.logo} /></div>}
                        </div>
                        <h4 className="text-center">{this.state.keywords==='hva'?`${this.state.home.name} VS ${this.state.away.name}`:this.state.keywords==='home'?this.state.home.name:this.state.away.name}</h4>
                        <div className="wPercent">
                            <div className="col-3 wdlCol"><span>Win :</span></div>
                            <div className="col-9 progressCol">
                            <div className="boxProgress" style={{width:`${((this.state.sum.w/this.state.hvaListSelectCount.length)*100).toFixed(2)}%`}}>   
                                <div className="progress-v1 progress-striped-green">
                                    <div className="progress-bar-v1">{((this.state.sum.w/this.state.hvaListSelectCount.length)*100).toFixed(2)}%</div>                       
                                </div> 
                            </div>
                            </div>
                        </div>
                        <div className="dPercent">
                            <div className="col-3 wdlCol"><span>Draw :</span></div>
                            <div className="col-9 progressCol">
                            <div className="boxProgress" style={{width:`${((this.state.sum.d/this.state.hvaListSelectCount.length)*100).toFixed(2)}%`}}>   
                                <div className="progress-v1 progress-striped-orange">
                                    <div className="progress-bar-v1">{((this.state.sum.d/this.state.hvaListSelectCount.length)*100).toFixed(2)}%</div>                       
                                </div> 
                            </div>
                            </div>
                        </div>
                        <div className="lPercent">
                        <div className="col-3 wdlCol"><span>Lose :</span></div>
                            <div className="col-9 progressCol">
                            <div className="boxProgress" style={{width:`${((this.state.sum.l/this.state.hvaListSelectCount.length)*100).toFixed(2)}%`}}>   
                                <div className="progress-v1 progress-striped-red">
                                    <div className="progress-bar-v1">{((this.state.sum.l/this.state.hvaListSelectCount.length)*100).toFixed(2)}%</div>                       
                                </div> 
                            </div>
                            </div>    
                        </div>
                        </div>
                    </div>
                    </div>
                    {/* e chart set */}
                <div className="tournament" key='HeaderRow'>
                    <div className="text-center">
                        <div className={this.state.HorA==='home'?'active btn btn-h2h col-3':'btn btn-h2h col-3'} onClick={(e)=>this.setHva('home')}>Home</div>
                        <div className={this.state.HorA==='all'?'active btn btn-h2h col-3':'btn btn-h2h col-3'} onClick={(e)=>this.setHva('all')}>All</div>
                        <div className={this.state.HorA==='away'?'active btn btn-h2h col-3':'btn btn-h2h col-3'} onClick={(e)=>this.setHva('away')}>Away</div>
                    </div>
                    <div className="text-center">
                        <div className="col-6 py-2">Tournament<br/>{this.state.listTour?this.state.listTour.length>0?<select className="selTour" onChange={this.setTour} value={this.state.tour}>{this.state.listTour.map(item=>{return <option key={item}>{item}</option>})}</select>:console.log():console.log()}</div>
                        <div className="col-6 py-2">Total Match<br/>{this.state.hvaList?this.state.hvaList.length>0?<select className="selCountMacth" onChange={this.setCountShow} value={this.state.countShow}>{this.state.hvaList.map((item,index)=>{return <option key={index} value={this.state.hvaList.length-index}>{this.state.hvaList.length-index}</option>})}</select>:<select className="selCountMacth"><option>No Match</option></select>:console.log()}</div>
                    </div>

                    <div className="col-12 text-center">Home VS Away</div>
                </div>
                <div>
                    {this.state.hvaListSelectCount}
                    {this.state.resultWDL}
                </div>
                </div>
                :console.log():console.log()}
            </div>:
            // Content Tded Zone
            <div className="tdedContentMainBlock containerLs">
                <div className="col-3 text-center cTdedHome">
                    <LazyLoadImage className="logoTeamPage H2hTopLogoTded " alt={this.state.keywords==='away'?'ทีมอื่นๆ':this.state.home.name} src={this.state.keywords==='away'?'https://sv.mvpkick.com/img/logo/nologo.png':this.state.home.logo} /><p className="switchShowInTdedPage">{this.state.keywords==='away'?'ทีมอื่นๆ':this.state.home.name}</p>
                    {this.state.hvaList?this.state.hvaList.length>0?
                    <div className="col-12 progressColTded">
                        <div><span className="switchHideInTdedPage">{this.state.keywords==='away'?'ทีมอื่นๆ':this.state.home.name}</span><span>ชนะ : {this.state.keywords==='away'?(((this.state.sumAway.w/this.state.hvaListSelectCount.length)*100)).toFixed(2):((this.state.sum.w/this.state.hvaListSelectCount.length)*100).toFixed(2)}%</span> <br className="switchShowInTdedPage" /> <span>เสมอ : {this.state.keywords==='away'?(((this.state.sumAway.d/this.state.hvaListSelectCount.length)*100)).toFixed(2):((this.state.sum.d/this.state.hvaListSelectCount.length)*100).toFixed(2)}%</span> <br className="switchShowInTdedPage" /> <span>แพ้ : {this.state.keywords==='away'?(((this.state.sumAway.l/this.state.hvaListSelectCount.length)*100)).toFixed(2):((this.state.sum.l/this.state.hvaListSelectCount.length)*100).toFixed(2)}%</span></div>
                        <div className="col-12">
                            <div className="boxProgress boxProgressTded" style={{width:'100%'}}>   
                                <div className="contentTded progress-v1 progress-striped-green" style={{width:`${this.state.keywords==='away'?((this.state.sumAway.w/this.state.hvaListSelectCount.length)*100).toFixed(2):((this.state.sum.w/this.state.hvaListSelectCount.length)*100).toFixed(2)}%`}}><div className="progress-bar-v1"></div></div>
                                <div className="contentTded progress-v1 progress-striped-orange" style={{width:`${this.state.keywords==='away'?((this.state.sumAway.d/this.state.hvaListSelectCount.length)*100).toFixed(2):((this.state.sum.d/this.state.hvaListSelectCount.length)*100).toFixed(2)}%`}}><div className="progress-bar-v1"></div></div> 
                                <div className="contentTded progress-v1 progress-striped-red" style={{width:`${this.state.keywords==='away'?((this.state.sumAway.l/this.state.hvaListSelectCount.length)*100).toFixed(2):((this.state.sum.l/this.state.hvaListSelectCount.length)*100).toFixed(2)}%`}}><div className="progress-bar-v1"></div></div>
                            </div>
                        </div>
                    </div>:
                    '':''}
                </div>
                <div className="col-6 cTdedMain subLs">
                <div className="cdListMatch">
                    <div className="ctMenu text-center">
                        <div className="col-5 btn btn-ctSelect" style={this.state.tour==='All'?{backgroundColor:'#1ca5d4'}:{}} onClick={(e)=>this.setTourFocusCT('All')}>ทุกรายการ</div>
                        <div className="col-5 btn btn-ctSelect" style={this.state.tour===this.state.tourNameFocus?{backgroundColor:'#1ca5d4'}:{}} onClick={(e)=>this.setTourFocusCT(this.state.tourNameFocus)}>เฉพาะรายการ</div>
                        <div className="col-5 btn btn-ctSelect" style={this.state.HorA==='all'?{backgroundColor:'#1ca5d4'}:{}} onClick={(e)=>this.setHva('all')}>ทั้งหมด</div>
                        <div className="col-5 btn btn-ctSelect" style={this.state.HorA!=='all'?{backgroundColor:'#1ca5d4'}:{}} onClick={(e)=>this.setHva(`${this.state.keywords==='away'?'away':'home'}`)}>เหย้า-เยือน</div>
                    </div>
                    {this.state.hvaList?this.state.hvaList.length>0?this.state.hvaList:<div className="text-center py-3">ไม่มีข้อมูล รายการที่เกี่ยวข้อง</div>:<div className="text-center py-3">ไม่มีข้อมูล รายการที่เกี่ยวข้อง</div>}
                    {this.state.hvaList?this.state.hvaList.length>0?this.state.resultWDL:'':''}
                </div>
                    {this.state.hvaList?this.state.hvaList.length>0?
                    <div className="col-12">
                        <div className="text-center py-3 px-3 boxReviewTded">
                            {this.state.keywords==='hva'?`การพบกันในรายการ ${this.state.tour==='All'?'ทั้งหมด':this.state.tour} ${this.state.HorA==='home'?`เทียบเฉพาะในบ้าน ${this.state.home.name}`:this.state.HorA==='away'?'เฉพาะนอกบ้าน':''} รวมทั้งสิ้น ${this.state.hvaListSelectCount.length} นัด ${this.state.home.name} ชนะ ${this.state.sum.w} เสมอ ${this.state.sum.d} และแพ้ ${this.state.sum.l} ยิงประตูรวม ${this.state.sumGoals.gfHome} เสียประตูรวม ${this.state.sumGoals.glHome} คิดเป็นอัตราประตูได้เสียเฉลี่ย เท่ากับ ${this.state.calTdedObj.difGoalPerGame.toFixed(1)} ลูกต่อแมตซ์`:
                            this.state.keywords==='home'?`สถิติผลงานรายการ ${this.state.tour==='All'?'ทั้งหมด':this.state.tour} ${this.state.HorA==='home'?'เฉพาะในบ้าน':this.state.HorA==='away'?'เฉพาะนอกบ้าน':''} ของ ${this.state.home.name} รวมทั้งหมด ${this.state.hvaListSelectCount.length} รายการ แบ่งเป็น ชนะ ${this.state.sum.w} เสมอ ${this.state.sum.d} และแพ้ ${this.state.sum.l} ยิงประตูรวม ${this.state.sumGoals.gfHome} เสียประตูรวม ${this.state.sumGoals.glHome} คิดเป็นอัตราประตูได้เสียเฉลี่ย เท่ากับ ${this.state.calTdedObj.difGoalPerGame.toFixed(1)} ลูกต่อแมตซ์`:
                            this.state.keywords==='away'?`สถิติผลงานรายการ ${this.state.tour==='All'?'ทั้งหมด':this.state.tour} ${this.state.HorA==='home'?'เฉพาะในบ้าน':this.state.HorA==='away'?'เฉพาะนอกบ้าน':''} ของ ${this.state.away.name} รวมทั้งหมด ${this.state.hvaListSelectCount.length} รายการ แบ่งเป็น ชนะ ${this.state.sum.w} เสมอ ${this.state.sum.d} และแพ้ ${this.state.sum.l} ยิงประตูรวม ${this.state.sumGoals.gfAway} เสียประตูรวม ${this.state.sumGoals.glAway} คิดเป็นอัตราประตูได้เสียเฉลี่ย เท่ากับ ${this.state.calTdedObj.difGoalPerGame.toFixed(1)} ลูกต่อแมตซ์`:''
                            }
                        </div>
                        <div className="py-3 text-center resultTdedPoint">
                            {
                            this.state.keywords==='hva'?this.state.home.name===this.state.resultCalSelectTeam?<div><LazyLoadImage className="logoLikeTded" alt='like' src='/img/icon/like.webp' /><br/>การพบกัน {this.state.resultCalSelectTeam} มีภารวมที่ดีกว่า</div>:
                            this.state.resultCalSelectTeam==='draw'?this.state.sumGoals.gfHome>this.state.sumGoals.glHome?<div><LazyLoadImage className="logoLikeTded" alt='like' src='/img/icon/like.webp' /><br/>การพบกัน ผลงานใกล้เคียงกัน แต่จำนวนประตูได้เสีย ทาง {this.state.home.name} ดีกว่า</div>:this.state.sumGoals.gfHome===this.state.sumGoals.glHome?<div><LazyLoadImage className="logoLikeTded" alt='like' src='/img/icon/like.webp' /><LazyLoadImage className="logoLikeTded" alt='dislike' src='/img/icon/dislike.webp' /><br/>ทั้งคู่ผลงานไม่ต่างกันนัก</div>:<div><LazyLoadImage className="logoLikeTded" alt='dislike' src='/img/icon/dislike.webp' /><br/>การพบกัน ผลงานใกล้เคียงกัน แต่ {this.state.away.name} มีประตูได้เสียดีกว่า</div>:
                            <div><LazyLoadImage className="logoLikeTded" alt='dislike' src='/img/icon/dislike.webp' /><br/>การพบกัน {this.state.resultCalSelectTeam} มีภารวมที่ดีกว่า</div>:
                                
                            this.state.keywords==='home'?this.state.home.name===this.state.resultCalSelectTeam?<div><LazyLoadImage className="logoLikeTded" alt='like' src='/img/icon/like.webp' /><br/><Link to={`/team/${this.state.home.id}/`} target="_blank">{this.state.home.name}</Link> ผลงาน{this.state.HorA==='home'?'เฉพาะในบ้าน':this.state.HorA==='away'?'เฉพาะนอกบ้าน':''}ดูใช้ได้</div>:
                            this.state.resultCalSelectTeam==='draw'?this.state.sumGoals.gfHome>this.state.sumGoals.glHome?<div><LazyLoadImage className="logoLikeTded" alt='like' src='/img/icon/like.webp' /><br/>ผลแพ้ชนะใกล้เคียงกัน แต่จำนวนประตูได้เสีย ทาง <Link to={`/team/${this.state.home.id}/`} target="_blank">{this.state.home.name}</Link> ดีกว่า</div>:this.state.sumGoals.gfHome===this.state.sumGoals.glHome?<div><LazyLoadImage className="logoLikeTded" alt='like' src='/img/icon/like.webp' /><LazyLoadImage className="logoLikeTded" alt='dislike' src='/img/icon/dislike.webp' /><br/>ผลแพ้ชนะใกล้เคียงกันมาก</div>:<div><LazyLoadImage className="logoLikeTded" alt='dislike' src='/img/icon/dislike.webp' /><br/>ผลแพ้ชนะใกล้เคียงกัน แต่ ทีมอื่นๆที่เคยพบกัน มีประตูได้เสียรวมดีกว่า</div>:
                            <div><LazyLoadImage className="logoLikeTded" alt='dislike' src='/img/icon/dislike.webp' /><br/><Link to={`/team/${this.state.home.id}/`} target="_blank">{this.state.home.name}</Link> ผลงาน{this.state.HorA==='home'?'เฉพาะในบ้าน':this.state.HorA==='away'?'เฉพาะนอกบ้าน':''}ดูไม่ดีนัก</div>:
                            
                            this.state.keywords==='away'?this.state.away.name===this.state.resultCalSelectTeam?<div><LazyLoadImage className="logoLikeTded" alt='like' src='/img/icon/like.webp' /><br/><Link to={`/team/${this.state.away.id}/`} target="_blank">{this.state.away.name}</Link> ผลงาน{this.state.HorA==='home'?'เฉพาะในบ้าน':this.state.HorA==='away'?'เฉพาะนอกบ้าน':''}ดูใช้ได้</div>:
                            this.state.resultCalSelectTeam==='draw'?this.state.sumGoals.gfaway>this.state.sumGoals.glAway?<div><LazyLoadImage className="logoLikeTded" alt='like' src='/img/icon/like.webp' /><br/>ผลแพ้ชนะใกล้เคียงกัน แต่จำนวนประตูได้เสีย ทาง <Link to={`/team/${this.state.away.id}/`} target="_blank">{this.state.away.name}</Link> ดีกว่า</div>:this.state.sumGoals.gfaway===this.state.sumGoals.glaway?<div><LazyLoadImage className="logoLikeTded" alt='like' src='/img/icon/like.webp' /><LazyLoadImage className="logoLikeTded" alt='dislike' src='/img/icon/dislike.webp' /><br/>ผลแพ้ชนะใกล้เคียงกันมาก</div>:<div><LazyLoadImage className="logoLikeTded" alt='dislike' src='/img/icon/dislike.webp' /><br/>ผลแพ้ชนะใกล้เคียงกัน แต่ ทีมอื่นๆที่เคยพบกัน มีประตูได้เสียรวมดีกว่า</div>:
                            <div><LazyLoadImage className="logoLikeTded" alt='dislike' src='/img/icon/dislike.webp' /><br/><Link to={`/team/${this.state.away.id}/`} target="_blank">{this.state.away.name}</Link> ผลงาน{this.state.HorA==='home'?'เฉพาะในบ้าน':this.state.HorA==='away'?'เฉพาะนอกบ้าน':''}ดูไม่ดีนัก</div>:
                            ''}
                        </div>
                    </div>:
                    '':''}
                </div>
                <div className="col-3 text-center cTdedAway">
                    <LazyLoadImage className="logoTeamPage H2hTopLogoTded" alt={this.state.keywords==='home'?'ทีมอื่นๆ':this.state.away.name} src={this.state.keywords==='home'?'https://sv.mvpkick.com/img/logo/nologo.png':this.state.away.logo} /><p className="switchShowInTdedPage">{this.state.keywords==='home'?'ทีมอื่นๆ':this.state.away.name}</p>
                    {this.state.hvaList?this.state.hvaList.length>0?
                    <div className="col-12 progressColTded">
                        <div><span className="switchHideInTdedPage">{this.state.keywords==='home'?'ทีมอื่นๆ':this.state.away.name}</span><span>ชนะ : {this.state.keywords==='away'?(((this.state.sum.w/this.state.hvaListSelectCount.length)*100)).toFixed(2):(((this.state.sumAway.w/this.state.hvaListSelectCount.length)*100)).toFixed(2)}%</span> <br className="switchShowInTdedPage" /> <span>เสมอ : {this.state.keywords==='away'?(((this.state.sum.d/this.state.hvaListSelectCount.length)*100)).toFixed(2):(((this.state.sumAway.d/this.state.hvaListSelectCount.length)*100)).toFixed(2)}%</span> <br className="switchShowInTdedPage" /> <span>แพ้ : {this.state.keywords==='away'?(((this.state.sum.l/this.state.hvaListSelectCount.length)*100)).toFixed(2):(((this.state.sumAway.l/this.state.hvaListSelectCount.length)*100)).toFixed(2)}%</span></div>
                        <div className="col-12">
                            <div className="boxProgress boxProgressTded" style={{width:'100%',textAlign: '-webkit-right'}}>   
                                <div className="contentTded progress-v1 progress-striped-green" style={{width:`${this.state.keywords==='away'?((this.state.sum.w/this.state.hvaListSelectCount.length)*100).toFixed(2):((this.state.sumAway.w/this.state.hvaListSelectCount.length)*100).toFixed(2)}%`}}><div className="progress-bar-v1" style={{float:'right'}}></div></div>
                                <div className="contentTded progress-v1 progress-striped-orange" style={{width:`${this.state.keywords==='away'?((this.state.sum.d/this.state.hvaListSelectCount.length)*100).toFixed(2):((this.state.sumAway.d/this.state.hvaListSelectCount.length)*100).toFixed(2)}%`}}><div className="progress-bar-v1" style={{float:'right'}}></div></div> 
                                <div className="contentTded progress-v1 progress-striped-red" style={{width:`${this.state.keywords==='away'?((this.state.sum.l/this.state.hvaListSelectCount.length)*100).toFixed(2):((this.state.sumAway.l/this.state.hvaListSelectCount.length)*100).toFixed(2)}%`}}><div className="progress-bar-v1" style={{float:'right'}}></div></div>
                            </div>
                        </div>
                    </div>:
                    '':''}
                </div>
                
            </div>
        )
    }
}

export default Hva;