import React from 'react'
import Livescore from '../../components/ls/Livescore'
import { Helmet, HelmetProvider } from 'react-helmet-async';

class Freeyesterdayls extends React.Component{

    constructor(props){
        super(props)
        this.state = {
           date : null
        }
     }

     componentDidMount(){
        this.setState({date : new Date(Date.now() + (3600000*2) - (3600000*24)).toISOString().slice(0,10)}) // *2 เพราะให้ตารางสลับแสดงช่วงตี 5
     }
    
    render() {

        return (
            this.state.date!==null?
        <HelmetProvider>
            <Helmet>
            <title>ผลบอลเมื่อคืน ติดเว็บไซต์ได้ฟรี โดย MVPKICK</title>
            <meta name="description" content='แจกตาราง ผลบอลเมื่อคืน ผลบอลย้อนหลัง นำไปติดเว็บไซต์ได้ฟรี ตามต้นฉบับ MVPKICK.COM เว็บผลบอลยอดนิยมของคนไทย' />
            <link rel="canonical" href='https://mvpkick.com/free/ผลบอลเมื่อคืน/' />
            <meta property="og:url" content="https://mvpkick.com/free/ผลบอลเมื่อคืน/" />
            <meta property="og:title" content="ผลบอลเมื่อคืน ติดเว็บไซต์ได้ฟรี โดย MVPKICK" />
            <meta property="og:description" content="แจกตาราง ผลบอลเมื่อคืน ผลบอลย้อนหลัง นำไปติดเว็บไซต์ได้ฟรี ตามต้นฉบับ MVPKICK.COM เว็บผลบอลยอดนิยมของคนไทย" />
            <meta property="og:image" content="https://mvpkick.com/img/asset/og-img-main.jpg" />
            </Helmet>
           <div>
               <Livescore selectDate={this.state.date} from="free" />
           </div>
         </HelmetProvider>:
         <div>No Data</div>
        )
     }
}

export default Freeyesterdayls;