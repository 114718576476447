import React from 'react'
import axios from 'axios'
import {Link} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Lastnextmatch extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            listTour : this.props.listTour,
            list : [],
            connMobile : this.props.connMobile,
            LorN : this.props.LorN,
            loading : true
        }
    }

    callApi = (listTour = this.state.listTour, LorN = this.props.LorN) =>{
        if(this.state.loading!==true){this.setState({loading : true})}
        let listData = []
        if(LorN === 'lastmatch'){
            listTour.map((item,index)=>{
                //axios.get(`http://localhost/tournament?lastmatch=yes&id=${item.id}`).then(res =>{
                axios.get(`https:///api.mvpkick.com/tournament?lastmatch=yes&id=${item.id}`).then(res =>{
                //axios.get(`https://sv.mvpkick.com/league/lastnextmatch.php?id=${item.id}&key=lastmatch`).then(res =>{
                    if(res.data.msg){
                        listData.push({name : item.name, events : []})
                        this.setState({list : listData, listTour : listTour, LorN : LorN, loading : false})
                    }else{
                        listData.push({name : item.name, events : res.data.events})
                        this.setState({list : listData, listTour : listTour, LorN : LorN, loading : false})

                    }
                })
            })
        }else if(LorN === 'nextmatch'){
            
            listTour.map((item,index)=>{
                axios.get(`https://api.mvpkick.com/tournament?nextmatch=yes&id=${item.id}`).then(res =>{
                //axios.get(`https://sv.mvpkick.com/league/lastnextmatch.php?id=${item.id}&key=nextmatch`).then(res =>{
                    if(res.data.msg){
                        listData.push({name : item.name, events : []})
                        this.setState({list : listData, listTour : listTour, LorN : LorN, loading : false})
                    }else{
                        listData.push({name : item.name, events : res.data.events})
                        this.setState({list : listData, listTour : listTour, LorN : LorN, loading : false})

                    }
                })
            })
        }
        
    }

    componentDidMount(){
        this.callApi();
    }
    componentDidUpdate(){
        if(this.state.connMobile!==this.props.connMobile){this.setState({connMobile : this.props.connMobile})}
        if(this.state.listTour!==this.props.listTour||this.state.LorN!==this.props.LorN){this.callApi(this.props.listTour, this.props.LorN)}
    }
    render(){
        if(this.state.list.length>0){
            var dataMatch = this.state.list.map((item,index) => {
                let {name,events} = item
                let listEvent = [];
                for(const eventsKey in events){ // loop events
                    let homeInfo = {
                        participant:{
                            id:'',
                            name:'',
                            gender:'',
                            countryFK:'',
                            country_name:'',
                            logo:''
                        },
                        result:{
                            ordinarytime:'',
                            halftime:'',
                            finalresult:''
                        }
                    }
                    let awayInfo = {
                        participant:{
                            id:'',
                            name:'',
                            gender:'',
                            countryFK:'',
                            country_name:'',
                            logo:''
                        },
                        result:{
                            ordinarytime:'',
                            halftime:'',
                            finalresult:''
                        }
                    }
                    let {event_participants,startdate,status_type,tournament_stage_name,tournament_stageFK} = events[eventsKey];
                    for(const event_participantsKey in event_participants){
                        let {number,result,participant} = event_participants[event_participantsKey]
                        if(number==='1'){
                            homeInfo.participant = participant;
                            for (const results in result) {
                                let {result_code,value} = result[results]
                                switch(result_code){
                                    case 'ordinarytime':homeInfo.result.ordinarytime=value;break;
                                    case 'halftime':homeInfo.result.halftime=value;break;
                                    case 'finalresult':homeInfo.result.finalresult=value;break;
                                    default:break;
                                }
                            }
                        }else if(number==='2'){
                            awayInfo.participant = participant;
                            for (const results in result) {
                                let {result_code,value} = result[results]
                                switch(result_code){
                                    case 'ordinarytime':awayInfo.result.ordinarytime=value;break;
                                    case 'halftime':awayInfo.result.halftime=value;break;
                                    case 'finalresult':awayInfo.result.finalresult=value;break;
                                    default:break;
                                }
                            }
                        }
                    }
                    listEvent.push({id : events[eventsKey].id, homeInfo,awayInfo,startdate : startdate, status_type : status_type, tournament_stage_name : tournament_stage_name, tournament_stageFK : tournament_stageFK})
                } // end loop events
                this.state.LorN==='lastmatch'?listEvent.sort((a,b)=>new Date(b.startdate) - new Date(a.startdate)):listEvent.sort((a,b)=>new Date(a.startdate) - new Date(b.startdate))
                return (
                    listEvent.length>0?
                    listEvent.map((item,index)=>{
                        return (
                            this.state.connMobile===false?
                            <div className='listMatch text-center' key={index}>
                                {index===0?<div className="tournament row">
                                    <div className="col-12 text-center">{item.tournament_stage_name}</div>
                                </div>:''}
                                <div className="col-2">{item.startdate.slice(0,10)}<br/>{item.status_type}</div>
                                <div className="col-3 text-right"><Link to={`/team/${item.homeInfo.participant.id}/`} target="_blank">{item.homeInfo.participant.name}</Link><LazyLoadImage className="logoTeamLs" alt={item.homeInfo.participant.name} src={item.homeInfo.participant.logo} /></div>
                                <div className="col-2 ftScore"><Link to={`/viewmatch/${item.id}/`} target="_blank"><span>{item.homeInfo.result.finalresult} - {item.awayInfo.result.finalresult}</span></Link></div>
                                <div className="col-3 text-left"><LazyLoadImage className="logoTeamLs" alt={item.awayInfo.participant.name} src={item.awayInfo.participant.logo} /><Link to={`/team/${item.awayInfo.participant.id}/`} target="_blank">{item.awayInfo.participant.name}</Link></div>
                                <div className="col-2 text-center"><Link to={`/h2h/${item.id}/`} target="_blank"><LazyLoadImage className="h2hIcon border-shine" alt='h2h' src='/img/icon/h2h_icon.webp' /></Link></div>
                            </div>:
                            <div className='listMatch text-center' key={index}>
                                {index===0?<div className="tournament row">
                                    <div className="col-12 text-center">{item.tournament_stage_name}</div>
                                </div>:''}
                                <div className="col-2">{item.startdate.slice(0,10)}<br/>{item.status_type}</div>
                                <div className="col-6 text-left"><LazyLoadImage className="logoTeamLs" alt={item.homeInfo.participant.name} src={item.homeInfo.participant.logo} /><Link to={`/team/${item.homeInfo.participant.id}/`} target="_blank">{item.homeInfo.participant.name}</Link><br/><LazyLoadImage className="logoTeamLs" alt={item.awayInfo.participant.name} src={item.awayInfo.participant.logo} /><Link to={`/team/${item.awayInfo.participant.id}/`} target="_blank">{item.awayInfo.participant.name}</Link></div>
                                <div className="col-1"><Link to={`/viewmatch/${item.id}/`} target="_blank">{item.homeInfo.result.finalresult}<br/>{item.awayInfo.result.finalresult}</Link></div>
                                <div className="col-1 ">{item.homeInfo.result.halftime}<br/>{item.awayInfo.result.halftime}</div>
                                <div className="col-2 "><Link to={`/h2h/${item.id}/`} target="_blank"><LazyLoadImage className="h2hIconVertical border-shine" alt='h2h' src='/img/icon/h2h_icon_vertical.webp' /></Link></div>
                            </div>
                        )
                    }):
                    <div className='listMatch text-center' key={index}>
                        <div className="tournament row">
                            <div className="col-12 text-center">{name}</div>
                        </div>
                        <div className="col-12">No List Event</div>
                    </div>
                )
            })
        }
        return(
            this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" alt="Loading" /></div>:
            <div>
                {dataMatch}
            </div>
        )
    }
}


export default Lastnextmatch;