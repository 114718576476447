import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {Link} from 'react-router-dom'

class Recommendmatch extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            list :this.props.list,
            //newList : [],
            selectDate : this.props.selectDate,
            pageSlide : 1,
            connMobile : this.props.connMobile
        }
    }

    shuffle = (array) => {
        var currentIndex = array.length, temporaryValue, randomIndex;
      
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;
      
          // And swap it with the current element.
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }
        return array;
    }
    
    selectPageSlidePlus = () =>{
       this.setState({pageSlide : this.state.pageSlide+1})
    }
    selectPageSlideMinus = () =>{
        this.setState({pageSlide : this.state.pageSlide-1})
    }
    componentDidMount(){
        let randElementArray = [...this.state.list];
        this.shuffle(randElementArray);
        this.setState({newList : randElementArray});
    }
    componentDidUpdate(){
        if(this.props.connMobile!==this.state.connMobile){this.setState({connMobile : this.props.connMobile})}
       
    }
    render(){
        var idFocusLeague = ['38','40','42','45','46','47','48','50','53','54','55','61','64','69','77','78','86','87','110','113','123','132','134','138','140','141','143','146','149','185','224','268','8984'];
        if(this.state.list.length>0){
            
            var dataRecommend = this.state.list.map((item,index)=>{
                if(item.tournament_templateFK!==idFocusLeague.find((ele) => ele === item.tournament_templateFK)){return}
                let {event_participants} = item;
                let homeInfo = {
                    participant:{
                        id:'',
                        name:'',
                        gender:'',
                        countryFK:'',
                        country_name:'',
                        logo:''
                    },
                    result:{
                        ordinarytime:'',
                        halftime:'',
                        finalresult:'',
                        runningscore:''
                    }
                }
                let awayInfo = {
                    participant:{
                        id:'',
                        name:'',
                        gender:'',
                        countryFK:'',
                        country_name:'',
                        logo:''
                    },
                    result:{
                        ordinarytime:'',
                        halftime:'',
                        finalresult:'',
                        runningscore:''
                    }
                }
                
                for(const event_participantsKey in event_participants){
                    let {number,participant,result} = event_participants[event_participantsKey];
                    if(number==='1'){
                        homeInfo.participant = participant;
                        for (const results in result) {
                            let {result_code,value} = result[results]
                            switch(result_code){
                                case 'runningscore':homeInfo.result.runningscore=value;break;
                                case 'ordinarytime':homeInfo.result.ordinarytime=value;break;
                                case 'halftime':homeInfo.result.halftime=value;break;
                                case 'finalresult':homeInfo.result.finalresult=value;break;
                                default:break;
                            }
                        }
                    }else if(number==='2'){
                        awayInfo.participant = participant;
                        for (const results in result) {
                            let {result_code,value} = result[results]
                            switch(result_code){
                                case 'runningscore':awayInfo.result.runningscore=value;break;
                                case 'ordinarytime':awayInfo.result.ordinarytime=value;break;
                                case 'halftime':awayInfo.result.halftime=value;break;
                                case 'finalresult':awayInfo.result.finalresult=value;break;
                                default:break;
                            }
                        }
                    }
                }
                return(
                        <div className="boxRecMatch col-12 text-center fading gradient-border" key={index}>
                            <div className="cardRecMatch">
                                <div className="cardNoFocus">
                                    <div className="col-12">{item.tournament_template_name}<br/>{item.status_descFK==='1'?item.startdate.slice(11,16):item.status_type}</div>
                                    <div className="col-6"><LazyLoadImage src={homeInfo.participant.logo} alt={homeInfo.participant.name} className="logoRecCard" /></div><div className="col-6"><LazyLoadImage src={awayInfo.participant.logo} alt={awayInfo.participant.name} className="logoRecCard" /></div>
                                    <div className="col-12 ftScore"><span>{item.status_descFK==='1'?' - ':`${homeInfo.result.ordinarytime} - ${awayInfo.result.ordinarytime}`}</span></div>
                                </div>
                                <div className="cardFocus">
                                    <div className="col-12 mt-5 mb-3">
                                        {item.status_descFK==='6'?
                                        <Link to={`/viewmatch/${item.id}/`} target="_blank"><span className="h2hMini" style={{backgroundColor:'#0b976b',padding:'5px'}}>ดูผลการแข่ง</span></Link>:
                                        <Link to={`/h2h/${item.id}/`} target="_blank"><span className="h2hMini" style={{backgroundColor:'#0b976b',padding:'5px'}}>H2H</span></Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                )
            })

            for(let i=0;i<dataRecommend.length;i++){
                if(dataRecommend[i]===undefined){
                    dataRecommend.splice(i, 1);i--;
                }
            }

        }

       
        return(
            <div className="RecMatchZone">
                <h3 className="mx-3 text-center heading-hl">Hot Match {this.state.selectDate}</h3>
                {dataRecommend?dataRecommend.length>0?
                <div className="text-center">
                    <div className="arrow-RecMatch px-3 text-center">
                        <div className="col-3 btn-laser" onClick={this.selectPageSlideMinus} style={this.state.pageSlide===1?{pointerEvents: 'none',opacity:'0.25'}:{cursor:'pointer'}}><span>{`ก่อนหน้า`}</span></div>
                        <div className="col-4">{this.state.pageSlide}</div>
                        <div className="col-3 btn-laser" onClick={this.selectPageSlidePlus} style={(this.state.pageSlide*6)<dataRecommend.length?{cursor:'pointer'}:{pointerEvents: 'none',opacity:'0.25'}}><span>{`ถัดไป`}</span></div>
                    </div>
                    {dataRecommend.slice(((this.state.pageSlide-1)*6),(this.state.pageSlide*6))}
                </div>:
                <div className="col-12 listMatch text-center">No Recommend Match</div>
                :
                <div className="col-12 listMatch text-center">No Recommend Match</div>
                }
            </div>
            
        )
    }
}

export default Recommendmatch;