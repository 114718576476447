import React from 'react'
import axios from 'axios'
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Rankplayer extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            list:[],
            loading : true,
        }
    }
    
    componentDidMount(){
        axios.get(`https://sv.mvpkick.com/league/topplayer_home.php`).then(res =>{
            //console.log(res.data)

            this.setState({list : res.data,loading : false})
        })
        .catch(e =>{
            this.setState({list : [],loading : false})
        })
    }

    render(){
        if(this.state.list.length>0){
            var dataSet = this.state.list.map((item,index) =>{
                let {team} = item;
                if(team.length<=0){
                    return(
                        <div key={index} className="cardRankTop">
                            <div>
                                <div className="col-12 leagueRankTop py-2 my-2">{item.tourName} ({item.seasonName})</div>
                                <div className="col-12 py-2">ยังไม่มีข้อมูล</div>
                            </div>
                        </div>
                    )
                }else
                {
                    team.sort((a,b)=> a.rank-b.rank)
                    let standingArrData = [];
                    team.map((team,i)=>{
                        //console.log(team)
                        let {standing_data} = team
                        let objSet = {
                            goals : 0,
                            penalties : 0,
                            st1scorer : 0
                        }
                        for(const data of standing_data){
                            switch (data.code) {
                                case 'goals':objSet.goals=data.value;break;
                                case 'penalties':objSet.penalties=data.value;break;
                                case '1stscorer':objSet.st1scorer=data.value;break;
                                default:break;
                            }
                        }
                        standingArrData.push(objSet)
                    })

                    return(
                        <div key={index} className="cardRankTop">
                            <div>
                                <div className="col-12 leagueRankTop py-2 my-2">{item.tourName} ({item.seasonName})</div>
                                <div className="col-12 py-2"><LazyLoadImage src={team[0].participant.imgplayer} alt={team[0].participant.name} className="logoRecCard playerImg boxRT" /><br/>{standingArrData[0].goals} Goals<br/>{team[0].participant.name}</div>
                                {team.length>1?<div className="col-6"><LazyLoadImage src={team[1].participant.imgplayer} alt={team[1].participant.name} className="playerImg boxRT" /><br/>{standingArrData[1].goals} Goals<br/>{team[1].participant.name}</div>:<div className="col-6"></div>}
                                {team.length>2?<div className="col-6"><LazyLoadImage src={team[2].participant.imgplayer} alt={team[2].participant.name} className="playerImg boxRT" /><br/>{standingArrData[2].goals} Goals<br/>{team[2].participant.name}</div>:<div className="col-6"></div>}
                            </div>
                            <div className="overlay-bottom-to-top">
                                <div>
                                    <div className="col-12"><LazyLoadImage src={team[0].participant.imgplayer} alt={team[0].participant.name} className="logoRecCard playerImg boxRT" /><br/>{team[0].participant.name}</div>
                                    <div className="col-5">Goals : {standingArrData[0].goals}</div>
                                    <div className="col-5">Penalties : {standingArrData[0].penalties}</div>
                                    <div className="col-5">1stscorer : {standingArrData[0].st1scorer}</div>
                                </div>
                            </div>
                        </div>
                    )
                }
            })
        }
        
        return(
            <div className="text-center">
                <h3 className="mx-3 text-center heading-hl">Top 3 Player</h3>
                {this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" /></div>:dataSet}
            </div>
        )
    }

}


export default Rankplayer;