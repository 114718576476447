import React from 'react'
import axios from 'axios'
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Odds extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            id : this.props.id,
            list : [],
            infoTeam : this.props.infoTeam,
            selectOdds : 'hdp',
            loading : true
        }
    }

    componentDidMount(){
        let listData = []
        let onextwo = []
        let hdp = []
        let ou = []
        axios.get(`https://sv.mvpkick.com/h2h/odds.php?id=${this.state.id}`).then(res=>{
            let {preodds} = res.data
            for(const preoddsKey in preodds){
                switch(preodds[preoddsKey].outcome_typeFK){
                    case'1':onextwo.push(preodds[preoddsKey]);break;
                    case'4':ou.push(preodds[preoddsKey]);break;
                    case'6':hdp.push(preodds[preoddsKey]);break;
                    default:break;
                }
            }
            listData.push({onextwo:onextwo,hdp:hdp,ou:ou})
            this.setState({list : listData,  loading : false})
        })
    }

    selectShow = (select) =>{
        this.state.selectOdds===select?console.log():this.setState({selectOdds : select})
    }

    render() {
        //console.log(this.state)
        if(this.state.list.length>0){
            var dataOdds = this.state.list.map((item,index)=>{
                let {hdp,ou,onextwo} = item;
                let listShow = [];
                if(this.state.selectOdds==='hdp'){ // hdp
                    hdp.map((hdpItem,i)=>{
                        let {preodds_bettingoffers} = hdpItem
                        for(const preodds_bettingoffersKey in preodds_bettingoffers){
                            let {odds} = preodds_bettingoffers[preodds_bettingoffersKey]
                            this.state.infoTeam.home.id===hdpItem.iparam?
                            listShow.push({hdpItem,odds:odds,HorA:'home',name:this.state.infoTeam.home.name}):listShow.push({hdpItem,odds:odds,HorA:'away',name:this.state.infoTeam.away.name})
                        }
                    })
                    listShow.sort((a,b)=>a.hdpItem.dparam - b.hdpItem.dparam)
                    listShow.sort((a,b)=>b.HorA.localeCompare(a.HorA)) // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare#Browser_compatibility
                    listShow.sort((a,b)=>a.hdpItem.outcome_scopeFK - b.hdpItem.outcome_scopeFK)
                    
                }else if(this.state.selectOdds==='ou'){ // ou
                    ou.map((ouItem,i)=>{
                        let {preodds_bettingoffers,outcome_subtypeFK} = ouItem
                        for(const preodds_bettingoffersKey in preodds_bettingoffers){
                            let {odds} = preodds_bettingoffers[preodds_bettingoffersKey]
                            outcome_subtypeFK==='3'?listShow.push({ouItem,odds:odds,ou:'over'}):listShow.push({ouItem,odds:odds,ou:'under'})
                        }
                    })
                    listShow.sort((a,b)=>a.ouItem.dparam - b.ouItem.dparam)
                    listShow.sort((a,b)=>a.ou.localeCompare(b.ou)) // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare#Browser_compatibility
                    listShow.sort((a,b)=>a.ouItem.outcome_scopeFK - b.ouItem.outcome_scopeFK)
                }else if(this.state.selectOdds==='onextwo'){ // ou
                    let list1x2 =[]
                    let data1x2 = {
                        full :{
                            oddsHome : null,
                            oddsDraw : null,
                            oddsAway : null
                        },
                        half :{
                            oddsHome : null,
                            oddsDraw : null,
                            oddsAway : null
                        }
                    }
                    onextwo.map((onextwoItem,i)=>{
                        let {preodds_bettingoffers,outcome_subtypeFK} = onextwoItem
                        for(const preodds_bettingoffersKey in preodds_bettingoffers){
                            let {odds} = preodds_bettingoffers[preodds_bettingoffersKey]
                            outcome_subtypeFK==='2'?list1x2.push({onextwoItem,odds:odds,onextwo:'draw'}):
                            outcome_subtypeFK==='1'&&onextwoItem.iparam===this.state.infoTeam.home.id?list1x2.push({onextwoItem,odds:odds,onextwo:'home'}):
                            list1x2.push({onextwoItem,odds:odds,onextwo:'away'})
                        }
                    })
                    for(let i=0;i<list1x2.length;i++){
                        if(list1x2[i].onextwoItem.outcome_scopeFK==='4'){
                            switch(list1x2[i].onextwo){
                                case'draw':data1x2.half.oddsDraw=list1x2[i].odds;break;
                                case'home':data1x2.half.oddsHome=list1x2[i].odds;break;
                                case'away':data1x2.half.oddsAway=list1x2[i].odds;break;
                            }
                        }else if(list1x2[i].onextwoItem.outcome_scopeFK==='1'){
                            switch(list1x2[i].onextwo){
                                case'draw':data1x2.full.oddsDraw=list1x2[i].odds;break;
                                case'home':data1x2.full.oddsHome=list1x2[i].odds;break;
                                case'away':data1x2.full.oddsAway=list1x2[i].odds;break;
                            }
                        }
                    }
                    listShow.push(data1x2);
                    //listShow.sort((a,b)=>a.onextwo.localeCompare(b.onextwo)) // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare#Browser_compatibility

                }
                return(
                    this.state.selectOdds==='hdp'?
                    listShow.map((item,index)=>{
                        return(
                        <div key={index} className="listMatch">
                            <div className="col-2">{item.hdpItem.outcome_scopeFK==='4'?<span>HALF : </span>:<span>FULL : </span>}</div>
                            <div className="col-6 text-left"><LazyLoadImage className="logoTeamLs" alt={item.hdpItem.name} src={item.hdpItem.logoTeam} />{item.name}</div>
                            <div className="col-2">{item.hdpItem.dparam}</div>
                            <div className="col-2">{item.odds}</div>
                        </div>
                        )
                    }):
                    this.state.selectOdds==='ou'?
                    listShow.map((item,index)=>{
                        return(
                        <div key={index} className="listMatch">
                            <div className="col-2">{item.ouItem.outcome_scopeFK==='4'?<span>HALF : </span>:<span>FULL : </span>}</div>
                            <div className="col-6 text-left">{item.ou}</div>
                            <div className="col-2">{item.ouItem.dparam}</div>
                            <div className="col-2">{item.odds}</div>
                        </div>
                        )
                    }):
                    this.state.selectOdds==='onextwo'?
                    listShow.map((item,index)=>{
                        return(
                            item.half&&item.full?
                            <div key={index} className="text-center">
                            <div className="listMatch"><div className="col-3"><span>FULL : </span></div><div className="col-3">{item.full.oddsHome}</div><div className="col-3">{item.full.oddsDraw}</div><div className="col-3">{item.full.oddsAway}</div></div>
                            <div className="listMatch"><div className="col-3"><span>HALF : </span></div><div className="col-3">{item.half.oddsHome}</div><div className="col-3">{item.half.oddsDraw}</div><div className="col-3">{item.half.oddsAway} </div></div>
                            </div>:
                            item.half?
                            <div className="listMatch"><div className="col-3"><span>HALF : </span></div><div className="col-3">{item.half.oddsHome}</div><div className="col-3">{item.half.oddsDraw}</div><div className="col-3">{item.half.oddsAway} </div></div>:
                            <div className="listMatch"><div className="col-3"><span>FULL : </span></div><div className="col-3">{item.full.oddsHome}</div><div className="col-3">{item.full.oddsDraw}</div><div className="col-3">{item.full.oddsAway}</div></div>
                        )
                    }):''
                    
                )
            })
        }
        return (
            this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" alt="Loading" /></div>:
            <div className="text-center">
                {this.state.selectOdds==='hdp'||this.state.selectOdds==='ou'?
                <div className="tournament row">
                    <div className="pb-3">
                        <div className={this.state.selectOdds==='hdp'?'active btn btn-h2h col-3':'btn btn-h2h col-3'} onClick={(e)=>this.selectShow('hdp')}>HDP</div>
                        <div className={this.state.selectOdds==='ou'?'active btn btn-h2h col-3':'btn btn-h2h col-3'} onClick={(e)=>this.selectShow('ou')}>O/U</div>
                        <div className={this.state.selectOdds==='onextwo'?'active btn btn-h2h col-3':'btn btn-h2h col-3'} onClick={(e)=>this.selectShow('onextwo')}>1X2</div>
                    </div>
                    <div className="col-2">FT-HT</div>
                    <div className="col-6 text-left">Team</div>
                    <div className="col-2">Rate</div>
                    <div className="col-2">Odds</div>
                </div>
                :
                <div className="tournament row">
                    <div className="pb-3">
                        <div className={this.state.selectOdds==='hdp'?'active btn btn-h2h col-3':'btn btn-h2h col-3'} onClick={(e)=>this.selectShow('hdp')}>HDP</div>
                        <div className={this.state.selectOdds==='ou'?'active btn btn-h2h col-3':'btn btn-h2h col-3'} onClick={(e)=>this.selectShow('ou')}>O/U</div>
                        <div className={this.state.selectOdds==='onextwo'?'active btn btn-h2h col-3':'btn btn-h2h col-3'} onClick={(e)=>this.selectShow('onextwo')}>1X2</div>
                    </div>
                    <div className="col-3">FT-HT</div>
                    <div className="col-3">Home</div>
                    <div className="col-3">Draw</div>
                    <div className="col-3">Away</div>
                </div>
                }
                {this.state.list.length>0?
                this.state.list[0].hdp.length>0&&this.state.list[0].ou.length>0&&this.state.list[0].onextwo.length>0?
                dataOdds:
                <div className="listMatch">No Odds</div>:
                ''}
            </div>
        )
    }
}
export default Odds
