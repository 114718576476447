import React from 'react'
import axios from 'axios'
import {Link} from "react-router-dom";

class Sidebarnews extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            list : [],
            id : this.props.id
        }
    }

    componentDidMount(){
        axios.get(`https://news.mvpkick.com/index.php?page=1`).then(res=>{
            this.setState({list : res.data})
        })
    }

    setDate = (timestamp) =>{
        let d = new Date(timestamp*1000);
        let months = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฏาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
        let hour = d.getHours();
        let minutes = d.getMinutes();
        if (hour < 10) { hour = "0" + hour; }   
        if (minutes < 10) { minutes = "0" + minutes; }      

        return `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`
    }

    render(){
        if(this.state.list.length>0){
            var listData = this.state.list.map((item,index) =>{

                if(this.state.id!==item.id){
                    return(
                        <Link to={`/news/${item.id}/`} key={item.id}>
                        <div className="cardNews newsInContent col-3">
                            <div>
                                <div style={{overflow:'hidden'}}><div className="ThumbnailNewsSidebar" style={{backgroundImage:`url(https://news.mvpkick.com/images/${item.thumbnail})`}}></div></div>
                                <div className="headingNewsPage text-left"><h4>{item.title}</h4></div>
                                <div className="pb-2 text-left">{item.stripdes.slice(0,150)}...</div>
                                <div className="dateTdedRow text-right mt-3"><span>{this.setDate(item.updatepost)}</span></div>
                            </div>
                        </div>
                        </Link>
                    )
                }
                
            })
        }
        if(listData){
            for(let i=0;i<listData.length;i++){
                if(listData[i]===undefined){
                    listData.splice(i,1)
                    i--;
                }
            }
        }
        return(
            <div className="sidebarNewsContent">
                {listData?listData.length>0?listData?.slice(0,6):'':''}
                <Link to="/news/"><div style={{textAlign:'-webkit-center'}}><div className="btn btn-seeAll text-center" style={{fontSize:'13pt'}}>อ่านข่าวอื่นๆ...</div></div></Link>
            </div>
        )
    }
}

export default Sidebarnews;