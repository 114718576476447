import React from 'react'
import firebase, {auth} from "../../modules/firebase"
import {Route,Redirect} from "react-router-dom";
import {connect} from "react-redux"; 

var provider = new firebase.auth.GoogleAuthProvider();
auth.languageCode = 'th';

class Login extends React.Component{
    constructor(props){
        super(props)
    }

    callLogin = (keyword) =>{
        switch(keyword){
            case 'gmail':
                auth.signInWithPopup(provider)
                .then((result) => {
                    //console.log('login success')
                    var credential = result.credential;
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    var token = credential.accessToken;
                    // The signed-in user info.
                    var user = result.user;
                    // Sent User to store
                    this.props.dispatch({
                        type:'login',
                        data:user
                    })
                }).catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    // The email of the user's account used.
                    var email = error.email;
                    // The firebase.auth.AuthCredential type that was used.
                    var credential = error.credential;
                    //console.log(errorCode)
                    //console.log(errorMessage)
                    //console.log(email)
                    //console.log(credential)
                });
                break;
            default: break;
        }
    }
    render() {
        console.log(this.props.user)
        return (
            <div>
                {this.props.user!==undefined?
                <Redirect to="/user" />:
                ''
                }
                <div className="infoUser">
                    <h3>Login Member</h3>
                    <div className="cmBtn cmLogin" onClick={(e) => this.callLogin('gmail')}><img src="img/icon/gmail-icon.png" alt="gmail" width="20px"/><br/>Login by Gmail</div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) =>{
    return{
        user : state.user
    }
}

export default connect(mapStateToProps)(Login)