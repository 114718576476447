import React from 'react'
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

class Chartslastmatch extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            list : this.props.list?this.props.list:null,
            id : this.props.id,
            listcal : this.props.listcal?this.props.listcal:null
        }
    }

    componentDidMount(){
        if(this.state.id!==null){
            const ctx = document.getElementById(`myChart${this.state.id}`);
            const labels = this.props.list;
            const data = {
                labels: labels,
                datasets: [{
                    label: 'Line Chart Trend Lastmatch',
                    data: this.props.listcal,
                    fill: false,
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.1
                }]
            };
            const myChart = new Chart(ctx, {
                type: 'line',
                data: data,
            })
        }
            
    }

    render(){
        return(
            this.state.list!==null?
            <canvas id={`myChart${this.state.id}`} max-width="400" max-height="400"></canvas>:
            ''
        )
    }
}

export default Chartslastmatch;