import React from 'react'

class Formation extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            fmt:this.props.fmt,
            HorA:this.props.HorA,
            lineupFmt:this.props.lineupFmt,
            shirt:this.props.shirt,
            logo:this.props.logo,
            inc:this.props.inc
        }
    }

    statusPlayer = (idPlayer) =>{
        return this.state.inc.map((item,index)=>{
            if(idPlayer===item.id){
                return <img src={item.incImg} alt={item.incident_code} className="iconInFmt" key={index}/>
            }
        })
    }

    render(){
        
        var fmtShow = (
            <div className={this.state.HorA==='home'?'fmt fmt-home':'fmt fmt-away'} style={{backgroundImage: `url(${this.state.logo})`}}>
                {this.state.HorA==='home'?<p className="text-center my-2">Formation Home : {this.state.fmt}</p>:console.log()}
                {this.state.lineupFmt.map((item,index)=>{
                    if(item.pos==='0')return;
                    if(this.state.fmt==='4-3-3'){return (<div className={`fmt-${item.pos==='73'||item.pos==='75'||item.pos==='77'?item.pos+'-4-3-3':item.pos}`} key={index}><img src={this.state.shirt?this.state.shirt:'/img/player/noimg.png'} className="fmtPlayer" alt="noshirt" />{this.statusPlayer(item.id)}({item.shirt_number})<br/>{item.thainame!==null?item.thainame:item.name} </div>)} // fix css 4-3-3
                    else if(this.state.fmt==='4-1-2-3'){return (<div className={`fmt-${item.pos==='84'||item.pos==='86'?item.pos+'-4-1-2-3':item.pos}`} key={index}><img src={this.state.shirt?this.state.shirt:'/img/player/noimg.png'} className="fmtPlayer" alt="noshirt" />{this.statusPlayer(item.id)}({item.shirt_number})<br/>{item.thainame!==null?item.thainame:item.name} </div>)} // fix css 4-1-2-3
                    else if(this.state.fmt==='4-1-2-1-2'){return (<div className={`fmt-${item.pos==='74'||item.pos==='76'?item.pos+'-4-1-2-1-2':item.pos}`} key={index}><img src={this.state.shirt?this.state.shirt:'/img/player/noimg.png'} className="fmtPlayer" alt="noshirt" />{this.statusPlayer(item.id)}({item.shirt_number})<br/>{item.thainame!==null?item.thainame:item.name} </div>)} // fix css 4-1-2-1-2
                    else if(this.state.fmt==='4-3-1-2'){return (<div className={`fmt-${item.pos==='63'||item.pos==='65'||item.pos==='67'||item.pos==='85'?item.pos+'-4-3-1-2':item.pos}`} key={index}><img src={this.state.shirt?this.state.shirt:'/img/player/noimg.png'} className="fmtPlayer" alt="noshirt" />{this.statusPlayer(item.id)}({item.shirt_number})<br/>{item.thainame!==null?item.thainame:item.name} </div>)} // fix css 4-3-1-2
                    else if(this.state.fmt==='5-3-2'){return (<div className={`fmt-${item.pos==='31'||item.pos==='33'||item.pos==='35'||item.pos==='37'||item.pos==='39'||item.pos==='73'||item.pos==='75'||item.pos==='77'?item.pos+'-5-3-2':item.pos}`} key={index}><img src={this.state.shirt?this.state.shirt:'/img/player/noimg.png'} className="fmtPlayer" alt="noshirt" />{this.statusPlayer(item.id)}({item.shirt_number})<br/>{item.thainame!==null?item.thainame:item.name} </div>)} // fix css 4-3-1-2
                    else if(this.state.fmt==='3-3-3-1'){return (<div className={`fmt-${item.pos==='63'||item.pos==='65'||item.pos==='67'?item.pos+'-3-3-3-1':item.pos}`} key={index}><img src={this.state.shirt?this.state.shirt:'/img/player/noimg.png'} className="fmtPlayer" alt="noshirt" />{this.statusPlayer(item.id)}({item.shirt_number})<br/>{item.thainame!==null?item.thainame:item.name} </div>)} // fix css 3-3-3-1
                    return (
                        <div className={`fmt-${item.pos}`} key={index}><img src={this.state.shirt?this.state.shirt:'/img/player/noimg.png'} className="fmtPlayer" alt="noshirt"/>{this.statusPlayer(item.id)}({item.shirt_number})<br/>{item.thainame!==null?item.thainame:item.name} </div>
                    )
                })}
                {this.state.HorA==='away'?<p className="text-center my-2">Formation Away : {this.state.fmt}</p>:console.log()}
            </div>
        )
        
        return(
            <div>{console.log()}
                {fmtShow}
            </div>
        )
    }
}

export default Formation;