import React from 'react'
import {connect} from "react-redux"; 
import {db} from "../../modules/firebase"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from "react-router-dom";

class Votetded extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            homeInfo : this.props.homeInfo,
            awayInfo : this.props.awayInfo,
            id : this.props.id,
            vote : {
                home : 0,
                draw : 0,
                away : 0,
                by : [],
                historyVoted : false
            }
        }
    }

    voteTeam = (id,value) =>{
        console.log(id,value)
        if(this.props.user!==undefined){
            console.log(typeof(this.state.vote.by))
            let arrBy = this.state.vote.by;
            arrBy.push(this.props.user.email)
            console.log(arrBy)
            let vote = {
                home: this.state.vote.home,
                draw: this.state.vote.draw,
                away: this.state.vote.away,
                by : arrBy
            }
            switch(value){
                case 'home':++vote.home;break;
                case 'away':++vote.away;break;
                case 'draw':++vote.draw;break;
                default:break;
            }
            db.collection("vote").doc(this.state.id).set({
                home: vote.home,
                draw: vote.draw,
                away: vote.away,
                id:this.state.id,
                by : vote.by
            })
            .then((docRef) => {
                console.log("Vote Success: ", docRef);
                db.collection("user").where("email", "==", this.props.user.email).get().then((querySnapshot) => {
                   if(querySnapshot.size===0){ // no user data in user collection
                        db.collection("user").doc(this.props.user.email).set({
                            email : this.props.user.email,
                            allvote : 1
                        })
                   }else{
                    querySnapshot.forEach((doc) => {
                        db.collection("user").doc(this.props.user.email).set({
                            email : this.props.user.email,
                            allvote : ++doc.data().allvote
                        })
                    })
                   }
                })
                this.readVote();
            })
            .catch((error) => {
                console.error("Vote Error: ", error);
            });
            
        }else{
            window.alert("โปรดเข้าสู่ระบบก่อนแสดงความเห็น");
            //window.location.replace("http://localhost:3000/login");
        }
    }

    readVote = () =>{
        db.collection("vote").where("id", "==", this.state.id).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                console.log(`${doc.id} => ${doc.data()}`);
                let voted = false
                if(this.props.user!==undefined){
                    let indexCheck = doc.data().by.findIndex((element) => element === this.props.user.email);
                    if(indexCheck>-1){voted = true}
                }
                this.setState({
                    vote : {
                        home : doc.data().home,
                        draw : doc.data().draw,
                        away : doc.data().away,
                        by : doc.data().by,
                        historyVoted : voted
                    }
                })
            });
        })
    }
    
    componentDidMount(){
        this.readVote()
    }

    render() {
        return (
            this.state.awayInfo!==undefined&&this.state.homeInfo!==undefined?
            <div className="listMatch text-center boxFunTong">
                <h3>ร่วมโหวตหาผู้ชนะการแข่ง</h3>
                <div className="row mb-3 voteTeamZone">
                    <div className="col-3">
                        <LazyLoadImage src={this.state.homeInfo.logo} alt={this.state.homeInfo.name} className="logoTeamPage mb-3" />
                        <div className="btn-vote" onClick={(e)=>this.voteTeam(this.state.id,'home')} style={this.state.vote.historyVoted?{pointerEvents:'none'}:{}}><span className="voteHome" style={this.state.vote.historyVoted?{background:'rgb(49 49 49)',opacity:'0.5'}:{}}>ชนะ</span></div>
                        <div className="mt-3">Vote : {this.state.vote.home}</div>
                    </div>
                    <div className="col-3">
                        <LazyLoadImage src='/img/icon/draw-icon.png' alt='draw' className="logoTeamPage mb-3" />
                        <div className="btn-vote" onClick={(e)=>this.voteTeam(this.state.id,'draw')} style={this.state.vote.historyVoted?{pointerEvents:'none'}:{}}><span className="voteDraw" style={this.state.vote.historyVoted?{background:'rgb(49 49 49)',opacity:'0.5'}:{}}>เสมอ</span></div>
                        <div className="mt-3">Vote : {this.state.vote.draw}</div>
                    </div>
                    <div className="col-3">
                        <LazyLoadImage src={this.state.awayInfo.logo} alt={this.state.awayInfo.name} className="logoTeamPage mb-3" />
                        <div className="btn-vote" onClick={(e)=>this.voteTeam(this.state.id,'away')} style={this.state.vote.historyVoted?{pointerEvents:'none'}:{}}><span className="voteAway" style={this.state.vote.historyVoted?{background:'rgb(49 49 49)',opacity:'0.5'}:{}}>ชนะ</span></div>
                        <div className="mt-3">Vote : {this.state.vote.away}</div>
                    </div>
                </div>
                {this.state.vote.historyVoted?<div className="btn-vote mt-3"><span className="votedActive">โหวตรายการนี้แล้ว</span></div>:''}
                {this.props.user===undefined?
                <Link to='/login'><div className="btn-vote my-3"><span className="voteLogin">เข้าสู่ระบบ เพื่อร่วมโหวต</span></div></Link>:
                <Link to='/user'><div className="btn-vote my-3"><span className="voteUser">My Profile : {this.props.user.displayName}</span></div></Link>}
            </div>
            :
            <div></div>
        )
    }
}

const mapStateToProps = (state) =>{
    return{
        user : state.user
    }
}
export default connect(mapStateToProps)(Votetded)