import React from 'react'
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

class Chartleague extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            list : this.props.list?this.props.list:null,
            id : this.props.id
        }
    }

    componentDidMount(){
        if(this.state.id!==null){
            const ctx = document.getElementById(`myChart${this.state.id}`);
    const myChart = new Chart(ctx, {
    type: 'bar',
    data: {
        labels: ['Wins', 'Draws', 'Defeits', 'Played', 'Goalsfor', 'Goalsagainst','Points'],
        datasets: [{
            label: '# Chart',
            data: [
                this.state.list.wins,
                this.state.list.draws,
                this.state.list.defeits,
                this.state.list.played,
                this.state.list.goalsfor,
                this.state.list.goalsagainst,
                this.state.list.points
            ],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 0, 252, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
                'rgba(255, 0, 252, 1)'
            ],
            borderWidth: 1
        }]
    },
    options: {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    }
});
            }
    }

    render(){
        return(
            this.state.list!==null?
            <canvas id={`myChart${this.state.id}`} max-width="400" max-height="400"></canvas>:
            ''
        )
    }
}

export default Chartleague;