import React from 'react'
import axios from 'axios'
import {Link} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Recommendmatch from '../ls/Recommendmatch'
import Ranktop from '../ls/Ranktop'
import Rankplayer from '../ls/Rankplayer'
import loadable from '@loadable/component'
import Cmlivescore from '../comment/Cmlivescore';
//import Mainnews from '../news/Mainnews'
//import Maintded from '../tded/Maintded'
const Mainnews = loadable(() => import('../news/Mainnews'));
const Maintded = loadable(() => import('../tded/Maintded'));

class Livescore extends React.Component{

    constructor(props){
        //console.log('construc')
        super(props)

        this.state = {
            connMobile : window.innerWidth>800?false:true,
            nextLoad : false,
            selectDate : this.props.selectDate,
            topLeague : true,
            //listIdTopLeague : ['38','46','48','61','69','78','86','87','108','109','113','132','134','138','141','149','151','252','268','8984'],
            checkInprogress : false,
            idNotShow : [],
            alert : false,
            idFocusFadeout : [],
            from : this.props.from?this.props.from:null,
            alertData : null,
            idShowOddsZone : [],
            list : [],
            loading : true,
            tdedkickList : [],
            fristload : false
        }
        
        
    }


    callApi = (value = this.state.topLeague) =>{
        this.state.loading?console.log():this.setState({loading : true, fristload:true})
        //axios.get(`https://sv.mvpkick.com/index.php?date=${this.state.selectDate}&topleague=${value?'yes':'no'}`).then(res => {
        
        axios.get(`https://sv.tdedkick.com/index.php?date=${this.state.selectDate}&topleague=yes`).then(response =>{
            var mainTdedkick = response.data; // run tdedkick เพื่อเก็บ odds หน้าแรก
            //console.log(mainTdedkick)
            //console.log('tdedkick case')

            /* s main case */
            //axios.get(`https://api.mvpkick.com/?date=${this.state.selectDate}&topleague=${value?'yes':'no'}`).then(res => {
            //axios.get(`https://service.mvpkick.com/?date=${this.state.selectDate}&topleague=${value?'yes':'no'}`).then(res => {
            axios.get(`https://api.mvpkick.com/?date=${this.state.selectDate}&topleague=${value?'yes':'no'}`).then(res => {
            //var {events} = res.data;
            //console.log(res.data)
            //console.log('main case')
            //console.log(mainTdedkick)
            let listforSet = [];
            for (const listEvent of res.data) {
                listforSet.push(listEvent)
                //console.log(listEvent)
                }
                listforSet.sort((a, b) => new Date(a.startdate) - new Date(b.startdate));
                listforSet.sort((a, b) => a.tournament_templateFK - b.tournament_templateFK);
                window.innerWidth>800?
                this.setState({list : listforSet, loading : false, connMobile : false, topLeague : value, tdedkickList : mainTdedkick, fristload:true}):
                this.setState({list : listforSet, loading : false, connMobile : true, topLeague : value, tdedkickList : mainTdedkick, fristload:true}) // First Load

            })
            
            /* e main case */

        })

        
    }

    
    componentDidMount(){
        
        console.log('didmount')
        window.addEventListener("resize", this.handleWindowsize);
        window.addEventListener("scroll", this.handleWindowscroll);
        this.callApi();
        setInterval(this.updateLivescore, 60000); // รันแมตซ์กำลังแข่งทุก 1 นาที
        setInterval(this.selectDate, 300000); // รันตารางใหม่ทุก 5 นาที
        
    }
    componentDidUpdate(){
        
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.handleWindowsize);
        window.removeEventListener("scroll", this.handleWindowscroll);
        clearInterval(this.updateLivescore)
        clearInterval(this.selectDate)
        
    }

    switchShowLeague = (id) =>{
        if(id!==this.state.idNotShow.find(ele => ele === id)){
            this.state.idFocusFadeout.push(id)
            this.setState({idFocusFadeout : this.state.idFocusFadeout});

            setTimeout(() => {
                this.state.idNotShow.push(id);
                this.setState({idNotShow : this.state.idNotShow});
              }, 500); // delay for show fadeout css
        }else{
            for(let i=0;i<this.state.idNotShow.length; i++){ 
                if (this.state.idNotShow[i] === id){
                    this.state.idNotShow.splice(i, 1);
                    i--;
                }
            }
            for(let i=0;i<this.state.idFocusFadeout.length; i++){ 
                if (this.state.idFocusFadeout[i] === id){
                    this.state.idFocusFadeout.splice(i, 1);
                    i--;
                }
            }
            this.setState({idNotShow : this.state.idNotShow, idFocusFadeout : this.state.idFocusFadeout});
        }
    }

    switchShowOdds = (id) =>{
        console.log(id)//idShowOddsZone
        if(id!==this.state.idShowOddsZone.find(ele => ele === id)){
            this.state.idShowOddsZone.push(id)
            this.setState({idShowOddsZone : this.state.idShowOddsZone});
        }else{
            for(let i=0;i<this.state.idShowOddsZone.length; i++){ 
                if (this.state.idShowOddsZone[i] === id){
                    this.state.idShowOddsZone.splice(i, 1);
                    i--;
                }
            }
            this.setState({idShowOddsZone : this.state.idShowOddsZone});
        }
    }

    handleWindowsize = () =>{
        window.innerWidth>800?this.state.connMobile===false?console.log('handle size PC'):this.setState({connMobile : false}):this.state.connMobile===true?console.log('handle size Mobile'):this.setState({connMobile : true});
    }

    handleWindowscroll = () =>{
        //console.log(this.state.connMobile+"conn Mobile")
        //console.log(this.state.nextLoad+"next load")
        //console.log(window.scrollY)
        this.state.connMobile===true?window.scrollY>300?this.state.nextLoad===true?console.log('handle scroll M >300'):this.setState({nextLoad : true}):this.state.nextLoad===false?console.log('handle scroll M <= 300'):this.setState({nextLoad : false}):console.log('handle scroll PC');
    }


    selectDate = (date = this.state.selectDate) =>{
        if(date !== this.state.selectDate){this.setState({loading : true})}
            //axios.get(`https://tester.mvpkick.com/?date=${date}&topleague=${this.state.topLeague?'yes':'no'}`).then(res => {
            /*axios.get(`https://api.mvpkick.com/?date=${date}&topleague=${this.state.topLeague?'yes':'no'}`).then(res => {
            var {events} = res.data;
            let listforSet = [];
            for (const listEvent in events) {
                    listforSet.push(events[listEvent])
                }
                listforSet.sort((a, b) => new Date(a.startdate) - new Date(b.startdate));
                listforSet.sort((a, b) => a.tournament_templateFK - b.tournament_templateFK);
                window.innerWidth>800?
                this.setState({list : listforSet, loading : false, connMobile : false, selectDate:date}):
                this.setState({list : listforSet, loading : false, connMobile : true, selectDate:date});
                //console.log('update ls from date')
            })*/
            axios.get(`https://api.mvpkick.com/?date=${date}&topleague=${this.state.topLeague?'yes':'no'}`).then(res => {
            let listforSet = [];
            for (const listEvent of res.data) {
                listforSet.push(listEvent)
                //console.log(listEvent)
                }
                listforSet.sort((a, b) => new Date(a.startdate) - new Date(b.startdate));
                listforSet.sort((a, b) => a.tournament_templateFK - b.tournament_templateFK);
                window.innerWidth>800?
                this.setState({list : listforSet, loading : false, connMobile : false, selectDate:date}):
                this.setState({list : listforSet, loading : false, connMobile : true, selectDate:date});

            })
    }

    alertScore = (inprogressData) =>{
        //console.log(`${inprogressData.homeInfo.participant.name} ${inprogressData.homeInfo.ordinarytime} - ${inprogressData.awayInfo.ordinarytime} ${inprogressData.awayInfo.participant.name}`)
        let audioObj = new Audio('https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/pause.wav')
        audioObj.addEventListener("canplaythrough", ()=>{
            audioObj.muted = true;
            if(this.state.alert===true){audioObj.muted = false;audioObj.play();}
        });
        audioObj.removeEventListener("canplaythrough", ()=>{});
    }

    updateLivescore = () => {
        //axios.get(`https://sv.mvpkick.com/ls.php?date=${this.state.selectDate}`).then(res => {
        axios.get(`https://api.mvpkick.com/?date=${this.state.selectDate}&inprogress=yes&topleague=${this.state.topLeague}`).then(res => {
            
            let listforSet = [];
            for (const listEvent of res.data) {
                listforSet.push(listEvent)
            }
            //console.log('updatescore')
            //console.log(listforSet)
            if(listforSet.length>0){
                //console.log('update in if')
                var newList = this.state.list;
                var alertData = null;
                for(let i=0;i<listforSet.length;i++){
                    let resultIndex = newList.findIndex( ({ id }) => id === listforSet[i].id ); // ถ้าไม่มี index จะ = -1
                    if(resultIndex>=0){
                        //console.log(newList[resultIndex])
                        //console.log(resultIndex)
                        /* set inprogress data */

                        let {event_participants} = listforSet[i];
                        let inprogressData = {
                            homeInfo : {participant : null, ordinarytime : null},
                            awayInfo : {participant : null, ordinarytime : null}
                        }

                        /* s แทนชื่อทีมภาษาไทย ใน inprogress */
                        for (const listStateKey in newList[resultIndex].event_participants) {
                            let {number,participant} = newList[resultIndex].event_participants[listStateKey];
                            if(number==='1'){
                               // console.log(participant)
                                inprogressData.homeInfo.participant = participant;
                               // console.log(inprogressData.homeInfo.participant)
                            }else if(number==='2'){
                                inprogressData.awayInfo.participant = participant;
                            }
                        }
                        /* e แทนชื่อทีมภาษาไทย ใน inprogress */

                        for (const listEventParticipants in event_participants) {
                            let {number,participant,result} = event_participants[listEventParticipants];
                            if(number==='1'){
                                //inprogressData.homeInfo.participant = participant;
                                event_participants[listEventParticipants].participant = inprogressData.homeInfo.participant;
                                for (const results in result) {
                                    let {result_code,value} = result[results]
                                    switch(result_code){
                                        case 'ordinarytime':inprogressData.homeInfo.ordinarytime=value;break;
                                        //case 'ordinarytime':inprogressData.homeInfo.ordinarytime='800';break;
                                        default:break;
                                    }
                                }
                            }else if(number==='2'){
                                event_participants[listEventParticipants].participant = inprogressData.awayInfo.participant;
                                //inprogressData.awayInfo.participant = participant;
                                for (const results in result) {
                                    let {result_code,value} = result[results]
                                    switch(result_code){
                                        case 'ordinarytime':inprogressData.awayInfo.ordinarytime=value;break;
                                        default:break;
                                    }
                                }
                            }
                        }
                        console.log(listforSet[i])
                        //console.log(inprogressData)

                        /* set old data */
                        let oldData = {
                            homeInfo : {participant : null, ordinarytime : null},
                            awayInfo : {participant : null, ordinarytime : null}
                        }
                        for (const listEventParticipants in newList[resultIndex].event_participants) {
                            let {number,participant,result} = newList[resultIndex].event_participants[listEventParticipants];
                            if(number==='1'){
                                oldData.homeInfo.participant = participant;
                                for (const results in result) {
                                    let {result_code,value} = result[results]
                                    switch(result_code){
                                        case 'ordinarytime':oldData.homeInfo.ordinarytime=value;break;
                                        default:break;
                                    }
                                }
                            }else if(number==='2'){
                                oldData.awayInfo.participant = participant;
                                for (const results in result) {
                                    let {result_code,value} = result[results]
                                    switch(result_code){
                                        case 'ordinarytime':oldData.awayInfo.ordinarytime=value;break;
                                        default:break;
                                    }
                                }
                            }
                        }
                        
                        //console.log("oldData")
                        //console.log(oldData)
                        if((inprogressData.homeInfo.ordinarytime!==oldData.homeInfo.ordinarytime)||(inprogressData.awayInfo.ordinarytime!==oldData.awayInfo.ordinarytime)){
                            alertData = `${inprogressData.homeInfo.participant.name} ${inprogressData.homeInfo.ordinarytime} - ${inprogressData.awayInfo.ordinarytime} ${inprogressData.awayInfo.participant.name}`;
                            this.alertScore(inprogressData);
                        }
                        newList = [
                            ...newList.slice(0, resultIndex),
                            listforSet[i], 
                            ...newList.slice(resultIndex + 1)
                        ]
                    }
                }

                this.setState({
                    list:newList, 
                    checkInprogress : true, 
                    alertData : alertData
                })
                //console.log('update')
            }
        });
    }

    setLeagueShow = (event) =>{
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        if(this.state.topLeague===value){

        }else{
            this.callApi(value);
        }
    }
    setAlert = (event) =>{
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        if(this.state.alert!==value){
            this.setState({alert:value})
        }
    }

    render(){
        
        //console.log('render')
        const today = new Date(Date.now() + (3600000*2)).toISOString().slice(0,10);
        const tomorrow = new Date(Date.now() + 86400000 + (3600000*2)).toISOString().slice(0,10);
        const yesterday = new Date(Date.now() - 86400000 + (3600000*2)).toISOString().slice(0,10);
        const next2day = new Date(Date.now() + (86400000*2) + (3600000*2)).toISOString().slice(0,10)
        const old2day = new Date(Date.now() - (86400000*2) + (3600000*2)).toISOString().slice(0,10)

        var livescoreList = this.state.list.map((item,index)=>{
            
            let {event_participants} = item;
            let homeInfo = {
                participant:{
                    id:'',
                    name:'',
                    gender:'',
                    countryFK:'',
                    country_name:'',
                    logo:''
                },
                result:{
                    ordinarytime:'',
                    halftime:'',
                    finalresult:'',
                    runningscore:''
                }
            }
            let awayInfo = {
                participant:{
                    id:'',
                    name:'',
                    gender:'',
                    countryFK:'',
                    country_name:'',
                    logo:''
                },
                result:{
                    ordinarytime:'',
                    halftime:'',
                    finalresult:'',
                    runningscore:''
                }
            }
            let elapsedRunning = {
                elapsed:'',
                injury_time:'',
                injury_time_elapsed:''
            }
            let oddsTK = {
                hdp: "",
                hdp_away: "",
                hdp_home: "",
                home_1x2: "",
                draw_1x2: "",
                away_1x2: "",
                ou: "",
                over_odds: "",
                under_odds: ""
            }
            for (const listEventParticipants in event_participants) {
                let {number,participant,result} = event_participants[listEventParticipants];
                if(number==='1'){
                    homeInfo.participant = participant;
                    for (const results in result) {
                        let {result_code,value} = result[results]
                        switch(result_code){
                            case 'runningscore':homeInfo.result.runningscore=value;break;
                            case 'ordinarytime':homeInfo.result.ordinarytime=value;break;
                            case 'halftime':homeInfo.result.halftime=value;break;
                            case 'finalresult':homeInfo.result.finalresult=value;break;
                            default:break;
                        }
                    }
                }else if(number==='2'){
                    awayInfo.participant = participant;
                    for (const results in result) {
                        let {result_code,value} = result[results]
                        switch(result_code){
                            case 'runningscore':awayInfo.result.runningscore=value;break;
                            case 'ordinarytime':awayInfo.result.ordinarytime=value;break;
                            case 'halftime':awayInfo.result.halftime=value;break;
                            case 'finalresult':awayInfo.result.finalresult=value;break;
                            default:break;
                        }
                    }
                }
            }
            for (const elapseds in item.elapsed) { // ไม่ deshuttering เพราะชื่อตัวแปรเหมือนกันจะเข้าใจผิด
                elapsedRunning = {
                    elapsed : item.elapsed[elapseds].elapsed,
                    injury_time : item.elapsed[elapseds].injury_time,
                    injury_time_elapsed : item.elapsed[elapseds].injury_time_elapsed
                };
            }

            let found = this.state.tdedkickList.find(({id}) => id === item.id)
            //console.log(`${item.id} = ${found}`)
            if(found!==undefined){
                oddsTK = {
                    hdp : found.hdp,
                    hdp_away : found.hdp_away,
                    hdp_home : found.hdp_home,
                    home_1x2 : found.home_1x2,
                    draw_1x2 : found.draw_1x2,
                    away_1x2 : found.away_1x2,
                    ou : found.ou,
                    over_odds : found.over_odds,
                    under_odds : found.under_odds
                }

            }else{
                oddsTK = {
                    hdp : "",
                    hdp_away : "",
                    hdp_home : "",
                    home_1x2 : "",
                    draw_1x2 : "",
                    away_1x2 : "",
                    ou : "",
                    over_odds : "",
                    under_odds : ""
                }
            }

            //console.log(this.state.idShowOddsZone + 'aaa')
            
            
            return(
                <div key={item.id} id={item.tournament_templateFK} className="fading">
                    
                        {index === 0 ? <div className="tournament row my-1" id={item.tournament_templateFK}>
                            <div className="col col-10"><LazyLoadImage className="flagLs" alt={item.tournament_template_name} src={item.flag} /><Link to={`/league/${item.tournament_templateFK}/`} target="_blank">{item.tournament_template_name} : {item.tournament_stage_name}</Link></div>
                            <div className="switchShow col-2" onClick={(e)=>this.switchShowLeague(item.tournament_templateFK)}>{item.tournament_templateFK === this.state.idNotShow.find(ele => ele === item.tournament_templateFK)?'+':'-'}</div>
                            </div> : 
                        item.tournament_templateFK===this.state.list[index-1].tournament_templateFK ? '' : <div className="tournament row my-1" id={item.tournament_templateFK}><div className="col col-10"><LazyLoadImage className="flagLs" alt={item.tournament_template_name} src={item.flag} /><Link to={`/league/${item.tournament_templateFK}/`} target="_blank">{item.tournament_template_name} : {item.tournament_stage_name}</Link></div><div className="switchShow col-2" onClick={(e)=>this.switchShowLeague(item.tournament_templateFK)}>{item.tournament_templateFK === this.state.idNotShow.find(ele => ele === item.tournament_templateFK)?'+':'-'}</div></div>}
                    
                    {item.tournament_templateFK===this.state.idNotShow.find(ele => ele === item.tournament_templateFK)?'': // condition hide show team
                    this.state.connMobile===false?
                    /* PC */
                    <div className={`listMatch ${item.tournament_templateFK===this.state.idFocusFadeout.find(ele => ele === item.tournament_templateFK)?'fadout':'fading'}`}>
                        <div className="col-2 text-center">{item.status_descFK==='1'?<span className="timeLs">{item.startdate.substr(11,5)}<br /><span className="oddsLs" onClick={(e)=>this.switchShowOdds(item.id)}>{oddsTK.hdp}</span></span>:(item.status_descFK==='2'||item.status_descFK==='3')&&elapsedRunning.elapsed!==''&&elapsedRunning.elapsed!=='0'?elapsedRunning.injury_time==='yes'?<div><span className="runningTime">{elapsedRunning.elapsed}+{elapsedRunning.injury_time_elapsed}'</span><LazyLoadImage className="running" alt='running' src='/img/icon/running.gif' /></div>:<div><span className="runningTime">{elapsedRunning.elapsed}'</span><LazyLoadImage className="running" alt='running' src='/img/icon/running.gif' /></div>:`${item.status_type}`}</div>
                        <div className="col-3 text-right"><Link to={`/team/${homeInfo.participant.id}/`} target="_blank">{homeInfo.participant.name}</Link><LazyLoadImage className="logoTeamLs" alt={homeInfo.participant.name} src={homeInfo.participant.logo} /></div>
                        <div className="col-2 text-center ftScore"><Link to={`/viewmatch/${item.id}/`} target="_blank"><span>{item.status_descFK==='6'||item.status_descFK==='1'||item.status_descFK==='5'?homeInfo.result.finalresult+' - '+awayInfo.result.finalresult:homeInfo.result.ordinarytime+' - '+awayInfo.result.ordinarytime}</span></Link></div>
                        <div className="col-3"><LazyLoadImage className="logoTeamLs" alt={awayInfo.participant.name} src={awayInfo.participant.logo} /><Link to={`/team/${awayInfo.participant.id}/`} target="_blank">{awayInfo.participant.name}</Link></div>
                        {/*<div className="col-2 text-center"><Link to={`/h2h/${item.id}/`} target="_blank"><LazyLoadImage className="h2hIcon border-shine" alt='h2h' src='/img/icon/h2h_icon.webp' /></Link></div>*/}
                        <div className="col-2 text-center"><Link to={`/h2h/${item.id}/`} target="_blank"><LazyLoadImage className="h2hIcon" alt='h2h' src='/img/icon/h2h_icon.webp' /></Link></div>
                        {item.id===this.state.idShowOddsZone.find(ele => ele === item.id)?
                        <div className="col-12 oddsZoneLs">
                            <div className="col-12 oddsZoneLsBox oddsLsBelow">
                            <div className="col-3">HDP</div><div className="col-3">{oddsTK.hdp_home}</div><div className="col-3">{oddsTK.hdp}</div><div className="col-3">{oddsTK.hdp_away}</div>
                            <div className="col-3">1X2</div><div className="col-3">{oddsTK.home_1x2}</div><div className="col-3">{oddsTK.draw_1x2}</div><div className="col-3">{oddsTK.away_1x2}</div>
                            <div className="col-3">O/U</div><div className="col-3">{oddsTK.over_odds}</div><div className="col-3">{oddsTK.ou}</div><div className="col-3">{oddsTK.under_odds}</div>
                            <div className="col-12 selectPlay"><a href="https://bit.ly/spin77service" rel="noopener nofollow" target="_blank"><button class="btn btn-gradient btn-glow">เดิมพันรายการนี้ คลิก !</button></a></div>
                            </div>
                        </div>:
                        ''}
                    </div>:
                    /* Mobile */
                    <div className={`listMatch ${item.tournament_templateFK===this.state.idFocusFadeout.find(ele => ele === item.tournament_templateFK)?'fadout':'fading'}`}>
                        <div className="col-2 text-center">{item.status_descFK==='1'?<span className="timeLs">{item.startdate.substr(11,5)}<br /><span className="oddsLs" onClick={(e)=>this.switchShowOdds(item.id)}>{oddsTK.hdp}</span></span>:(item.status_descFK==='2'||item.status_descFK==='3')&&elapsedRunning.elapsed!==''&&elapsedRunning.elapsed!=='0'?elapsedRunning.injury_time==='yes'?<div><span className="runningTime">{elapsedRunning.elapsed}+{elapsedRunning.injury_time_elapsed}'</span><LazyLoadImage className="running" alt='running' src='/img/icon/running.gif' /></div>:<div><span className="runningTime">{elapsedRunning.elapsed}'</span><LazyLoadImage className="running" alt='running' src='/img/icon/running.gif' /></div>:item.status_type}</div>
                        <div className="col-6"><LazyLoadImage className="logoTeamLs" alt={homeInfo.participant.name} src={homeInfo.participant.logo} /><Link to={`/team/${homeInfo.participant.id}/`} target="_blank">{homeInfo.participant.name}</Link><br /><LazyLoadImage className="logoTeamLs" alt={awayInfo.participant.name} src={awayInfo.participant.logo} /><Link to={`/team/${awayInfo.participant.id}/`} target="_blank">{awayInfo.participant.name}</Link></div>
                        <div className="col-1 text-center"><Link to={`/viewmatch/${item.id}/`} target="_blank">{item.status_descFK==='6'||item.status_descFK==='1'||item.status_descFK==='5'?homeInfo.result.finalresult:homeInfo.result.ordinarytime}<br />{item.status_descFK==='6'||item.status_descFK==='1'||item.status_descFK==='5'?awayInfo.result.finalresult:awayInfo.result.ordinarytime}</Link></div>
                        <div className="col-1 text-center">{homeInfo.result.halftime}<br />{awayInfo.result.halftime}</div>
                        {/*<div className="col-2 text-center"><Link to={`/h2h/${item.id}/`} target="_blank"><LazyLoadImage className="h2hIconVertical border-shine" alt='h2h' src='/img/icon/h2h_icon_vertical.webp' /></Link></div>*/}
                        <div className="col-2 text-center"><Link to={`/h2h/${item.id}/`} target="_blank"><LazyLoadImage className="h2hIconVertical" alt='h2h' src='/img/icon/h2h_icon_vertical.webp' /></Link></div>
                        {item.id===this.state.idShowOddsZone.find(ele => ele === item.id)?
                        <div className="col-12 oddsZoneLs">
                            <div className="col-12 oddsZoneLsBox oddsLsBelow">
                            <div className="col-3">HDP</div><div className="col-3">{oddsTK.hdp_home}</div><div className="col-3">{oddsTK.hdp}</div><div className="col-3">{oddsTK.hdp_away}</div>
                            <div className="col-3">1X2</div><div className="col-3">{oddsTK.home_1x2}</div><div className="col-3">{oddsTK.draw_1x2}</div><div className="col-3">{oddsTK.away_1x2}</div>
                            <div className="col-3">O/U</div><div className="col-3">{oddsTK.over_odds}</div><div className="col-3">{oddsTK.ou}</div><div className="col-3">{oddsTK.under_odds}</div>
                            <div className="col-12 selectPlay"><a href="https://bit.ly/spin77service" rel="noopener nofollow" target="_blank"><button class="btn btn-gradient btn-glow">เดิมพันรายการนี้ คลิก !</button></a></div>
                            </div>
                        </div>:
                        ''}
                    </div>
                    }
                    
                </div>
            )
            
        })

        for(let i=0;i<livescoreList.length;i++){
            if(livescoreList[i]===undefined){
                livescoreList.splice(i, 1);i--;
            }
        }

        return(
            this.state.from===null?
            <div className="containerLs">
            {/*<div className="col-2 goToTop subLs">{(this.state.connMobile===true&&this.state.nextLoad)||(this.state.connMobile===false)?<Ranktop />:''}</div>*/}
            <div className="col-2 goToTop subLs">{(this.state.connMobile===true&&this.state.nextLoad)||(this.state.connMobile===false)?'':''}</div>
                {/*<div className="col-2 goToTop subLs"></div>*/}
                <div className="col-8 mainLs">
                    {this.state.from!=='free'?<div className="boxbannerZone"><a href="https://bit.ly/spin77service" rel="noopener nofollow" target="_blank"><LazyLoadImage src={`/img/asset/900x300-spin77.gif`} alt='spin77' className="bannerHomepage" /></a></div>:''}
                    {/*this.state.from!=='free'?<div className="boxbannerZone"><a href="https://bit.ly/kr777register" rel="noopener nofollow" target="_blank"><LazyLoadImage src={`/img/asset/900x300-kr777.gif`} alt='kr777' className="bannerHomepage" /></a></div>:''*/}
                    <form className="py-2 goToTop">
                    <label><input type="checkbox" id="alert" name="alert" onChange={this.setAlert} checked={this.state.alert} /> : Alert</label>
                        <label style={{float:'right'}}><input type="checkbox" id="topleague" name="topleague" onChange={this.setLeagueShow} checked={this.state.topLeague} /> : Top League</label>
                    </form>
                    <div className="helloFanball">แสดงรายการ 09:01(วันนี้) - 09:00(พรุ่งนี้)</div>
                    <div className="nav-selectDate">
                        <div className={this.state.selectDate===old2day?'col selectDate active':'col selectDate'} onClick={(e) => this.selectDate(old2day)}><span>{old2day.slice(5,10)}</span></div>
                        <div className={this.state.selectDate===yesterday?'col selectDate active':'col selectDate'} onClick={(e) => this.selectDate(yesterday)}><span>เมื่อวาน</span></div>
                        <div className={this.state.selectDate===today?'col selectDate active':'col selectDate'} onClick={(e) => this.selectDate(today)}><span>วันนี้</span></div>
                        <div className={this.state.selectDate===tomorrow?'col selectDate active':'col selectDate'} onClick={(e) => this.selectDate(tomorrow)}><span>พรุ่งนี้</span></div>
                        <div className={this.state.selectDate===next2day?'col selectDate active':'col selectDate'} onClick={(e) => this.selectDate(next2day)}><span>{next2day.slice(5,10)}</span></div>
                    </div>
                    {this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" alt="Loading" /></div>:
                    this.state.connMobile===true?livescoreList.length>20?this.state.nextLoad?livescoreList:livescoreList.slice(0,20):livescoreList:livescoreList}
                    {/* s comment point */}
                     {<Cmlivescore />}
                    {/* e comment point */}
                    {/*console.log(livescoreList.length)*/}
                    {/*console.log(this.state.connMobile)*/}
                    
                    {/* s อันเก่า ข่าว + วิเคราะห์ = โชว์ */}
                    {/*livescoreList.length>20?(this.state.connMobile===true&&this.state.nextLoad)||(this.state.connMobile===false)?<div><Cmlivescore /><h2 className="wicroeballTitle text-center py-3" style={{width:'90%',margin:'auto',marginTop:'10px',marginBottom:'10px'}}>วิเคราะห์บอลวันนี้</h2><Maintded from="livescore" /><Link to="/tded/" target="_blank"><div style={{textAlign:'-webkit-center'}}><div className="btn btn-seeAll text-center" style={{fontSize:'13pt'}}>อ่านบทวิเคราะห์ทั้งหมด...</div></div></Link></div>:'':<div><Cmlivescore /><h2 className="wicroeballTitle text-center py-3" style={{width:'90%',margin:'auto',marginTop:'10px',marginBottom:'10px'}}>วิเคราะห์บอลวันนี้</h2><Maintded from="livescore" /><Link to="/tded/" target="_blank"><div style={{textAlign:'-webkit-center'}}><div className="btn btn-seeAll text-center" style={{fontSize:'13pt'}}>อ่านบทวิเคราะห์ทั้งหมด...</div></div></Link></div>*/}
                    {/*(this.state.connMobile===true&&this.state.nextLoad)||(this.state.connMobile===false)?<div><h2 className="wicroeballTitle text-center py-3" style={{width:'90%',margin:'auto',marginTop:'10px',marginBottom:'10px'}}>ข่าวฟุตบอลวันนี้</h2><Mainnews from="livescore" /><Link to="/news/" target="_blank"><div style={{textAlign:'-webkit-center'}}><div className="btn btn-seeAll text-center" style={{fontSize:'13pt'}}>อ่านข่าวทั้งหมด...</div></div></Link></div>:''*/}
                    {/* e อันเก่า ข่าว + วิเคราะห์ = โชว์ */}

                    {this.state.connMobile===true?'':<div><h2 className="wicroeballTitle text-center py-3" style={{width:'90%',margin:'auto',marginTop:'10px',marginBottom:'10px'}}>วิเคราะห์บอลวันนี้</h2><Maintded from="livescore" /><Link to="/tded/" target="_blank"><div style={{textAlign:'-webkit-center'}}><div className="btn btn-seeAll text-center" style={{fontSize:'13pt'}}>อ่านบทวิเคราะห์ทั้งหมด...</div></div></Link></div>}
                    {this.state.connMobile===true?'':<div><h2 className="wicroeballTitle text-center py-3" style={{width:'90%',margin:'auto',marginTop:'10px',marginBottom:'10px'}}>ข่าวฟุตบอลวันนี้</h2><Mainnews from="livescore" /><Link to="/news/" target="_blank"><div style={{textAlign:'-webkit-center'}}><div className="btn btn-seeAll text-center" style={{fontSize:'13pt'}}>อ่านข่าวทั้งหมด...</div></div></Link></div>}
                </div>
                {/*(this.state.connMobile===true&&this.state.nextLoad)||(this.state.connMobile===false)?this.state.list.length>0?<Recommendmatch  selectDate={this.state.selectDate} list={this.state.list} connMobile={this.state.connMobile} />:'':''*/}
                {console.log(`${this.state.connMobile} - ${this.state.nextLoad}`)}
                {/*<div className="col-2 goToTop subLs">{(this.state.connMobile===true&&this.state.nextLoad)||(this.state.connMobile===false)?this.state.list.length>0?<Rankplayer />:<Rankplayer />:''}</div>*/}
                <div className="col-2 goToTop subLs text-center">
                    {/*<a href="https://bit.ly/bn55aff1" rel="noopener nofollow" target="_blank"><LazyLoadImage src={`/img/asset/banner-tb-bonus55.webp`} alt='bonus55' className="bannertb" /></a>*/}
                </div>
                {/* popup fix */}
                {this.state.alertData!==null?<div className="popupAlert fading">
                        <div className="showStatusData">{this.state.alertData}</div>
                </div>:''}
            </div>:
            <div className="containerLs">
            <div className="col-12 mainLs">
                <form className="py-2 goToTop">
                <label><input type="checkbox" id="alert" name="alert" onChange={this.setAlert} checked={this.state.alert} /> : Alert</label>
                    <label style={{float:'right'}}><input type="checkbox" id="topleague" name="topleague" onChange={this.setLeagueShow} checked={this.state.topLeague} /> : Top League</label>
                </form>
                <div className="helloFanball">แสดงรายการ 09:01(วันนี้) - 09:00(พรุ่งนี้)</div>
                <div className="nav-selectDate">
                    <div className={this.state.selectDate===old2day?'col selectDate active':'col selectDate'} onClick={(e) => this.selectDate(old2day)}><span>{old2day.slice(5,10)}</span></div>
                    <div className={this.state.selectDate===yesterday?'col selectDate active':'col selectDate'} onClick={(e) => this.selectDate(yesterday)}><span>เมื่อวาน</span></div>
                    <div className={this.state.selectDate===today?'col selectDate active':'col selectDate'} onClick={(e) => this.selectDate(today)}><span>วันนี้</span></div>
                    <div className={this.state.selectDate===tomorrow?'col selectDate active':'col selectDate'} onClick={(e) => this.selectDate(tomorrow)}><span>พรุ่งนี้</span></div>
                    <div className={this.state.selectDate===next2day?'col selectDate active':'col selectDate'} onClick={(e) => this.selectDate(next2day)}><span>{next2day.slice(5,10)}</span></div>
                </div>
                {this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" alt="Loading" /></div>:
                this.state.from==='free'?livescoreList:
                this.state.connMobile===true?livescoreList.length>20?this.state.nextLoad?livescoreList:livescoreList.slice(0,20):livescoreList:livescoreList
                }
                {/*this.state.connMobile===true?livescoreList.length>20?this.state.nextLoad?livescoreList:livescoreList.slice(0,20):livescoreList:livescoreList*/}
            </div>
            </div>
        )
    }
}

export default Livescore;
