import React from 'react'
import {connect} from "react-redux"; 
import {db} from "../../modules/firebase"
import { Link } from "react-router-dom";

class Cmlivescore extends React.Component{
    constructor(props){
        super(props)
        this.state = {comment : []}
    }

    postComment = (e) =>{
        let commentDes = e.target.cm.value
        e.preventDefault();
        console.log(commentDes)
        if(commentDes!==""){
            let badWords = ['www', 'com', 'ufa', 'casino','@','ไลน์','line','net','http','<','>','href']
            //<a href="https://mvpkick.com">MVPKICK.COM</a>
            //let badWords = []
            let haveBadWords = false
            for(let i=0;i<badWords.length;i++){
                if(commentDes.includes(badWords[i])){haveBadWords = true}
            }
            if (haveBadWords) {
                window.alert("ขออภัย ! มีคำต้องห้ามในเนื้อหา")
            }else{
                //console.log('check cm')
                //console.log(this.props.user)
                //console.log(Date.now())
                if(this.props.user!==undefined){
                    db.collection("cm").add({
                        time: Date.now(),
                        comment: commentDes,
                        displayName: this.props.user.displayName,
                        email: this.props.user.email
                    })
                    .then((docRef) => {
                        console.log("Document written with ID: ", docRef.id);
                        this.readComment()
                        e.target.cm.value = "";
                    })
                    .catch((error) => {
                        console.error("Error adding document: ", error);
                    });
                    
                }else{
                    window.alert("โปรดเข้าสู่ระบบก่อนแสดงความเห็น");
                    //window.location.replace("http://localhost:3000/login");
                }
            }
        }else{
            window.alert("โปรดใส่เนื้อหาก่อนกด Comment !");
        }
    }

    readComment = () =>{
        let arrCm = []
        db.collection("cm").orderBy("time", "desc").limit(10).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                //console.log(`${doc.id} => ${doc.data()}`);
                arrCm.push(doc.data())
            });
            this.setState({comment : arrCm})
        })
    }

    componentDidMount(){
        this.readComment()
    }
    
    componentWillUnmount(){
        this.setState({comment : []})
    }

    render(){

        return(
            <div>
                {
                   this.state.comment.map(item=>{
                       return (
                            <div key={item.time} className="cmBlock">
                                <div className="dateCmRow">{new Date(item.time).toLocaleDateString("en-US")} By {item.displayName}</div>
                                <div className="desCmRow">{item.comment}</div>
                            </div>
                       )
                   }) 
                }
                
                <form className="cmForm" onSubmit = {this.postComment}>
                <textarea className="livescoreCm" name="cm" rows="5" placeholder="เชียร์ทีมไหน ร่วมแสดงความเห็นกันที่นี่...">
                </textarea>
                <input type="submit" className="cmBtn" value="แสดงความเห็น"/>
                </form>
                {this.props.user===undefined?
                <Link to='/login'><div className="cmBtn cmLogin">เข้าสู่ระบบ</div></Link>:
                <Link to='/user'><div className="cmBtn cmLogin">My Profile : {this.props.user.displayName}</div></Link>}
            </div>
        )
    }
}

const mapStateToProps = (state) =>{
    return{
        user : state.user
    }
}

export default connect(mapStateToProps)(Cmlivescore)