import React from 'react'
import axios from 'axios'
import {Link} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

class Mainnews extends React.Component{
    _isMounted = false;

    constructor(props){
        super(props)
        this.state = {
            list : [],
            page : 1,
            id : null,
            from : this.props.from?this.props.from:null
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
      }

    componentDidMount(){
        this._isMounted = true;
        axios.get(`https://news.mvpkick.com/index.php?page=${this.state.page}`).then(res =>{
            if (this._isMounted) {
                this.setState({list : res.data, id : null})
            }
        })
    }

    selectPage = (page) =>{
        window.scrollTo(0, 0)
        axios.get(`https://news.mvpkick.com/index.php?page=${page}`).then(res =>{
            this.setState({list : res.data, page : page})
        })
    }

    setDate = (timestamp) =>{
        let d = new Date(timestamp*1000);
        let months = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฏาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
        let hour = d.getHours();
        let minutes = d.getMinutes();
        if (hour < 10) { hour = "0" + hour; }   
        if (minutes < 10) { minutes = "0" + minutes; }      

        return `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`
    }

    render(){

        if(this.state.list.length>0){
            var dataNews = this.state.list.slice(0,8).map((item,index) =>{
                
                return(
                    <Link to={`/news/${item.id}/`} target="_blank" key={index}>
                    <div className="listTded col-5" >
                        <div style={{overflow:'hidden'}}><div className="ThumbnailNews" style={{backgroundImage:`url(https://news.mvpkick.com/images/${item.thumbnail})`}}></div></div>
                        <div className="headingNewsPage text-left"><h3>{item.title}</h3></div>
                        <div className="text-left pa-2">{item.stripdes.slice(0,150)}...</div>
                        <div className="col-12 dateTdedRow text-right"><span>{this.setDate(item.updatepost)}</span></div>
                    </div>
                    </Link>
                )
                
            })
        }
        
        return(
            this.state.list.length>0?
            this.state.from==='livescore'?
            <div className="boxNewsLs">
                {dataNews.slice(0,4)}
            </div>:
            <HelmetProvider>
                <Helmet>
                <title>ข่าวฟุตบอล ข่าวบอลวันนี้ พรีเมียร์ลีก ลาลีกา บอลไทย ข่าวนักเตะ</title>
                <meta name="description" content='อ่านข่าวฟุตบอลรายวัน ข่าวบอลวันนี้ ข่าวนักเตะย้ายสโมสร ข้อมูลซื้อขายนักเตะล่าสุด ติดตามข้อมูลวงการฟุตบอลรู้ก่อนใคร จากการอ่านข่าวบอลสดใหม่จากเว็บไซต์ MVPKICK.COM' />
                <link rel="canonical" href={`https://mvpkick.com/news/`} />
                <meta property="og:url" content={`https://mvpkick.com/news/`} />
                <meta property="og:title" content="ข่าวฟุตบอล ข่าวบอลวันนี้ พรีเมียร์ลีก ลาลีกา บอลไทย ข่าวนักเตะ" />
                <meta property="og:description" content="อ่านข่าวฟุตบอลรายวัน ข่าวบอลวันนี้ ข่าวนักเตะย้ายสโมสร ข้อมูลซื้อขายนักเตะล่าสุด ติดตามข้อมูลวงการฟุตบอลรู้ก่อนใคร จากการอ่านข่าวบอลสดใหม่จากเว็บไซต์ MVPKICK.COM" />
                <meta property="og:image" content="https://mvpkick.com/img/asset/og-img-main.jpg" />
                </Helmet>
            <div className="tdedPostLists text-center">
                {dataNews}
                <div>
                    <div className="col-4 btn btn-tded-news" onClick={(e) => this.selectPage(--this.state.page)} style={this.state.page===1?{pointerEvents: 'none',opacity:'0.25'}:{cursor:'pointer'}}>ก่อนหน้า</div>
                    <div className="col-2">หน้า {this.state.page}</div>
                    <div className="col-4 btn btn-tded-news" onClick={(e) => this.selectPage(++this.state.page)} style={this.state.list.length<9?{pointerEvents: 'none',opacity:'0.25'}:{cursor:'pointer'}}>ถัดไป</div>
                </div>
                <div className="boxContentPage" style={{maxWidth :'1300px'}}>
                    <div>
                        <h1>ข่าวบอลวันนี้ ข่าวฟุตบอลรายวัน ข่าวซื้อขายนักเตะประจำวัน ตามติดทุกสถานการณ์</h1>
                        <p>การวิเคราะห์ผลบอลให้แม่นยำมาขึ้น สิ่งที่คอลูกหนังควรตามติดไม่แพ้ผลการณ์แข่งขันแต่ละแมตซ์ คือเรื่องข่าวสารวงการลูกหนังในรอบสัปดาห์ มีรายชื่อนักเตะคนไหนเพิ่มได้รับบาดเจ็บในแมตซ์ล่าสุดบ้าง ข้อมูลปัญหาภายในทีมที่คุณกำลังสนใจ ระหว่างผู้เล่นด้วยกันเองหรืออาจเป็นปัญหาระหว่างโค้ชกับเจ้าของสโมสร ข้อมูลข่าวฟุตบอลเหล่านี้คือสิ่งสำคัญให้คอลูกหนังนำไปเป็นปัจจัยในการตัดเกรดผลการแข่งมากขึ้นนอกเหนือจากสถิติการแข่งย้อนหลังของแต่ละทีม สำหรับ MVPKICK.COM เรารวบรวมความเคลื่อนไหววงการฟุตบอลในรอบวันจากสื่อต่างประเทศชั้นนำ ส่งต่อข้อมูลสมายังเว็บไซต์ MVPKICK เพื่อให้แฟนบอลชาวไทยได้ตามติดสถานกาณ์สำคัญในรอบวันไม่มีพลาดคอนเท้นต์ที่แฟนบอลต้องรู้ อัพเดทสดใหม่ ทันทุกข่าวสารก่อนใคร</p>
                        <p>ข่าวค่าตัวดาวซัลโว ข่าวบอลคืนนี้ ข่าวฟุตบอลไทยและต่างประเทศ ข่าวบอลพรีเมียร์ลีกอังกฤษ บอลสเปน บอลฝรั่งเศส และโซนอื่นๆ มาครบทุกเนื้อหา เอาใจเพื่อนๆที่ชอบการติดตามข้อมูลความเคลื่อนไหวในวงการฟุตบอลรอบโลก ตื่นเช้ามาคอยเสพข่าวสารได้ทุกวันที่หน้า <Link to="/news/">ข่าวบอล</Link> จากเว็บไซต์ MVPKICK.COM เว็บไซต์ ผลบอลสด <Link to="/ผลบอลเมื่อคืน/">ผลบอลเมื่อคืน</Link> วิเคราะห์บอล ข่าวฟุตบอล มีครบ รวมถึงข้อมูลสถิติต่างๆในแต่ละทัวร์นาเม้น ข้อมูลเฉพาะทีม ประวัตินักฟุตบอล เรารวมเนื้อหาสาระกีฬาฟุตบอลไว้ในเว็บเดียวตอบโจทย์ผู้ใช้ทุกระดับ รองรับการเข้าถึงทุกอุปกรณ์ เพียงหยิบมือถือคุณเข้าเว็บ MVPKICK เท่านี้ก็ไม่ต้องกลัวพลาดเรื่องข้อมูลฟุตบอลในรอบวัน เว็บเดียวจบครบทุกสาระลูกหนังของจริง</p>
                    </div>
                </div>
            </div>
            </HelmetProvider>:
            <div className="listMatch text-center">No Data</div>
        )
    }
}

export default Mainnews; 