import React from 'react'
import axios from 'axios'

class Standing extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            list : [],
            id : this.props.id,
            idHome:this.props.idHome,
            idAway:this.props.idAway,
            loading:true
        }
    }

    componentDidMount(){
        axios.get(`https://api.mvpkick.com/event?standings=yes&id=${this.state.id}`).then(res =>{
        //axios.get(`https://service.mvpkick.com/viewmatch/standing/?id=${this.state.id}`).then(res =>{
            //let {standings} = res.data;
            let listfotSet = [];
            if(res.data.msg===undefined){
                listfotSet.push(res.data) 
                console.log(listfotSet)
                this.setState({list : listfotSet, loading : false})
            }else{
                this.setState({list : [], loading : false})
            }
        })
    }

    render(){
        let listFocusStanding = ['foulcommit','offside','possession','shotoff','shoton','corner','yellow_cards','red_cards','subst']
        
        if(this.state.list.length>0){
            var dataHome = null;
            var dataAway = null;
            let stdHome = [];
            let stdAway = [];
            let {standing_participants} = this.state.list[0];
            for(const standing_participantsKey in standing_participants){
                let {participantFK,standing_data,participant} = standing_participants[standing_participantsKey]
                if(participantFK===this.state.idHome){stdHome=standing_data; dataHome=participant}
                else if(participantFK===this.state.idAway){stdAway=standing_data; dataAway=participant}
            }
            //console.log(stdHome.findIndex(ele => ele.code==="foulcommit"))
            var standingShow = listFocusStanding.map((item,index)=>{
                let indexHome = stdHome.findIndex(ele => ele.code===item);
                let indexAway = stdAway.findIndex(ele => ele.code===item);
                
                if(indexHome!==-1&&indexAway!==-1){ // ถ้าไม่มี return -1
                    return (
                    <div className="listMatch-none-py" key={index}>
                        <div className="col-4 text-left">
                            <div className="wPercent">
                                <div className="col-3 standingCol">{stdHome[indexHome].value} ({stdHome[indexHome].value==='0'?'0':((parseInt(stdHome[indexHome].value)/(parseInt(stdHome[indexHome].value)+parseInt(stdAway[indexAway].value)))*100).toFixed(2)}%)</div>
                                <div className="col-9 progressStandingCol progressCol" style={{textAlign:'-webkit-right'}}>
                                <div className="boxProgress" style={{width:`${stdHome[indexHome].value==='0'?'0':((parseInt(stdHome[indexHome].value)/(parseInt(stdHome[indexHome].value)+parseInt(stdAway[indexAway].value)))*100).toFixed(2)}%`}}>   
                                    <div className="progress-v1 progress-striped-orange-re" style={{background:`${stdHome[indexHome].value==='0'?'none':''}`}}>
                                        <div className="progress-bar-v1"></div>                       
                                    </div> 
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 nameStanding">{item}</div>
                        <div className="col-4 text-right">
                            <div className="wPercent">
                                <div className="col-9 progressStandingCol progressCol">
                                <div className="boxProgress" style={{width:`${stdAway[indexAway].value==='0'?'0':((parseInt(stdAway[indexAway].value)/(parseInt(stdHome[indexHome].value)+parseInt(stdAway[indexAway].value)))*100).toFixed(2)}%`}}>   
                                    <div className="progress-v1 progress-striped-red" style={{background:`${stdHome[indexHome].value==='0'?'none':''}`}}>
                                        <div className="progress-bar-v1"></div>                       
                                    </div> 
                                </div>
                                </div>
                                <div className="col-3 standingCol">({stdAway[indexAway].value==='0'?'0':((parseInt(stdAway[indexAway].value)/(parseInt(stdHome[indexHome].value)+parseInt(stdAway[indexAway].value)))*100).toFixed(2)}%) {stdAway[indexAway].value}</div>
                            </div>
                        </div>
                    </div>
                    )
                }
            })
            
        }
        return(
            this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" /></div>:
            this.state.list.length>0?
            <div className="text-center">
                {standingShow?standingShow.length>0?
                <div>
                    <div className="mb-3">
                        <div className="col-4 text-center"><img src={dataHome.logo} className="logoTeamPage" alt={dataHome.name}/><br/>{dataHome.name}</div>
                        <div className="col-4"></div>
                        <div className="col-4 text-center"><img src={dataAway.logo} className="logoTeamPage" alt={dataAway.name}/><br/>{dataAway.name}</div>
                    </div>
                {standingShow}
                </div>:
                <div className="listMatch">No Standing</div>:<div className="listMatch">No Standing</div>}
            </div>:
            <div className="text-center"><div className="listMatch">No Standing</div></div>
        )
    }
}

export default Standing;