import axios from 'axios'
import React from 'react'
import {Link} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Nextmatch extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            list : [],
            id : this.props.id,
            HorA : 'all',
            tour : 'All', // พิมใหญ่
            connMobile : this.props.connMobile,
            loading : true
        }
    }

    setHva = (reciveHorA = 'all') =>{
        this.setState({HorA : reciveHorA})
    }
    setTour = (event) =>{
        this.setState({tour : event.target.value})
    }

    componentDidMount(){
        //axios.get(`https://sv.mvpkick.com/team/nextmatch.php?id=${this.state.id}`).then(res => {
        axios.get(`https://api.mvpkick.com/team/?id=${this.state.id}&nextmatch=yes`).then(res => {
            let listforSet = [];
            for (const listEvent of res.data.events) {
                listforSet.push(listEvent)
            }
            listforSet.sort((a, b) => new Date(a.startdate) - new Date(b.startdate));
            this.setState({list : listforSet, loading : false})
        })
    }
    componentDidUpdate(){
        if(this.state.connMobile!==this.props.connMobile){this.setState({connMobile : this.props.connMobile})}
    }

    render(){
        console.log(this.state.list)
        var listTour = ['All']
        var nextmatchList = this.state.list.map((item,index)=>{
            let {event_participants} = item;
            let homeInfo = {
                participant:{
                    id:'',
                    name:'',
                    gender:'',
                    countryFK:'',
                    country_name:'',
                    logo:''
                }
            }
            let awayInfo = {
                participant:{
                    id:'',
                    name:'',
                    gender:'',
                    countryFK:'',
                    country_name:'',
                    logo:''
                }
            }
            const found = listTour.find(tourName => tourName === item.tournament_template_name);
            if(found===item.tournament_template_name){
            }else{
                listTour.push(item.tournament_template_name)
            }
            for (const listEventParticipants in event_participants) {
                let {number,participant} = event_participants[listEventParticipants];
                if(number==='1'){
                    homeInfo.participant = participant;
                }else if(number==='2'){
                    awayInfo.participant = participant;
                }
            }

            if(this.state.HorA==='home'&&homeInfo.participant.id!==this.state.id){
                return undefined;
            }else if(this.state.HorA==='away'&&awayInfo.participant.id!==this.state.id){
                return undefined;
            }
            if(this.state.tour!=='All'&&item.tournament_template_name!==this.state.tour){
                return undefined;
            }

            return(
                <div key={item.id}>
                    {this.state.connMobile===false?
                    /* PC */
                    <div className="listMatch">
                        <div className="col-2 text-center">{item.startdate.substr(0,10)}<br /><Link to={`/league/${item.tournament_templateFK}/`} target="_blank">{item.tournament_template_name}</Link></div>
                        <div className="col-3 text-right"><Link to={`/team/${homeInfo.participant.id}/`} target="_blank">{homeInfo.participant.name}</Link><LazyLoadImage className="logoTeamLs" alt={homeInfo.participant.name} src={homeInfo.participant.logo} /></div>
                        <div className="col-2 text-center ftScore"><Link to={`/viewmatch/${item.id}/`} target="_blank"><span> - </span></Link></div>
                        <div className="col-3"><LazyLoadImage className="logoTeamLs" alt={awayInfo.participant.name} src={awayInfo.participant.logo} /><Link to={`/team/${awayInfo.participant.id}/`} target="_blank">{awayInfo.participant.name}</Link></div>
                        <div className="col-2 text-center text-white"><Link to={`/h2h/${item.id}/`} target="_blank"><LazyLoadImage className="h2hIcon border-shine" alt='h2h' src='/img/icon/h2h_icon.webp' /></Link></div>
                    </div>
                    :
                    /* Mobile */
                    <div className="listMatch">
                        <div className="col-2 text-center">{item.startdate.substr(0,10)}<br /><Link to={`/league/${item.tournament_templateFK}/`} target="_blank">{item.tournament_template_name}</Link></div>
                        <div className="col-6"><LazyLoadImage className="logoTeamLs" alt={homeInfo.participant.name} src={homeInfo.participant.logo} /><Link to={`/team/${homeInfo.participant.id}/`} target="_blank">{homeInfo.participant.name}</Link><br/><LazyLoadImage className="logoTeamLs" alt={awayInfo.participant.name} src={awayInfo.participant.logo} /><Link to={`/team/${awayInfo.participant.id}/`} target="_blank">{awayInfo.participant.name}</Link></div>
                        <div className="col-1 text-center text-white"></div>
                        <div className="col-1 text-center text-white"></div>
                        <div className="col-2 text-center text-white"><Link to={`/h2h/${item.id}/`} target="_blank"><LazyLoadImage className="h2hIconVertical border-shine" alt='h2h' src='/img/icon/h2h_icon_vertical.webp' /></Link></div>
                    </div>
                    }
                </div>
            )
        })

        for(let z=0;z<nextmatchList.length;z++){
            if(nextmatchList[z]===undefined){
                nextmatchList.splice(z,1)
                z--;
            }
        }
        
        return(
            this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" alt="Loading" /></div>:
            <div>
                <div className="tournament" key='HeaderRow'>
                <div className="text-center">
                    <div className={this.state.HorA==='home'?'active btn btn-h2h col-3':'btn btn-h2h col-3'} onClick={(e)=>this.setHva('home')}>Home</div>
                    <div className={this.state.HorA==='all'?'active btn btn-h2h col-3':'btn btn-h2h col-3'} onClick={(e)=>this.setHva('all')}>All</div>
                    <div className={this.state.HorA==='away'?'active btn btn-h2h col-3':'btn btn-h2h col-3'} onClick={(e)=>this.setHva('away')}>Away</div>
                </div>
                <div className="col-12 py-2 text-center">Tournament<br/>{listTour?listTour.length>0?<select className="selTour" onChange={this.setTour} value={this.state.tour}>{listTour.map(item=>{return <option key={item}>{item}</option>})}</select>:console.log():console.log()}</div>
                </div>
                {nextmatchList?nextmatchList.length>0?nextmatchList:<div className="listMatch"><div className="col-12 text-center">No Data</div></div>:console.log()}
            </div>
        )
    }

}

export default Nextmatch;