import React from 'react'
import {auth} from "../../modules/firebase"
import {Redirect} from "react-router-dom";
import {connect} from "react-redux"; 
import { Link } from "react-router-dom";
import {db} from "../../modules/firebase"

class User extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            allvote : 0
        }
    }

    callLogout = () =>{
        auth.signOut().then(() => {
            this.props.dispatch({
                type:'logout',
                data:undefined
            })
            console.log('Sign-out successful.')
            console.log(this.props.user)
          }).catch((error) => {
            // An error happened.
          });
    }

    componentDidMount(){
        db.collection("user").where("email", "==", this.props.user.email).get().then((querySnapshot) => {
            if(querySnapshot.size===0){ // no user data in user collection
                return
            }else{
             querySnapshot.forEach((doc) => {
                 this.setState({allvote : doc.data().allvote})
             })
            }
        })
    }

    render() {
        console.log(this.props.user)
        return (
            <div>
                {this.props.user===undefined?
                <Redirect to='/login'/>:
                <div className="infoUser">
                    <h3 style={{color:'rgb(27 232 176)'}}>เข้าสู่ระบบสำเร็จ !</h3>
                    <h4>My Profile</h4>
                    <div className="row">
                        <div className="col-5">Name :</div>
                        <div className="col-5">{this.props.user.displayName}</div>
                    </div>
                    <div className="row">
                        <div className="col-5">E-mail :</div>
                        <div className="col-5">{this.props.user.email}</div>
                    </div>
                    <div className="row">
                        <div className="col-5">Voted :</div>
                        <div className="col-5">{this.state.allvote}</div>
                    </div>
                    <div className="cmBtn cmLogin" onClick={(e)=>this.callLogout()}>ออกจากระบบ</div>
                    <div className="cmBtn cmLogin" onClick={(e)=>window.history.back()} style={{backgroundColor:'#1ca5d4'}}>กลับไปหน้าก่อนหน้านี้</div>
                    <Link to='/'><div className="cmBtn cmLogin" style={{backgroundColor:'#009688'}}>กลับไปหน้าแรก</div></Link>
                </div>
                }                
            </div>
        )
    }

}

const mapStateToProps = (state) =>{
    return{
        user : state.user
    }
}

export default connect(mapStateToProps)(User)