const Islogin = (state = {user : undefined},action) =>{
    
    switch(action.type){
        case 'login':
            state = {user : action.data}
            return state;
        case 'logout':
            state = {user : undefined}
            return state;
        default:
            return state;
    }
    
}
export default Islogin;