import React from 'react'
import axios from 'axios'
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Thumbnailtded extends React.Component{
    _isMounted = false;
    constructor(props){
        super(props)
        this.state = {
            id : this.props.id,
            homeInfo : '',
            awayInfo : '',
            resultHome : '',
            resultAway : '',
            infoMatch: '',
            loading : true
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
      }
    
    componentDidMount(){
        this._isMounted = true;
        axios.get(`https://api.mvpkick.com/event?id=${this.state.id}`).then(res =>{
            //axios.get(`https://sv.mvpkick.com/h2h/index.php?id=${this.state.id}`).then(res =>{
            let homeInfo = {}
            let awayInfo = {}
            let infoMatch = {
                id: '',
                tournament_stageFK: '',
                startdate: '',
                status_type: '',
                status_descFK: '',
                tournamentFK: '',
                tournament_templateFK: '',
                tournament_stage_name: '',
                tournament_name: '',
                tournament_template_name: '',
                VenueName: ''
            }
            let resultHome = {
                ordinarytime:null,
                halftime:null,
                finalresult:null
            };
            let resultAway = {
                ordinarytime:null,
                halftime:null,
                finalresult:null
            };
            if(res.data.msg!==undefined){
                this.setState({homeInfo : homeInfo, awayInfo : awayInfo, resultHome : resultHome, resultAway : resultAway, infoMatch : infoMatch, loading : false});
            }else{

            
            let event = res.data;
                infoMatch = {
                    id: event.id,
                    tournament_stageFK: event.tournament_stageFK,
                    startdate: event.startdate,
                    status_type: event.status_type,
                    status_descFK: event.status_descFK,
                    tournamentFK: event.tournamentFK,
                    tournament_templateFK: event.tournament_templateFK,
                    tournament_stage_name: event.tournament_stage_name,
                    tournament_name: event.tournament_name,
                    tournament_template_name: event.tournament_template_name,
                }
                let {event_participants, property} = event;
                for (const listEventParticipants in event_participants) {
                    let {number,participant,result} = event_participants[listEventParticipants];
                    switch(number){
                        case '1':homeInfo = participant;
                        for(const resultKey in result){
                            switch (result[resultKey].result_code) {
                                case "ordinarytime":resultHome.ordinarytime=result[resultKey].value; break;
                                case "halftime":resultHome.halftime=result[resultKey].value; break;
                                case "finalresult":resultHome.finalresult=result[resultKey].value; break;
                                default:break;
                            }
                        }
                        break;
                        case '2':awayInfo = participant;
                        for(const resultKey in result){
                            switch (result[resultKey].result_code) {
                                case "ordinarytime":resultAway.ordinarytime=result[resultKey].value; break;
                                case "halftime":resultAway.halftime=result[resultKey].value; break;
                                case "finalresult":resultAway.finalresult=result[resultKey].value; break;
                                default:break;
                            }
                        }
                        break;
                        default:break;
                    }
                }
                for (const listEventProperty in property) {
                    let {name, value} = property[listEventProperty];
                    switch(name){
                        case 'VenueName':infoMatch.VenueName=value;break;
                        default:break;
                    }
                }
            
            if (this._isMounted) {
            this.setState({homeInfo : homeInfo, awayInfo : awayInfo, resultHome : resultHome, resultAway : resultAway, infoMatch : infoMatch, loading : false});
            }
            }
        })
    }
    
    render(){
       // console.log(this.state)
        console.log(this.state.infoMatch.id)
        return(
            this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" alt="Loading" /></div>:
            <div>
                <div className="thumbTdedImg">
                    <div className="tourNameTded col-12 py-2">{this.state.infoMatch.tournament_template_name} ({this.state.infoMatch.tournament_name})<br/>{this.state.infoMatch.tournament_stage_name}<br/>{this.state.infoMatch.startdate.slice(0,10)} {this.state.infoMatch.status_descFK==='6'?'(จบ)':''}</div>
                    <div className="topLogoH2h">
                        <div className="col-6"><LazyLoadImage className="logoTeamPage H2hTopLogo" alt={this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name} src={this.state.homeInfo.logo} /><br/>{this.state.homeInfo.thainame!==null?this.state.homeInfo.thainame:this.state.homeInfo.name}</div>
                        <div className="col-6"><LazyLoadImage className="logoTeamPage H2hTopLogo " alt={this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name} src={this.state.awayInfo.logo} /><br/>{this.state.awayInfo.thainame!==null?this.state.awayInfo.thainame:this.state.awayInfo.name}</div>
                    </div>
                    <div className="tourStartTded col-12"><span style={this.state.infoMatch.status_descFK!=='1'?{background:'#e3104a'}:{}}>{this.state.infoMatch.status_descFK!=='1'?this.state.infoMatch.status_descFK!=='6'?this.state.infoMatch.status_type:`${this.state.resultHome.finalresult} - ${this.state.resultAway.finalresult}`:this.state.infoMatch.startdate.slice(11,16)}</span></div>
                    <div className="col-12 py-2">{this.state.infoMatch.VenueName!==''?this.state.infoMatch.VenueName:''}</div>
                </div>
                    <div className="headingTdedPage text-center mt-3"><span className="wicroeballTitle">วิเคราะห์บอล {this.state.infoMatch.tournament_template_name}</span><h3>{this.state.homeInfo.name} VS {this.state.awayInfo.name}</h3></div>
            </div>
        )
    }
}

export default Thumbnailtded; 