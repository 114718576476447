import React from 'react'
import axios from 'axios'
import {Link} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Topplayer extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            listTour : this.props.listTour,
            list : [],
            connMobile : this.props.connMobile,
            selectTeam : null,
            tour : '',
            loading : true
        }
    }

    setTour = (event) =>{
        this.setState({tour : event.target.value})
    }

    componentDidMount(){
        this.callApi()
    }

    componentDidUpdate(){
        //console.log(this.state.listTour)
        if(this.state.connMobile!==this.props.connMobile){this.setState({connMobile : this.props.connMobile})}
        if(this.state.listTour!==this.props.listTour){this.callApi(this.props.listTour)}
    }

    callApi = (listTour = this.state.listTour) => {
        let listData = [];
        listTour.map((item,index)=>{
            // axios.get(`https://sv.mvpkick.com/league/topplayer.php?id=${item.id}`).then(res=>{
            axios.get(`https://api.mvpkick.com/tournament?id=${item.id}&topplayer=yes`).then(res=>{
                if(res.data.msg===undefined){
                    listData.push({name : item.name, standings : res.data})
                    this.setState({list : listData, listTour : listTour,tour : listData[0].name, loading : false })
                }else{
                    this.setState({list : listData, listTour : listTour, loading : false })
                }
                
            })
        })
    }

    render(){
        if(this.state.list.length>0){
            var dataTopplayer = this.state.list.map((item,index) => {
                //console.log(this.state.listTour)
                if(item.name!==this.state.tour){
                    return;
                }
                let {name,standings} = item;
                //console.log(standings)
                let tourName = name;
                let listData = [];
                //for(const standingsKey in standings){
                    let {standing_participants} = standings
                    for(const standing_participantsKey in standing_participants){
                        let {rank,team,standing_data,participant} = standing_participants[standing_participantsKey]
                        let dataItem = {
                            participant:null,
                            team:null,
                            rank:null,
                            goals:'-',
                            penalties:'-',
                            onestscorer:'-'
                        }
                        dataItem.participant=participant;
                        dataItem.team=team;
                        dataItem.rank=rank;
                        for(let i=0;i<standing_data.length;i++){
                            switch(standing_data[i].code){
                                case'goals':dataItem.goals=standing_data[i].value;break;
                                case'penalties':dataItem.penalties=standing_data[i].value;break;
                                case'1stscorer':dataItem.onestscorer=standing_data[i].value;break;
                                default:break;
                            }
                        }
                        listData.push(dataItem)
                    }
                    //console.log(listData)
                    listData.sort((a,b)=>a.rank - b.rank)
                //}

                return(
                    <div key={index}>
                        <div className="tournament px-0 row col-12 text-center">
                            <div className="col-6 text-left">Name</div>
                            <div className="col-2">Goals</div>
                            <div className="col-2">Pen</div>
                            <div className="col-2">1stscorer</div>
                        </div>
                        {listData?listData.length>0?
                        listData.map((item,i)=>{
                            return(
                                <div className="listMatch text-center" key={i}>
                                    <div className="col-6 text-left"><LazyLoadImage className="logoTeamLs" alt={item.team.thainame!==null?item.team.thainame:item.team.name} src={item.team.logo} /><Link to={`/player/${item.participant.id}/`} target="_blank">{item.participant.thainame!==null?item.participant.thainame:item.participant.name}</Link></div>
                                    <div className="col-2">{item.goals}</div>
                                    <div className="col-2">{item.penalties}</div>
                                    <div className="col-2">{item.onestscorer}</div>
                                </div>
                            )
                        }):<div className="listMatch text-center">No data</div>:<div className="listMatch text-center">No data</div>}
                    </div>
                )
            })
        }
        return(
            this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" alt="Loading" /></div>:
            <div>
                {this.state.list.length>1?
                <div className="text-center"><select className="selTour mb-3" onChange={this.setTour} value={this.state.tour}>{this.state.list.map(item=>{return <option key={item.name}>{item.name}</option>})}</select></div>:<div className="listMatch text-center">No data</div>}
                {dataTopplayer}
            </div>
        )
    }
    
    
}


export default Topplayer;