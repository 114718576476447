import React from 'react'
import axios from 'axios'
import {Link} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactHtmlParser from 'react-html-parser';
import Sidebarnews from './Sidebarnews'
import DocumentMeta from 'react-document-meta';

class ContentNews extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            list : [],
            id : this.props.id
        }
    }

    componentDidMount(){
        
        axios.get(`https://news.mvpkick.com/index.php?id=${this.state.id}`).then(res =>{
                this.setState({list : res.data})
        })
    }

    componentDidUpdate(){
       if(this.props.id!==this.state.id){
            axios.get(`https://news.mvpkick.com/index.php?id=${this.props.id}`).then(res =>{
                this.setState({list : res.data, id : this.props.id})
            })
       }
    }

    setDate = (timestamp) =>{
        let d = new Date(timestamp*1000);
        let months = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฏาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
        let hour = d.getHours();
        let minutes = d.getMinutes();
        if (hour < 10) { hour = "0" + hour; }   
        if (minutes < 10) { minutes = "0" + minutes; }      

        return `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`
    }

    render(){

        window.scrollTo(0, 0) // for debug click from sidebar
        const html = this.state.list.length>0?this.state.list[0].des:null
        
        return(
            this.state.list.length>0?
            <HelmetProvider>
                <Helmet>
                <title>{this.state.list[0].title}</title>
                <meta name="description" content={this.state.list[0].metades} />
                <link rel="canonical" href={`https://mvpkick.com/news/${this.state.id}/`} />
                <meta property="og:url" content={`https://mvpkick.com/news/${this.state.id}/`} />
                <meta property="og:title" content={this.state.list[0].title} />
                <meta property="og:description" content={this.state.list[0].metades} />
                <meta property="og:image" content={`https://news.mvpkick.com/images/${this.state.list[0].thumbnail}`} />
                {/* OpenGraph tags */}
                <meta name="og:url" content={`https://mvpkick.com/news/${this.state.id}/`} />
                <meta name="og:title" content={this.state.list[0].title} />
                <meta name="og:description" content={this.state.list[0].metades} />
                <meta name="og:image" content={`https://news.mvpkick.com/images/${this.state.list[0].thumbnail}`} />
                <meta name="og:type" content="website" />
                </Helmet>
            <div className="mainBoxNews col-12">
                <div className="contentNewsDetails col-6">
                    <LazyLoadImage src={`https://news.mvpkick.com/images/${this.state.list[0].thumbnail}`} alt={this.state.list[0].title} className="posterNewsContentpost" />
                    <div className="text-left headingNewsPage">
                        <h1>{this.state.list[0].title}</h1>
                        <span>Updated : {this.setDate(this.state.list[0].updatepost)}</span>
                         { ReactHtmlParser(html) }   
                        <div>คำค้นหา : {this.state.list[0].tag.map((item,index) => { 
                            return (index+1===this.state.list[0].tag.length?<Link to={`/tag/${item.trim().replace(" ", "-")}/`}  key={index}>{item.trim()}</Link>:<span  key={index}><Link to={`/tag/${item.trim().replace(" ", "-")}/`}>{item.trim()}</Link>, </span>)
                        })}</div>
{/*<div><a href="https://bit.ly/spin77service" rel="noopener nofollow" target="_blank"><LazyLoadImage src={`/img/asset/900x300_bonus55bet.gif`} alt='spin77' className="bannerHomepage" /></a></div>*/}
                        {/*<div><a href="https://bit.ly/kr777register" rel="noopener nofollow" target="_blank"><LazyLoadImage src={`/img/asset/900x300-kr777.gif`} alt='kr777' className="bannerHomepage" /></a></div>*/}
                    </div>
                    
                </div>
                <div>
                <div className="sidebarNews col-9"><Sidebarnews id={this.state.id}/></div>
                </div>
            </div>
            </HelmetProvider>:
            <div className="listMatch text-center">No Data</div>
        )
    }
}

export default ContentNews; 
