import './App.scss';
import Home from './pages/Home'
import H2h from './pages/h2h/H2h'
import Team from './pages/team/Team'
import Player from './pages/player/Player'
import League from './pages/league/League'
import Viewmatch from './pages/viewmatch/Viewmatch'
import Yesterday from './pages/Yesterday'
import Tomorrow from './pages/Tomorrow'
import Tded from './pages/Tded'
import News from './pages/News'
import Login from './pages/login/Login'
import User from './pages/login/User'
import Tag from './pages/Tag'
import Freelivescore from './pages/free/Freelivescore'
import Freeyesterdayls from './pages/free/Freeyesterdayls'
import Freetomorrowls from './pages/free/Freetomorrowls'
import { Switch, Route, Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';

function App() {

  const history = useHistory();
  //console.log(history)
  
  return (
    <div>
      {history.location.pathname.includes("/free/")?
      console.log():
    <div className="topbar-nav">
      
      {/*
      <Link to={`/`}><div className="nav-item"><img src="/img/icon/icon-ponballsod.webp" alt="icon ผลบอลสด" className="icon-nav"/><span>ผลบอลสด</span></div></Link>
      <Link to={`/ผลบอลเมื่อคืน/`}><div className="nav-item"><img src="/img/icon/icon-ballyesterday.webp" alt="icon ผลบอลเมื่อคืน" className="icon-nav"/><span>ผลบอลเมื่อคืน</span></div></Link>
      <Link to={`/บอลพรุ่งนี้/`}><div className="nav-item"><img src="/img/icon/icon-balltomorrow.webp" alt="icon บอลพรุ่งนี้" className="icon-nav"/><span>บอลพรุ่งนี้</span></div></Link>
      <Link to={`/tded/`}><div className="nav-item"><img src="/img/icon/icon-tdedball.webp" alt="icon วิเคราะห์บอล" className="icon-nav"/><span>วิเคราะห์บอล</span></div></Link>
      <Link to={`/news/`}><div className="nav-item-last"><img src="/img/icon/icon-news.webp" alt="icon ข่าวบอล" className="icon-nav"/><span>ข่าวบอล</span></div></Link>
      */}
      <a href={`/`}><div className="nav-item"><img src="/img/icon/icon-ponballsod.webp" alt="icon ผลบอลสด" className="icon-nav"/><span>ผลบอลสด</span></div></a>
      <a href={`/ผลบอลเมื่อคืน/`}><div className="nav-item"><img src="/img/icon/icon-ballyesterday.webp" alt="icon ผลบอลเมื่อคืน" className="icon-nav"/><span>ผลบอลเมื่อคืน</span></div></a>
      <a href={`/บอลพรุ่งนี้/`}><div className="nav-item"><img src="/img/icon/icon-balltomorrow.webp" alt="icon บอลพรุ่งนี้" className="icon-nav"/><span>บอลพรุ่งนี้</span></div></a>
      <a href={`/tded/`}><div className="nav-item"><img src="/img/icon/icon-tdedball.webp" alt="icon วิเคราะห์บอล" className="icon-nav"/><span>วิเคราะห์บอล</span></div></a>
      <a href={`/news/`}><div className="nav-item-last"><img src="/img/icon/icon-news.webp" alt="icon ข่าวบอล" className="icon-nav"/><span>ข่าวบอล</span></div></a>
      
      
    </div>}
    <div className="App" style={history.location.pathname.includes("/free/")?{paddingTop:'0px'}:{}}>
      <Switch>
      
        <Route path="/h2h/:id" render={(props ) => <H2h {...props } />} />

        <Route path="/h2h">
          <H2h />
        </Route>

        <Route path="/team/:id" render={(props ) => <Team {...props } />} />

        <Route path="/team">
          <Team />
        </Route>

        <Route path="/player/:id" render={(props ) => <Player {...props } />} />

        <Route path="/player">
          <Player />
        </Route>

        <Route path="/viewmatch/:id" render={(props ) => <Viewmatch {...props } />} />

        <Route path="/viewmatch">
          <Viewmatch />
        </Route>

        <Route path="/league/:id" render={(props ) => <League {...props } />} />

        <Route path="/league">
          <League />
        </Route>

        <Route path="/tded/:id" render={(props ) => <Tded {...props } />} />

        <Route path="/tded">
          <Tded />
        </Route>

        <Route path="/news/:id" render={(props ) => <News {...props } />} />

        <Route path="/news">
          <News />
        </Route>

        <Route path="/tag/:tag" render={(props ) => <Tag {...props } />} />

        <Route path="/tag">
          <Tag />
        </Route>

        <Route path="/free/ผลบอลสด">
          <Freelivescore />
        </Route>

        <Route path="/free/ผลบอลเมื่อคืน">
          <Freeyesterdayls />
        </Route>
        
        <Route path="/free/บอลพรุ่งนี้">
          <Freetomorrowls />
        </Route>

        <Route path="/ผลบอลเมื่อคืน">
          <Yesterday/>
        </Route>

        <Route path="/บอลพรุ่งนี้">
          <Tomorrow />
        </Route>

        <Route path="/login">
          <Login />
        </Route>

        <Route path="/user" render={(props) => <User {...props}/>}/>

        <Route path="/">
          <Home />
        </Route>

      </Switch>
    </div>
    <div className="footerBar" style={history.location.pathname.includes("/free/")?{marginBottom:'0px'}:{}}>เช็คผลบอลสด ทุกโปรแกรมแข่ง ต้องที่ <Link to={`/`}>mvpkick.com</Link> เท่านั้น</div>

    {history.location.pathname.includes("/free/")?
      console.log():
    <div className="footerbar-nav">
      {/*
      <Link to={`/บอลพรุ่งนี้/`}><div className="nav-item-footerbar"><img src="/img/icon/icon-balltomorrow.webp" alt="icon บอลพรุ่งนี้" className="icon-nav"/><br/><span>บอลพรุ่งนี้</span></div></Link>
      <Link to={`/ผลบอลเมื่อคืน/`}><div className="nav-item-footerbar"><img src="/img/icon/icon-ballyesterday.webp" alt="icon ผลบอลเมื่อคืน" className="icon-nav"/><br/><span>บอลเมื่อคืน</span></div></Link>
      <Link to={`/`}><div className="nav-item-footerbar"><img src="/img/icon/icon-ponballsod.webp" alt="icon ผลบอลสด" className="icon-nav"/><br/><span>ผลบอลสด</span></div></Link>
      <Link to={`/tded/`}><div className="nav-item-footerbar"><img src="/img/icon/icon-tdedball.webp" alt="icon วิเคราะห์บอล" className="icon-nav"/><br/><span>วิเคราะห์บอล</span></div></Link>
      <Link to={`/news/`}><div className="nav-item-footerbar"><img src="/img/icon/icon-news.webp" alt="icon ข่าวบอล" className="icon-nav"/><br/><span>ข่าวบอล</span></div></Link>
      */}
      <a href={`/บอลพรุ่งนี้/`}><div className="nav-item-footerbar"><img src="/img/icon/icon-balltomorrow.webp" alt="icon บอลพรุ่งนี้" className="icon-nav"/><br/><span>บอลพรุ่งนี้</span></div></a>
      <a href={`/ผลบอลเมื่อคืน/`}><div className="nav-item-footerbar"><img src="/img/icon/icon-ballyesterday.webp" alt="icon ผลบอลเมื่อคืน" className="icon-nav"/><br/><span>บอลเมื่อคืน</span></div></a>
      <a href={`/`}><div className="nav-item-footerbar"><img src="/img/icon/icon-ponballsod.webp" alt="icon ผลบอลสด" className="icon-nav"/><br/><span>ผลบอลสด</span></div></a>
      <a href={`/tded/`}><div className="nav-item-footerbar"><img src="/img/icon/icon-tdedball.webp" alt="icon วิเคราะห์บอล" className="icon-nav"/><br/><span>วิเคราะห์บอล</span></div></a>
      <a href={`/news/`}><div className="nav-item-footerbar"><img src="/img/icon/icon-news.webp" alt="icon ข่าวบอล" className="icon-nav"/><br/><span>ข่าวบอล</span></div></a>
    </div>
    }
    </div>
  );
}

export default App;
