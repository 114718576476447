import React from 'react'
import axios from 'axios'
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Statistics extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            listTour : this.props.listTour,
            list : [],
            connMobile : this.props.connMobile,
            selectTeam : null,
            tour : '',
            loading : true
        }
    }
    callApi = (listTour = this.state.listTour) =>{
        //console.log(listTour)
        let listData = [];
        listTour.map((item,index)=>{
            axios.get(`https://api.mvpkick.com/tournament?id=${item.id}&statistic=yes`).then(res =>{
            //axios.get(`https://sv.mvpkick.com/league/statistics.php?id=${item.id}`).then(res =>{
                
                listData.push({name : item.name, statistics : res.data.statistics})
                this.setState({list : listData, listTour : listTour,tour : listData[0].name ,loading : false})
            })
        })

    }
    selectTeam = (select) =>{
        if(this.state.selectTeam!==select){this.setState({selectTeam:select})}else if(this.state.selectTeam===select){this.setState({selectTeam:null})}
    }

    setTour = (event) =>{
        this.setState({tour : event.target.value})
    }

    componentDidMount(){
        this.callApi()
    }

    componentDidUpdate(){
        //console.log(this.state.listTour)
        if(this.state.connMobile!==this.props.connMobile){this.setState({connMobile : this.props.connMobile})}
        if(this.state.listTour!==this.props.listTour){this.callApi(this.props.listTour)}
    }
    
    render() {
        if(this.state.list.length>0){
            var dataStatistics = this.state.list.map((item,index) => {
                //console.log(this.state.listTour)
                if(item.name!==this.state.tour){
                    return;
                }
                let {name,statistics} = item;
                //let {name} = item;
                let tourName = name;
                let listData = [];
                for(const statisticsKey in statistics){
                    //let {statistic_participants} = item
                    let {statistic_participants} = statistics[statisticsKey]
                    //console.log(statistic_participants)
                    for(const statistic_participantsKey in statistic_participants){
                        let {participant,statistic_data} = statistic_participants[statistic_participantsKey]
                        
                        let dataItem = {
                            name : null,
                            id : null,
                            logo : null,
                            Total_games : null,
                            Total_goals : null,
                            Total_yellow_cards : null,
                            Total_red_cards : null,
                            Total_clean_sheets : null,
                            Won_1st_half : null,
                            Won_2nd_half : null,
                            Bore_draws : null,
                            Goals_ratio : null,
                            Penalty_goals : null,
                            Own_goals : null,
                            First_goal : null,
                            Last_goal : null,
                            tourName : null
                        }
                        for(let i=0;i<statistic_data.length;i++){
                            switch(statistic_data[i].statistic_data_type.name){
                                case 'Total games':dataItem.Total_games=statistic_data[i].value;break;
                                case 'Total goals':dataItem.Total_goals=statistic_data[i].value;break;
                                case 'Total yellow cards':dataItem.Total_yellow_cards=statistic_data[i].value;break;
                                case 'Total red cards':dataItem.Total_red_cards=statistic_data[i].value;break;
                                case 'Total clean sheets':dataItem.Total_clean_sheets=statistic_data[i].value;break;
                                case 'Won 1st half':dataItem.Won_1st_half=statistic_data[i].value;break;
                                case 'Won 2nd half':dataItem.Won_2nd_half=statistic_data[i].value;break;
                                case 'Bore draws':dataItem.Bore_draws=statistic_data[i].value;break;
                                case 'Goals ratio':dataItem.Goals_ratio=statistic_data[i].value;break;
                                case 'Penalty goals':dataItem.Penalty_goals=statistic_data[i].value;break;
                                case 'Own goals':dataItem.Own_goals=statistic_data[i].value;break;
                                case 'First goal':dataItem.First_goal=statistic_data[i].value;break;
                                case 'Last goal':dataItem.Last_goal=statistic_data[i].value;break;
                                default:break;
                            }
                        }
                        dataItem.id = participant.id;
                        dataItem.name = participant.name;
                        dataItem.logo = participant.logo;
                        dataItem.tourName = tourName;
                        listData.push(dataItem)
                    }
                    
                }

                return(
                    listData.length>0?
                    listData.map((item,index)=>{
                        //if(this.state.selectTeam===index){
                            return(
                                <div key={index} className="text-center">
                                    {index===0?
                                    <div className="tournament  px-0 row col-12">{item.tourName}</div>
                                    :''}
                                    <div className="text-center">
                                        <div className='btn btn-h2h my-2' onClick={(e)=>this.selectTeam(item.id)}><LazyLoadImage className="logoTeamStatistics" alt={item.name} src={item.logo} /><br/>{item.name}</div>
                                    </div>
                                    {this.state.selectTeam===item.id?
                                    <div className="tournament row">
                                        <div className="col-12 text-center">{item.name}</div>
                                    {item.Total_games?<div className="listMatch"><div className="col-8">Total games</div><div className="col-4">{item.Total_games}</div></div>:''}
                                    {item.Total_goals?<div className="listMatch"><div className="col-8">Total goals</div><div className="col-4">{item.Total_goals}</div></div>:''}
                                    {item.Total_yellow_cards?<div className="listMatch"><div className="col-8">Total yellow cards</div><div className="col-4">{item.Total_yellow_cards}</div></div>:''}
                                    {item.Total_red_cards?<div className="listMatch"><div className="col-8">Total red cards</div><div className="col-4">{item.Total_red_cards}</div></div>:''}
                                    {item.Total_clean_sheets?<div className="listMatch"><div className="col-8">Total clean sheets</div><div className="col-4">{item.Total_clean_sheets}</div></div>:''}
                                    {item.Won_1st_half?<div className="listMatch"><div className="col-8">Won 1st half</div><div className="col-4">{item.Won_1st_half}</div></div>:''}
                                    {item.Won_2nd_half?<div className="listMatch"><div className="col-8">Won 2nd half</div><div className="col-4">{item.Won_2nd_half}</div></div>:''}
                                    {item.Bore_draws?<div className="listMatch"><div className="col-8">Bore draws</div><div className="col-4">{item.Bore_draws}</div></div>:''}
                                    {item.Goals_ratio?<div className="listMatch"><div className="col-8">Goals ratio</div><div className="col-4">{item.Goals_ratio}</div></div>:''}
                                    {item.Penalty_goals?<div className="listMatch"><div className="col-8">Penalty goals</div><div className="col-4">{item.Penalty_goals}</div></div>:''}
                                    {item.Own_goals?<div className="listMatch"><div className="col-8">Own goals</div><div className="col-4">{item.Own_goals}</div></div>:''}
                                    {item.First_goal?<div className="listMatch"><div className="col-8">First goal</div><div className="col-4">{item.First_goal}</div></div>:''}
                                    {item.Last_goal?<div className="listMatch"><div className="col-8">Last goal</div><div className="col-4">{item.Last_goal}</div></div>:''}
                                    </div>
                                    :
                                    ''}
                                </div>
                            )
                        //}
                    })
                    :<div className="listMatch text-center" key={index}>No Data</div>
                )

            })
            
        }
        return (
            this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" alt="Loading"/></div>:
            <div>
                {this.state.list.length>1?
                <div className="text-center"><select className="selTour mb-3" onChange={this.setTour} value={this.state.tour}>{this.state.list.map(item=>{return <option key={item.name}>{item.name}</option>})}</select></div>:''}
                {dataStatistics}
            </div>
        )
    }
}

export default Statistics;