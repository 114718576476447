import React from 'react'
import axios from 'axios'
import {Link} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

class Tag extends React.Component{
    _isMounted = false;

    constructor(props){
        super(props)
        this.state = {
            list : [],
            page : 1,
            tag : null,
            tagHaveSlash : null,
            from : this.props.from?this.props.from:null
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
      }

    componentDidMount(){
        this._isMounted = true;
        let {match} = this.props;
        if(match===undefined){
            // redirect home page
            window.location.replace("https://mvpkick.com");
        }else{
            let {tag} = match.params
            tag = tag.replace(" ","-") // ดักเคสพิมมาผ่าน url แบบไม่มี -
            let mainTag = tag.replace("-"," ")
            axios.get(`https://news.mvpkick.com/index.php?tag=${mainTag}&page=${this.state.page}`).then(res =>{
                if (this._isMounted) {
                    this.setState({list : res.data, tag : mainTag, tagHaveSlash : tag})
                }
            })
        }
    }

    selectPage = (page) =>{
        window.scrollTo(0, 0)
        axios.get(`https://news.mvpkick.com/index.php?tag=${this.state.tag}&page=${page}`).then(res =>{
            this.setState({list : res.data, page : page})
        })
    }

    setDate = (timestamp) =>{
        let d = new Date(timestamp*1000);
        let months = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฏาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
        let hour = d.getHours();
        let minutes = d.getMinutes();
        if (hour < 10) { hour = "0" + hour; }   
        if (minutes < 10) { minutes = "0" + minutes; }      

        return `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`
    }

    render(){

        if(this.state.list.length>0){
            var dataNews = this.state.list.slice(0,8).map((item,index) =>{
                
                return(
                    <Link to={`/news/${item.id}/`} target="_blank" key={index}>
                    <div className="listTded col-5" >
                        <div style={{overflow:'hidden'}}><div className="ThumbnailNews" style={{backgroundImage:`url(https://news.mvpkick.com/images/${item.thumbnail})`}}></div></div>
                        <div className="headingNewsPage text-left"><h3>{item.title}</h3></div>
                        <div className="text-left pa-2">{item.stripdes.slice(0,150)}...</div>
                        <div className="col-12 dateTdedRow text-right"><span>{this.setDate(item.updatepost)}</span></div>
                    </div>
                    </Link>
                )
                
            })
        }
        
        return(
            this.state.list.length>0?
            <HelmetProvider>
                <Helmet>
                <title>{this.state.tag} : เนื้อหาข่าวที่เกี่ยวข้อง MVPKICK.COM</title>
                <meta name="description" content={`ค้นหาข้อมูลเนื้อหาฟุตบอล เกี่ยวกับ ${this.state.tag} จากเว็บไซต์ MVPKICK.COM`} />
                <link rel="canonical" href={`https://mvpkick.com/tag/${this.state.tagHaveSlash}/`} />
                <meta property="og:url" content={`https://mvpkick.com/tag/${this.state.tagHaveSlash}/`} />
                <meta property="og:title" content={`${this.state.tag} : เนื้อหาข่าวที่เกี่ยวข้อง MVPKICK.COM`} />
                <meta property="og:description" content={`ค้นหาข้อมูลเนื้อหาฟุตบอล เกี่ยวกับ ${this.state.tag} จากเว็บไซต์ MVPKICK.COM`} />
                <meta property="og:image" content="https://mvpkick.com/img/asset/og-img-main.jpg" />
                </Helmet>
            <div className="tdedPostLists text-center">
                {dataNews}
                <div>
                    <div className="col-4 btn btn-tded-news" onClick={(e) => this.selectPage(--this.state.page)} style={this.state.page===1?{pointerEvents: 'none',opacity:'0.25'}:{cursor:'pointer'}}>ก่อนหน้า</div>
                    <div className="col-2">หน้า {this.state.page}</div>
                    <div className="col-4 btn btn-tded-news" onClick={(e) => this.selectPage(++this.state.page)} style={this.state.list.length<9?{pointerEvents: 'none',opacity:'0.25'}:{cursor:'pointer'}}>ถัดไป</div>
                </div>
            </div>
            </HelmetProvider>:
            <div className="listMatch text-center">No Data</div>
        )
    }
}

export default Tag; 