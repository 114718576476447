import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAIXr8M3XoGVRBTuM4q5EWcpkoFK1OwlZw",
    authDomain: "mvpkick-react-sv-d20c5.firebaseapp.com",
    projectId: "mvpkick-react-sv-d20c5",
    storageBucket: "mvpkick-react-sv-d20c5.appspot.com",
    messagingSenderId: "83529920026",
    appId: "1:83529920026:web:0ef1970d4d055a4f20eba3",
    measurementId: "G-0XMVFF7DKW"
  };
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth()
export const db = firebase.firestore();
export default firebase;