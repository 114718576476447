import React from 'react'
import axios from 'axios'
import {Link} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Createchartsleague from './Createchartsleague';

class Callchartsleague extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            listTour : this.props.listTour,
            list : [],
            idTour : this.props.idTour,
            connMobile : this.props.connMobile,
            LorN : this.props.LorN,
            loading : true,
            maxplayed : this.props.maxplayed
        }
    }

    callApi = (listTour = this.state.listTour, LorN = this.props.LorN) =>{
        //console.log(this.props.listTour)
        if(this.state.loading!==true){this.setState({loading : true})}
        let listData = []
        if(LorN === 'lastmatch'){
            listTour.map((item,index)=>{
                //axios.get(`http://localhost/tournament?lastmatch=yes&id=${this.props.idTour}`).then(res =>{
                axios.get(`https:///api.mvpkick.com/tournament?lastmatch=yes&id=${item.id}`).then(res =>{
                //axios.get(`https://sv.mvpkick.com/league/lastnextmatch.php?id=${item.id}&key=lastmatch`).then(res =>{
                    if(res.data.msg){
                        listData.push({name : item.name, events : []})
                        this.setState({list : listData, listTour : listTour, LorN : LorN, loading : false})
                    }else{
                        listData.push({name : item.name, events : res.data.events})
                        this.setState({list : listData, listTour : listTour, LorN : LorN, loading : false})

                    }
                })
            })
        }
    }

    componentDidMount(){
        this.callApi();
    }
    render(){
        let listTeam = [];
        if(this.state.list.length>0){
            var dataMatch = this.state.list.map((item,index) => {
                let {name,events} = item
                let listEvent = [];
                for(const eventsKey in events){ // loop events
                    let homeInfo = {
                        participant:{
                            id:'',
                            name:'',
                            gender:'',
                            countryFK:'',
                            country_name:'',
                            logo:''
                        },
                        result:{
                            ordinarytime:'',
                            halftime:'',
                            finalresult:''
                        }
                    }
                    let awayInfo = {
                        participant:{
                            id:'',
                            name:'',
                            gender:'',
                            countryFK:'',
                            country_name:'',
                            logo:''
                        },
                        result:{
                            ordinarytime:'',
                            halftime:'',
                            finalresult:''
                        }
                    }
                    let {event_participants,startdate,status_type,tournament_stage_name,tournament_stageFK} = events[eventsKey];
                    for(const event_participantsKey in event_participants){
                        let {number,result,participant} = event_participants[event_participantsKey]
                        if(number==='1'){
                            homeInfo.participant = participant;
                            for (const results in result) {
                                let {result_code,value} = result[results]
                                switch(result_code){
                                    case 'ordinarytime':homeInfo.result.ordinarytime=value;break;
                                    case 'halftime':homeInfo.result.halftime=value;break;
                                    case 'finalresult':homeInfo.result.finalresult=value;break;
                                    default:break;
                                }
                            }
                        }else if(number==='2'){
                            awayInfo.participant = participant;
                            for (const results in result) {
                                let {result_code,value} = result[results]
                                switch(result_code){
                                    case 'ordinarytime':awayInfo.result.ordinarytime=value;break;
                                    case 'halftime':awayInfo.result.halftime=value;break;
                                    case 'finalresult':awayInfo.result.finalresult=value;break;
                                    default:break;
                                }
                            }
                        }

                        if(listTeam.find(element => element.name == participant.name)==undefined){
                            listTeam.push({name : participant.name, score : [], calWDL : []})
                        }

                    }
                    let indexSelectTeam = listTeam.findIndex(element => element.name == homeInfo.participant.name)
                    if(homeInfo.result.ordinarytime>awayInfo.result.ordinarytime){
                        listTeam[indexSelectTeam].score.push('W')
                    }else if(homeInfo.result.ordinarytime==awayInfo.result.ordinarytime){
                        listTeam[indexSelectTeam].score.push('D')
                    }else if(homeInfo.result.ordinarytime<awayInfo.result.ordinarytime){
                        listTeam[indexSelectTeam].score.push('L')
                    }
                    let indexSelectTeam2 = listTeam.findIndex(element => element.name == awayInfo.participant.name)
                    if(homeInfo.result.ordinarytime>awayInfo.result.ordinarytime){
                        listTeam[indexSelectTeam2].score.push('L')
                    }else if(homeInfo.result.ordinarytime==awayInfo.result.ordinarytime){
                        listTeam[indexSelectTeam2].score.push('D')
                    }else if(homeInfo.result.ordinarytime<awayInfo.result.ordinarytime){
                        listTeam[indexSelectTeam2].score.push('W')
                    }
                    listEvent.push({id : events[eventsKey].id, homeInfo,awayInfo,startdate : startdate, status_type : status_type, tournament_stage_name : tournament_stage_name, tournament_stageFK : tournament_stageFK})
                } // end loop events
                listEvent.sort((a,b)=>new Date(a.startdate) - new Date(b.startdate))
                //console.log(listTeam)
            })
            for(let i=0;i<listTeam.length;i++){
                for(let num=0;num<listTeam[i].score.length;num++){
                    if(num==0){
                        if(listTeam[i].score[num]=='W'){listTeam[i].calWDL.push(1)}
                        else if(listTeam[i].score[num]=='D'){listTeam[i].calWDL.push(0)}
                        else if(listTeam[i].score[num]=='L'){listTeam[i].calWDL.push(-1)}
                    }else{
                        if(listTeam[i].score[num]=='W'){listTeam[i].calWDL.push(listTeam[i].calWDL[num-1]+1)}
                        else if(listTeam[i].score[num]=='D'){listTeam[i].calWDL.push(listTeam[i].calWDL[num-1]+0)}
                        else if(listTeam[i].score[num]=='L'){listTeam[i].calWDL.push(listTeam[i].calWDL[num-1]+-1)}
                    }
                }
            }
        }
        return(
            this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" alt="Loading" /></div>:
            <div>
                {this.props.connMobile==false?<Createchartsleague list={listTeam} maxplayed={this.state.maxplayed}/>:''}
            </div>
        )
    }
}


export default Callchartsleague;