import React from 'react'
import axios from 'axios'
import Lastmatch from '../../components/team/Lastmatch'
import {Link} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet, HelmetProvider } from 'react-helmet-async';

class Player extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            id:'',
            list:[],
            select:'player',
            connMobile:false,
            listIdTeam:[],
            loading : true
        }
    }

    handleWindowsize = () =>{
        window.innerWidth>800?this.state.connMobile===false?console.log('handle size PC Team'):this.setState({connMobile : false}):this.state.connMobile===true?console.log('handle size Mobile Team'):this.setState({connMobile : true});
    }
    

    componentDidMount(){
        window.addEventListener("resize", this.handleWindowsize);
        const {match} = this.props
        let {id} = match.params
        //axios.get(`https://sv.mvpkick.com/player/index.php?id=${id}`).then(res=>{
        axios.get(`https://api.mvpkick.com/player/?id=${id}`).then(res=>{
            //var {participant} = res.data;
            let listforSet = [];
            listforSet.push(res.data)
            this.setState({list : listforSet, id : id, loading : false})
        })
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.handleWindowsize);
    }

    selectShow = (select) =>{
        this.state.select===select?console.log():this.setState({select : select})
    }

    render(){
        console.log(this.state.list)
        if(this.state.list.length>0){
            var dataPlayer = this.state.list.map((item,index) =>{
                let listPlayTeam = [];
                let {property, teams} = item;
                let infoPlayer = {
                    date_of_birth:'-',
                    height:'-',
                    weight:'-',
                    position:'-',
                    status:'-',
                    flagLogo:'-'
                }
                for(const propertyKey in property){
                    switch(property[propertyKey].name){
                        case'date_of_birth':infoPlayer.date_of_birth = property[propertyKey].value;break;
                        case'height':infoPlayer.height = property[propertyKey].value;break;
                        case'weight':infoPlayer.weight = property[propertyKey].value;break;
                        case'position':infoPlayer.position = property[propertyKey].value;break;
                        case'status':infoPlayer.status = property[propertyKey].value;break;
                        default:break;
                    }
                }
                for(const teamsKey in teams){
                    let hisPlayTeam = {
                        date_from : null,
                        date_to : null,
                        id : null,
                        name : null,
                        countryFK : null,
                        country_name : null,
                        active : null,
                        ShirtNumber : '-',
                        logo : '-'
                    }
                    let {date_from,date_to,properties,team,active} = teams[teamsKey]
                    date_from==='0000-00-00'?hisPlayTeam.date_from = '-':hisPlayTeam.date_from = date_from
                    date_to==='0000-00-00'?hisPlayTeam.date_to = '-':hisPlayTeam.date_to = date_to
                    hisPlayTeam.active = active
                    if(properties!==undefined){ // บางทีมไม่มี
                        for(const propertiesKey in properties){
                            if(properties[propertiesKey].name==='ShirtNumber') hisPlayTeam.ShirtNumber = properties[propertiesKey].value;
                        }
                    }
                    for(const teamKey in team){
                        let {id,name,countryFK,country_name,logo} = team[teamKey]
                        hisPlayTeam.id = id;
                        hisPlayTeam.name = name;
                        hisPlayTeam.countryFK = countryFK;
                        hisPlayTeam.country_name = country_name;
                        hisPlayTeam.logo = logo
                    }
                    listPlayTeam.push(hisPlayTeam)
                    if(hisPlayTeam.active==='yes')this.state.listIdTeam.push(hisPlayTeam.id)
                }
                
                return(
                    <div key={index}>
                        <div className="tournament row">
                            <div className="col-12 text-center">Info Player</div>
                        </div>
                        <div className="listMatch"><div className="col-6 text-center">date of birth</div><div className="col-6 text-center">{infoPlayer.date_of_birth}</div></div>
                        <div className="listMatch"><div className="col-6 text-center">height</div><div className="col-6 text-center">{infoPlayer.height}</div></div>
                        <div className="listMatch"><div className="col-6 text-center">weight</div><div className="col-6 text-center">{infoPlayer.weight}</div></div>
                        <div className="listMatch"><div className="col-6 text-center">position</div><div className="col-6 text-center">{infoPlayer.position}</div></div>
                        <div className="listMatch"><div className="col-6 text-center">status</div><div className="col-6 text-center">{infoPlayer.status}</div></div>
                        <div className="listMatch"><div className="col-6 text-center">country</div><div className="col-6 text-center"><LazyLoadImage className="flagLs" alt={item.country_name} src={item.flagLogo} /></div></div>
                        
                        <div className="col-12 text-center"><h1>History Play</h1></div>
                        <div className="tournament row">
                            <div className="col-6">Team</div>
                            <div className="col-2 text-center">ShirtNumber</div>
                            <div className="col-4 text-center">in-out</div>
                        </div>
                        {listPlayTeam.length>0?listPlayTeam.map((item,index)=>{
                            return(
                                <div className="listMatch" key={index}>
                                    <div className="col-6"><LazyLoadImage className="logoTeamLs" alt={item.name} src={item.logo} /><Link to={`/team/${item.id}/`} target="_blank">{item.name}</Link></div>
                                    <div className="col-2 text-center">{item.ShirtNumber}</div>
                                    <div className="col-4 text-center">{item.date_from}<br/>{item.date_to}</div>
                                </div>
                            )
                        })
                        :
                        <div className="listMatch">
                            <div className="col-12 text-center">No History Play</div>
                        </div>
                        }
                    </div>
                )
            })
            
        }
        return(
            <HelmetProvider>
                <Helmet>
                <title>{this.state.list.length>0?`${this.state.list[0].name} ข้อมูลนักเตะ ประวัติผู้เล่น โปรแกรมบอลลงแข่งล่าสุด`:`No Data Player ID ${this.state.id}`}</title>
                <meta name="description" content={this.state.list.length>0?`ข้อมูลนักเตะ ${this.state.list[0].name} ว-ด-ป เกิด ทีมที่เคยลงเล่น โปรแกรมบอลลงแข่งล่าสุด โดยเว็บไซต์ MVPKICK.COM`:`No Data Des Player ID ${this.state.id}`} />
                <link rel="canonical" href={`https://mvpkick.com/player/${this.state.id}/`} />
                <meta property="og:url" content={`https://mvpkick.com/player/${this.state.id}/`} />
                <meta property="og:title" content={this.state.list.length>0?`${this.state.list[0].name} ข้อมูลนักเตะ ประวัติผู้เล่น โปรแกรมบอลลงแข่งล่าสุด`:`No Data Player ID ${this.state.id}`} />
                <meta property="og:description" content={this.state.list.length>0?`ข้อมูลนักเตะ ${this.state.list[0].name} ว-ด-ป เกิด ทีมที่เคยลงเล่น โปรแกรมบอลลงแข่งล่าสุด โดยเว็บไซต์ MVPKICK.COM`:`No Data Des Player ID ${this.state.id}`} />
                <meta property="og:image" content="https://mvpkick.com/img/asset/og-img-main.jpg" />
                </Helmet>
            <div className="container">
                <div className="text-center">
                    {this.state.list.length>0?<div><img className="logoPlayerPage" src={`${this.state.list[0].imgplayer}`} alt={`${this.state.list[0].name} Profile`}/><br/>{this.state.list[0].name}</div>:console.log()}
                </div>
                <div className="text-center my-3">
                    <div className={this.state.select==='player'?'active btn btn-h2h col-4':'btn btn-h2h col-4'} onClick={(e)=>this.selectShow('player')}>Player Info</div>
                    <div className={this.state.select==='lastmatch'?'active btn btn-h2h col-4':'btn btn-h2h col-4'} onClick={(e)=>this.selectShow('lastmatch')}>Last Match</div>
                </div>
                {this.state.loading?<div className="loading"><img src="/img/asset/loading.gif" className="loadingImg" alt="Loading" /></div>:
                this.state.select==='lastmatch'?<Lastmatch connMobile={this.state.connMobile} from='PlayerPage' idTeamOfPlayer={this.state.listIdTeam} id={this.state.id} />:dataPlayer
                }
            </div>
            </HelmetProvider>
        )
    }
}

export default Player;