import React from 'react'
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

class Createchartsleague extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            list : this.props.list?this.props.list:null,
            maxplayed : this.props.maxplayed
        }
    }

    componentDidMount(){
        if(this.state.id!==null){
            let lineNumber = []
            let arrDatasets = []
            for(let i=1;i<=this.state.maxplayed;i++){
                lineNumber.push(i)
            }
            const ctx = document.getElementById(`myChart${this.state.id}`);
            
            /* s set new arr */
            for(let num=0;num<this.state.list.length;num++){
                arrDatasets.push(
                    {
                        label: this.props.list[num].name,
                        data: this.props.list[num].calWDL,
                        fill: false,
                        borderColor: `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)})`,
                        borderWidth:'1',
                        tension: 0.1
                    }
                )
            }
            //console.log(arrDatasets)
            /* e set new arr */
            
            const data = {
                labels: lineNumber,
                datasets: arrDatasets
            };
            const myChart = new Chart(ctx, {
                type: 'line',
                data: data,
                options: {
                    plugins: {
                        legend: {
                            labels: {
                                // This more specific font property overrides the global property
                                font: {
                                    size: 13
                                }
                            }
                        }
                    }
                }
            })
        }
            
    }

    render(){
        return(
            this.state.list!==null?
            <div className="chartLeagueTable">
            <canvas id={`myChart${this.state.id}`} max-width="400" max-height="400"></canvas>
            </div>:
            ''
        )
    }
}

export default Createchartsleague;